import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../../components/Forms/MyProfile/ChangePassword/ChangePassword';
import {
    EditMyProfileForm,
    newPasswordIsTheSameAsOld,
    requiredField,
    useStyles,
} from './utils';
import ProfileSettings from '../../components/Forms/MyProfile/Settings/ProfileSettings';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../store';
import { useEditMyProfileFormData } from '../../hooks/useEditMyProfileFormData';
import { ReduxState } from '../../store/types';
import Loader from '../../components/Loader/Loader';
import { myProfileSettingsValidationSchema } from '../../components/Forms/MyProfile/Settings/utils';
import * as Yup from 'yup';
import { getTabNumber } from '../../utils/getTabNumber';
import usePageTitle from '../../hooks/usePageTitle';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import { Permission } from '../../store/types/Permission';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';
import {generateTabs} from "../../utils/generateTabs";
import { useRawPermissions } from '../../hooks/usePermissions';


const MyProfile: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const permissions = useRawPermissions();

    const { isLoading } = useSelector((state: ReduxState) => state.myProfile);
    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.myProfile.isFormSaving,
    );

    const { initialValues, data } = useEditMyProfileFormData();

    const [
        addChangePasswordValidation,
        setAddChangePasswordValidation,
    ] = useState(false);

    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1]));
    const [validationSchema, setValidationSchema] = useState(
        myProfileSettingsValidationSchema,
    );

    useEffect(() => {
        if (addChangePasswordValidation) {
            setValidationSchema(
                myProfileSettingsValidationSchema.concat(
                    changePasswordFormValidationSchema,
                ),
            );
        } else {
            setValidationSchema(myProfileSettingsValidationSchema);
        }
    }, [addChangePasswordValidation]);

    useEffect(() => {
        dispatch(actions.getMyProfileDetails.request());
    }, []);

    const handleSubmit = (values: EditMyProfileForm) => {
        if (data.customer?.i_customer) {
            dispatch(
                actions.editMyProfile.request({
                    initialValues: initialValues,
                    changedValues: values,
                    i_customer: data.customer.i_customer,
                    i_individual: data.customer?.i_individual,
                    redirectTab: tabIndex,
                }),
            );
        }
    };

    const handleAddPasswordValidation = (newValue: boolean) => {
        setAddChangePasswordValidation(newValue);
    };

    const handleTabindexChange = (
        event: React.ChangeEvent<{}>,
        index: number,
    ) => setTabIndex(index);

    const changePasswordFormValidationSchema = Yup.object().shape({
        email: Yup.string().email().notRequired(),
        oldPassword: Yup.string()
            .test(
                'passLength',
                t('enums:passwordRules.PasswordMinLength', {
                    charLength: data.configData?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        data.configData?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            data.configData?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .required(requiredField),
        newPassword: Yup.string()
            .required(requiredField)
            .test('equal', newPasswordIsTheSameAsOld, function (v) {
                const ref = Yup.ref('oldPassword');
                return v !== this.resolve(ref);
            })
            .test(
                'passLength',
                t('enums:passwordRules.PasswordMinLength', {
                    charLength: data.configData?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        data.configData?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            data.configData?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.cr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        data.configData?.Web.PasswordComplexityRules
                    ) {
                        return /[a-zA-Z]/g.test(value);
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.nr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        data.configData?.Web.PasswordComplexityRules
                    ) {
                        return /\d/.test(value);
                    }

                    return true;
                },
            ),
    });

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="my-profile-loader" />
            </div>
        );
    }

    const top = (
        <div className={classes.extensionHeader}>
            <label>{t('screens:myProfile.myProfile')}</label>

            <OverflowTooltip
                copy={false}
                classes={{ text: classes.subTitle }}
                tooltip={initialValues.login}
                text={initialValues.login}
            />
        </div>
    );


    const { tabNames, tabs } = generateTabs(
        [
            {
                title: t('common:settings'),
                permission:
                Permission.MyCompany.MyProfile.OwnUserSetting.value,
                tab:         <ProfileSettings
                    timeZonesList={data.timeZonesList}
                    languagesList={data.languagesList || []}
                    timeInUserFormat={data.timeInUserFormat}
                />,
            },
            {
                title: t('common:changePassword'),
                permission:
                Permission.MyCompany.MyProfile.ChangePassword.value,
                tab: (
                    <ChangePasswordForm
                        rules={data?.configData?.Web}
                        handleAddValidation={handleAddPasswordValidation}
                    />
                ),
            },
        ],
        permissions,
    );


    return (
        <PermissionPlaceholder
            permission={Permission.MyCompany.MyProfile.value}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={false}
            >
                {({ submitForm, dirty, values, errors }) => (
                    <DetailsWrapper
                        tabs={tabs}
                        tabsNames={tabNames}
                        top={top}
                        hideCancel
                        showActionButtons
                        saveEnable={dirty}
                        preventSavePress={() => handleSubmit(values)}
                        preventIsOpen={dirty}
                        defaultTabIndex={tabIndex}
                        onTabChange={handleTabindexChange}
                        onSavePress={submitForm}
                    >
                        {isFormSaving && (
                            <Loader
                                dataQa="edit-my-profile-sending-loader"
                                absolutePosition
                            />
                        )}
                    </DetailsWrapper>
                )}
            </Formik>
        </PermissionPlaceholder>
    );
};

export default MyProfile;
