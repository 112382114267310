import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ForwardModeFlag } from '../../../../store/types/ServiceFeature';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import CustomizedRadio from '../../../Radio/Radio';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import {
    callForwardingRuleEmptyRule,
    ExtensionCallForwardingFormProps,
    ExtensionCallForwardingFormType,
    useStyles,
} from './CallForwardingForm.utils';
import FollowMeRulesList from './FollowMeRulesList';
import FollowRuleForm from './FollowRuleForm';

const CallForwardingForm: React.VFC<ExtensionCallForwardingFormProps> = ({
    permittedSipProxies,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        values,
        handleSubmit,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
        isValid,
    } = useFormikContext<ExtensionCallForwardingFormType>();

    useEffect(() => {
        handleDirtyChange?.('callForwarding', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callForwarding', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callForwarding', () => isValid);
    }, [isValid]);

    const forwardModesList = useMemo(
        () =>
            Object.values(ForwardModeFlag)
                .slice(2)
                .map((v) => ({
                    name: t(`enums:forwardModeEnum.${v}`),
                    value: v,
                })),
        [],
    );

    const onChangeForwardingMode = useCallback(
        (v: { name: string; value: ForwardModeFlag }) => {
            setFieldValue('callForwardingMethod', v.value);

            if (
                v.value === ForwardModeFlag.SimpleForwarding ||
                v.value === ForwardModeFlag.ForwardToSIPUri
            ) {
                setFieldValue('callForwardingRules', [
                    { ...callForwardingRuleEmptyRule },
                ]);
            } else {
                setFieldValue('callForwardingRules', []);
            }
        },
        [setFieldValue],
    );

    const setCallForwardingStatus = (field: string, value: boolean) => {
        setFieldValue(field, value);

        if (field === 'callForwardingStatus' && value) {
            setFieldValue('callForwardingMethod', ForwardModeFlag.FollowMe);
        }
    };

    return (
        <div className={classes.mainContainer}>
            <SwitchWithLabel
                id="callForwardingStatus"
                field="callForwardingStatus"
                label={t('screens:extensions.callForwarding')}
                value={values.callForwardingStatus}
                disabled={values.callForwardingLocked}
                setValue={setCallForwardingStatus}
                icon={
                    <ServiceIcon
                        type={Service.CallForwarding}
                        dataQa="call-forwarding-service"
                        dataTestId="call-forwarding-service"
                    />
                }
            />

            {values.callForwardingStatus && (
                <div className={classes.contentContainer}>
                    <CustomizedTooltip
                        title={t('tooltips:incomings.locked')}
                        copy={false}
                        disableHoverListener={!values.callForwardingLocked}
                    >
                        <div className={classes.row}>
                            {forwardModesList.map((v) => (
                                <CustomizedRadio
                                    key={v.value}
                                    value={v.value}
                                    className={classes.radioContainer}
                                    checked={
                                        values.callForwardingMethod === v.value
                                    }
                                    label={v.name}
                                    dataQa={`call-forwarding-${v.value}`}
                                    onChange={() => onChangeForwardingMode(v)}
                                    disabled={values.callForwardingLocked}
                                />
                            ))}
                        </div>
                    </CustomizedTooltip>

                    {(values.callForwardingMethod ===
                        ForwardModeFlag.ForwardToSIPUri ||
                        values.callForwardingMethod ===
                            ForwardModeFlag.SimpleForwarding) && (
                        <FollowRuleForm
                            setFieldError={(field, value) => {
                                setFieldError(
                                    `callForwardingRules.[0].${field}`,
                                    value,
                                );
                            }}
                            setFieldValue={(field, value) => {
                                setFieldValue(
                                    `callForwardingRules.[0].${field}`,
                                    value,
                                );
                            }}
                            rule={values.callForwardingRules[0]}
                            permittedSipProxies={permittedSipProxies || ['']}
                            errors={errors?.callForwardingRules?.[0]}
                            mode={values.callForwardingMethod}
                        />
                    )}

                    {(values.callForwardingMethod ===
                        ForwardModeFlag.FollowMe ||
                        values.callForwardingMethod ===
                            ForwardModeFlag.AdvancedForwarding) && (
                        <FollowMeRulesList />
                    )}
                </div>
            )}
        </div>
    );
};

export default CallForwardingForm;
