import {put, takeLatest, select} from 'redux-saga/effects';
import * as actions from '../../actions';
import {api} from '../../services/axios';
import {AxiosResponse} from 'axios';
import {ReduxState} from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import {ActionType} from 'typesafe-actions';
import {ActiveCall, CallHistory} from '../../types/CallHistory';
import {
    getGlobalCurrencyData,
    getGlobalCustomerInfo,
    getSessionData,
} from '../generic/saga';
import {CustomerInfo} from '../../types/CustomerInfo';
import fileDownload from 'js-file-download';
import toast from 'react-hot-toast';
import i18n from '../../../services/i18n';
import {showErrorToast} from '../../../utils/showErrorToast';
import {
    Account,
    BillingSession,
    CDR,
    Customer,
} from '../../../services/endpoints';
import dayjs from '../../../services/customDayJs';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function* getExtensionCallHistoryList(
    action: ActionType<typeof actions.getExtensionCallHistory.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        if (action.payload.withInitCalls) {
            yield getGlobalCustomerInfo();
            const customerInfoRes: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );
            yield put(
                actions.setCustomerCurrencyForCallHistory({
                    currency: customerInfoRes.data.customer_info.iso_4217,
                }),
            );
            yield getSessionData();
        }

        body.setParams({
            i_account: action.payload.i_account,
            from_date: action.payload.from,
            to_date: action.payload.till,
            i_service_type: 3,
            cli: action.payload.cli || undefined,
            cld: action.payload.cld || undefined,
            offset: action.payload.offset,
            limit: action.payload.limit,
            get_total: 1,
            with_cr_download_ids: '1',
            show_unsuccessful: action.payload.unsuccessfulCalls
                ? '1'
                : undefined,
            call_recording: action.payload.isCallRecording ? '1' : undefined,
        });

        const res: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield api.post(Account.GetXdrList, body);

        yield put(actions.getExtensionCallHistory.success(res.data));
    } catch (err) {
        yield put(actions.getExtensionCallHistory.failure());
    }
}


export function extractExtensionName(input:string [] ):string {
    let ext = 'wav'

    input.forEach((item: string) => {
        if (item.indexOf('filename') != -1) {
            ext = item.substring(item.indexOf('.') + 1);
            ext = ext.slice(0, ext.length - 1);
        }
    })

    return ext;
}

export function* getExtensionCallHistoryFile(
    action: ActionType<typeof actions.getExtensionCallHistoryFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({...action.payload, call_recording_id: undefined});

        const res: AxiosResponse<string> = yield api.post(
            CDR.GetCallRecording,
            body,
            {responseType: 'blob'},
        );
        const contentDisposition: [] = res.headers["content-disposition"] ? res.headers["content-disposition"].split(" ") : [];
        const ext = extractExtensionName(contentDisposition);

        fileDownload(res.data, `${action.payload.fileName || 'audio'}.${ext}`);
        action.payload.callback && action.payload.callback();
    } catch (err) {
        yield put(actions.getExtensionCallHistoryFile.failure());
    }
}

export function* getCallHistoryList(
    action: ActionType<typeof actions.getCallHistory.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        if (action.payload.withInitCalls) {
            yield getGlobalCustomerInfo();

            const customerInfoRes: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );
            yield put(
                actions.setCustomerCurrencyForCallHistory({
                    currency: customerInfoRes.data.customer_info.iso_4217,
                }),
            );

            yield getSessionData();
            yield getGlobalCurrencyData();
        }

        body.setParams({
            from_date: action.payload.from,
            to_date: action.payload.till,
            i_service_type: 3,
            cli: action.payload.cli || undefined,
            cld: action.payload.cld || undefined,
            offset: action.payload.offset,
            limit: action.payload.limit,
            get_total: 1,
            with_cr_download_ids: '1',
            show_unsuccessful: action.payload.unsuccessfulCalls
                ? '1'
                : undefined,
            call_recording: action.payload.callRecordingsOnly ? '1' : undefined,
        });

        const res: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);
        const xdr = {...res.data};
        xdr.xdr_list = res.data.xdr_list.map((e, index) => {
            return {
                ...e,
                id: index,
            };
        });
        yield put(actions.getCallHistory.success(xdr));
    } catch (err) {
        yield put(actions.getCallHistory.failure());
    }
}

export function* removeCallRecordings(
    action: ActionType<typeof actions.removeCallRecordings.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            xdr_list: action.payload.items.map((v) => ({
                id: v.i_xdr,
                call_recording_id: v.cr_download_ids?.[0],
            })),
        });

        yield api.post(CDR.BatchDeleteCallRecording, body);

        if (action.payload.getDataExtensionRequestPayload) {
            yield put(
                actions.getExtensionCallHistory.request(
                    action.payload.getDataExtensionRequestPayload,
                ),
            );
        }

        if (action.payload.getDataRequestPayload) {
            yield put(
                actions.getCallHistory.request(
                    action.payload.getDataRequestPayload,
                ),
            );
        }

        toast(i18n.t<string>('screens:callSettings.callRecordingsRemoved'));

        yield put(actions.removeCallRecordings.success());
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.removeCallRecordings.failure());
    }
}

export function* getCallHistoryBasicData() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const timezoneOffset = yield select(
            (state: ReduxState) => state.generic.sessionData?.tz_offset,
        ) || 0;

        const body = new JSONFormData(session_id, csrf_token);

        const params = {
            show_unsuccessful: '0',
            i_service_type: '3',
            get_total: '1',
            limit: '1',
        };

        const date = dayjs.utc();

        body.setParams({
            ...params,
            from_date: date.startOf('date').format(dateFormat),
            to_date: date.utcOffset(timezoneOffset / 60).format(dateFormat),
        });

        const todayResponse: AxiosResponse<{
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        body.setParams({
            ...params,
            from_date: date
                .startOf('date')
                .subtract(1, 'day')
                .format(dateFormat),
            to_date: date
                .subtract(1, 'day')
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
        });

        const yesterdayResponse: AxiosResponse<{
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        yield put(
            actions.getCallHistoryBasicData.success({
                todayCalls: todayResponse.data.total,
                yesterdayCalls: yesterdayResponse.data.total,
            }),
        );
    } catch (err) {
        yield put(actions.getCallHistoryBasicData.failure());
    }
}

export function* getActiveCalls() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<{
            active_session_list: ActiveCall[];
        }> = yield api.post(BillingSession.GetActiveSessionsList, body);

        yield put(
            actions.getActiveCalls.success({
                calls: res.data.active_session_list,
                total: res.data.active_session_list.length,
                refreshTime: dayjs().format(dateFormat),
            }),
        );
    } catch (err) {
        yield put(actions.getActiveCalls.failure());
    }
}

export const callsSaga = [
    takeLatest(
        actions.getExtensionCallHistory.request,
        getExtensionCallHistoryList,
    ),
    takeLatest(
        actions.getExtensionCallHistoryFile.request,
        getExtensionCallHistoryFile,
    ),
    takeLatest(actions.getCallHistory.request, getCallHistoryList),
    takeLatest(actions.removeCallRecordings.request, removeCallRecordings),
    takeLatest(actions.getActiveCalls.request, getActiveCalls),
    takeLatest(
        actions.getCallHistoryBasicData.request,
        getCallHistoryBasicData,
    ),
];
