import React from 'react';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TwoLineCell from '../../Calls/TwoLineCell';
import { convertToUserFormat } from '../../../utils/dateWithTimezoneConversion';
import EmptyRowContent from '../../DataTable/EmptyRowContent';
import { makeStyles } from '@material-ui/core/styles';
import DateTimeCell from '../../Calls/DateTimeCell';

export interface CreditAdjustmentsTableRowInterface {
    id: string;
    accountId?: string;
    description?: string;
    comment?: string;
    connectTime?: string;
    chargedAmount?: number;
}

export const generateColumns = (
    t: TFunction<string>,
    currency = 'USD',
    userDateTimeFormat?: string,
    iService?: number
): Column<CreditAdjustmentsTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.descriptionComments'),
            accessor: 'description',
            width: 2,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.description || ''}
                        descriptionText={params.row.original.comment}
                    />
                );
            },
        },
        {
            Header: t<string>('common:date'),
            accessor: 'connectTime',
            width: 1,
            Cell: function Cell(params) {
                return params.row.original.connectTime ? (
                    <DateTimeCell
                        time={params.row.original.connectTime as string}
                        customDateFormat={userDateTimeFormat}
                    />
                ) : (
                    <EmptyRowContent />
                );
            },
        },
        {
            Header: t<string>(iService === 2
                ? 'screens:billing.amountWithCurrency'
                : 'screens:billing.chargedWithCurrency'
                , {
                currency: currency,
            }),
            width: 0.5,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};
export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'end',
        },
    },

    tableContent: {
        '& td': {
            alignItems: 'center !important',
        },
        '& td:nth-child(4)': {
            display: 'flex',
            justifyContent: 'end',
        },
    },
}));
