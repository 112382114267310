import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    GetUAInfoRequest,
    GetUAListRequest,
    UAInfo,
    UAListPayload,
} from '../../types/Devices';

export const devicesList = createAsyncAction(
    'GET_UA_LIST',
    'GET_UA_LIST_SUCCESS',
    'GET_UA_LIST_FAILED',
)<GetUAListRequest, UAListPayload, undefined>();

export const releaseDevice = createAsyncAction(
    'RELEASE_DEVICE',
    'RELEASE_DEVICE_SUCCESS',
    'RELEASE_DEVICE_FAILED',
)<
    { extension_id: string; i_cpe?: number; onSuccess?: () => void },
    undefined,
    undefined
>();

export const assignDevice = createAsyncAction(
    'ASSIGN_DEVICE',
    'ASSIGN_DEVICE_SUCCESS',
    'ASSIGN_DEVICE_FAILED',
)<
    {
        withRelease?: boolean;
        port: number;
        i_cpe: number;
        i_c_ext: number;
        onSuccess?: () => void;
        extension_id?: string;
    },
    undefined,
    undefined
>();

export const fetchDeviceByIAccount = createAsyncAction(
    'FETCH_DEVICE_BY_I_ACCOUNT',
    'FETCH_DEVICE_BY_I_ACCOUNT_SUCCESS',
    'FETCH_DEVICE_BY_I_ACCOUNT_FAILED',
)<GetUAInfoRequest, { device: UAInfo | null }, undefined>();
