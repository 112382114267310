import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { useLocation } from 'react-router';

export const IGNORE_TRANSLATION_KEY = 'serverSessionResetPasswordError';
export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SignInRootBackground,
    },

    wrapper: {
        height: 435,
        width: 515,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        borderRadius: 4,
        alignItems: 'center',
        '& .MuiInputBase-root': {
            marginBottom: 16,
        },
    },

    credentials: {
        width: 372,
        display: 'flex',
        flexDirection: 'column',
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
}));

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
