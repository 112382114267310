import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import { sipDefaultValues } from '../components/Extensions/Sip';

export const useExtensionDeviceFormData = () => {
    const { sipInfoErrors, savingSipInfo } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const extension = useSelector(
        (state: ReduxState) => state.extensions.extensionDetails,
    );

    const initFormData = useMemo(() => {
        if (!extension) {
            return sipDefaultValues;
        }
        return {
            iAccount: extension.i_account,
            savingSipInfo: savingSipInfo,
            registrationName: extension.account_id,
            servicePassword: extension.account_info?.h323_password || '',
            userAgent: extension.account_info?.sip_agent || '',
            sipInfoErrors: sipInfoErrors,
            sipContact: extension.account_info?.sip_contact || '',
        };
    }, [extension, sipInfoErrors, savingSipInfo]);

    return {
        initFormData: initFormData,
        data: {
            extension: extension,
            sipStatus: !!extension?.account_info?.sip_status,
        },
    };
};
