import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DeviceInfo, { DeviceInfoDefinition } from '../Devices/DeviceInfo';
import i18n from '../../services/i18n';
import Button from '../Button/Button';
import { Add, Cancel, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import Loader from '../Loader/Loader';
import Sip from './Sip';
import AssignDeviceDialog from '../Devices/AssignDeviceDialog';
import { toast } from 'react-hot-toast';
import AlertDialog from '../AlertDialog/AlertDialog';
import { DialogButton } from '../AlertDialog/DialogContainer';
import { DeviceProps, useStyles } from './Device.utils';
import classNames from 'classnames';
import PermissionProvider from '../PermissionProvider/PermissionProvider';

const Device: React.FC<DeviceProps> = ({
    iAccount,
    register,
    i_c_ext,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    extensionNumber,
    assignPermission,
    releasePermission,
    changePermission,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const device = useSelector((state: ReduxState) => state.extensions.device);

    const [deviceAssigned, setDeviceAssigned] = useState(!!device?.item);
    const [idOpenDeviceDialog, setIsOpenDialog] = useState(false);
    const [idOpenReleaseDialog, setIsOpenReleaseDialog] = useState(false);

    useEffect(() => {
        if (iAccount) {
            dispatch(
                actions.fetchDeviceByIAccount.request({ i_account: iAccount }),
            );
        }
    }, [iAccount]);

    useEffect(() => {
        setDeviceAssigned(!!device?.item);
    }, [device]);

    const handleIsOpenDialogChange = () => {
        setIsOpenDialog((prevState) => !prevState);
    };

    const handleIsOpenReleaseDialog = () => {
        setIsOpenReleaseDialog((prevState) => !prevState);
    };

    const portConfiguration = device?.item?.ports_config.find(
        (p) => p.i_account == iAccount,
    );

    const { port } = portConfiguration || {};
    const definition: DeviceInfoDefinition[] = [
        {
            deviceField: 'name',
            size: 6,
            label: i18n.t<string>('screens:devices.deviceName'),
            readonly: true,
        },
        {
            deviceField: 'mac',
            size: 6,
            label: i18n.t<string>('screens:devices.macAddress'),
            readonly: true,
        },
        {
            deviceField: 'type',
            size: 6,
            label: i18n.t<string>('screens:devices.model'),
            readonly: true,
        },
        {
            deviceField: 'ports_config',
            size: 6,
            label: i18n.t<string>('screens:devices.linePort'),
            value: port ? port + '.' : undefined,
            readonly: true,
        },
    ];

    const handleSubmitReleaseClick = () => {
        if (portConfiguration?.extension_id) {
            dispatch(
                actions.releaseDevice.request({
                    extension_id: portConfiguration?.extension_id,
                    onSuccess: () => {
                        handleIsOpenReleaseDialog();
                        toast(t<string>('screens:devices.deviceReleased'));
                        dispatch(
                            actions.fetchDeviceByIAccount.request({
                                i_account: iAccount,
                            }),
                        );
                    },
                }),
            );
        }
    };

    const actionReleaseDialogButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={handleIsOpenReleaseDialog}
        />,
        <DialogButton
            key="save"
            label={t('common:release')}
            onClick={handleSubmitReleaseClick}
        />,
    ];

    const content = deviceAssigned ? (
        <>
            <Grid className={classes.itemsContainer}>
                <DeviceInfo
                    customClasses={{
                        field: classes.field,
                        container: classes.container,
                    }}
                    device={device?.item}
                    definition={definition}
                />
                <Grid className={classes.actionButtons}>
                    <PermissionProvider permission={releasePermission || []}>
                        <Button
                            className={classes.releaseButton}
                            icon={<Cancel />}
                            onClick={handleIsOpenReleaseDialog}
                        >
                            {t('screens:devices.release')}
                        </Button>
                    </PermissionProvider>

                    <PermissionProvider permission={changePermission || []}>
                        <Button
                            className={classes.changeButton}
                            primary
                            accent
                            icon={<Edit />}
                            onClick={handleIsOpenDialogChange}
                        >
                            {t('common:change')}
                        </Button>
                    </PermissionProvider>
                </Grid>
            </Grid>
            <AlertDialog
                isOpen={idOpenReleaseDialog}
                hideHeader={true}
                description={t('screens:extensions.releaseExtension')}
                dialogActionsButtons={actionReleaseDialogButtons}
                className={classes.releaseContainer}
            />
        </>
    ) : (
        <PermissionProvider permission={assignPermission || []}>
            <Button
                className={classes.assignButton}
                primary
                accent
                icon={<Add />}
                onClick={handleIsOpenDialogChange}
            >
                {t('screens:devices.assign')}
            </Button>
        </PermissionProvider>
    );

    return (
        <>
            {device?.isLoading ? (
                <Loader dataQa={'fetch-device-loader'} absolutePosition />
            ) : (
                <div className={classNames(
                    !deviceAssigned && classes.assignDeviceButtonContainer)}
                >
                    {content}
                    <Grid
                        className={classNames(
                            classes.itemsContainer,
                            classes.marginTop,
                        )}
                    >
                        <div className={classes.titleContainer}>
                            <h5>{t('screens:extensions.sipInfo')}</h5>
                        </div>
                        <Sip
                            register={register}
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                            handleSetIsValidFunc={handleSetIsValidFunc}
                        />
                    </Grid>
                    <PermissionProvider permission={assignPermission || []}>
                        <AssignDeviceDialog
                            id={portConfiguration?.extension_id || extensionNumber}
                            i_c_ext={i_c_ext}
                            isOpen={idOpenDeviceDialog}
                            onClose={handleIsOpenDialogChange}
                            selectedDevice={device?.item ?? undefined}
                            selectedPort={portConfiguration}
                            callBackOnSave={() => {
                                handleIsOpenDialogChange();
                                const msg = t('screens:devices.deviceAssigned');
                                toast(msg);
                                dispatch(
                                    actions.fetchDeviceByIAccount.request({
                                        i_account: iAccount,
                                    }),
                                );
                            }}
                        />
                    </PermissionProvider>
                </div>
            )}
        </>
    );
};

export default Device;
