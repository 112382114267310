import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/callSettings';
import { MohInfo } from '../../types/AccountMoh';
import { DialingRuleInfo } from '../../types/DialingRule';
import { ServiceFeature } from '../../types/ServiceFeature';
import { CallBarringRule } from '../../types/CallBarring';
import { CustomerInfoDetails } from '../../types/CustomerInfo';

export interface CallSettingsStateType {
    isDataLoading?: boolean;
    isDataSending?: boolean;
    mohDetails?: {
        items: MohInfo[];
    };
    onHoldMusicStatus?: boolean;
    extToExtStatus?: boolean;
    dialingRules?: DialingRuleInfo[];
    customerServiceFeatures?: ServiceFeature[];
    callBarringStatus?: boolean;
    callBarring?: ServiceFeature;
    callBarringItems?: CallBarringRule[];
    customerInfo?: CustomerInfoDetails;
}

export const initialState: CallSettingsStateType = {};

export type CallSettingsActionsType = ActionType<typeof actions>;

const callSettingsReducer = createReducer<
    CallSettingsStateType,
    CallSettingsActionsType
>(initialState)
    .handleAction(actions.getCallSettingsDetails.request, (state) => ({
        ...state,
        isDataLoading: true,
    }))
    .handleAction(actions.getCallSettingsDetails.success, (state) => ({
        ...state,
        isDataLoading: false,
    }))
    .handleAction(actions.editCallSettings.request, (state) => ({
        ...state,
        isDataSending: true,
    }))
    .handleAction(actions.editCallSettings.success, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(actions.editCallSettings.failure, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(actions.getDialingRules.success, (state, action) => ({
        ...state,
        dialingRules: action.payload,
    }))
    .handleAction(
        actions.getCustomerServiceFeatures.success,
        (state, action) => ({
            ...state,
            customerServiceFeatures: action.payload,
        }),
    )
    .handleAction(
        actions.getCustomerInfoCallBarring.success,
        (state, action) => ({
            ...state,
            isDataSending: false,
            callBarring: action.payload.callBarring,
            customerInfo: action.payload.customerInfo,
        }),
    )
    .handleAction(actions.getCallBarringOptions.success, (state, action) => ({
        ...state,
        isDataSending: false,
        callBarringItems: action.payload.callBarringOptions,
    }))
    .handleAction(actions.getCallBarringOptions.failure, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(
        actions.getCallSettingsMohDetails.success,
        (state, action) => ({
            ...state,
            mohDetails: {
                ...state.mohDetails,
                ...action.payload,
            },
        }),
    )
    .handleAction(actions.getMusicRingDetails.success, (state, action) => ({
        ...state,
        onHoldMusicStatus: action.payload.onHoldMusicStatus,
        extToExtStatus: action.payload.extToExtStatus,
    }));
export default callSettingsReducer;
