import { createAsyncAction } from 'typesafe-actions';
import { MyProfile } from './payloads';
import * as payloads from '../payloads';

export const getMyProfileDetails = createAsyncAction(
    'GET_MY_PROFILE_DETAILS',
    'GET_MY_PROFILE_DETAILS_SUCCESS',
    'GET_MY_PROFILE_DETAILS_FAILED',
)<undefined, undefined, undefined>();

export const getMyProfileCustomerInfoByLogin = createAsyncAction(
    'GET_CUSTOMER_INFO',
    'GET_CUSTOMER_INFO_SUCCESS',
    'GET_CUSTOMER_INFO_SUCCESS',
)<undefined, MyProfile, undefined>();

export const editMyProfile = createAsyncAction(
    'EDIT_MY_PROFILE',
    'EDIT_MY_PROFILE_SUCCESS',
    'EDIT_MY_PROFILE_FAILED',
)<payloads.EditMyProfileRequestPayload, undefined, undefined>();
