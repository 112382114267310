import { useTabStyles } from './utils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import { Formik } from 'formik';
import {
    callSettingsValidationSchema,
    ExtensionCallSettingsFormType,
} from '../../../components/Forms/Extensions/CallSettings/CallSettingsForm.utils';
import CallSettingsForm from '../../../components/Forms/Extensions/CallSettings/CallSettingsForm';
import { useEditExtensionCallSettingsFormData } from '../../../hooks/useEditExtensionCallSettingsFormData';
import { TabInterface } from '../../../components/Tabs/Tabs.utils';

const CallSettingsTab: React.VFC<TabInterface> = ({
    handleSubmitForm,
    handleInitDataChange,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
}) => {
    const classes = useTabStyles();

    const { initFormData, data } = useEditExtensionCallSettingsFormData();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callSettingsTab?.isLoading ||
            false,
    );

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading, initFormData]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="incoming-list-loader" />
        </div>
    ) : (
        <Formik<ExtensionCallSettingsFormType>
            initialValues={initFormData}
            validationSchema={callSettingsValidationSchema}
            onSubmit={handleSubmitForm!}
            enableReinitialize
            validateOnChange={false}
        >
            <CallSettingsForm
                handleDirtyChange={handleDirtyChange}
                handleSetSubmitFunc={handleSetSubmitFunc}
                handleSetIsValidFunc={handleSetIsValidFunc}
                accountId={data?.extension?.account_id}
                mohItems={data.mohItems}
                i_account={data?.extension?.i_account}
            />
        </Formik>
    );
};

export default CallSettingsTab;
