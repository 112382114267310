import { ActionType, createReducer } from 'typesafe-actions';
import { MyProfile } from '../../actions/myProfile/payloads';
import { actions } from '../../index';

export interface MyProfileStateType {
    isLoading?: boolean;
    isInitialLoaded?: boolean;
    customer?: MyProfile;
    isFormSaving?: boolean;
}

export type MyProfileActionsType = ActionType<typeof actions>;

export const initialState: MyProfileStateType = {};

const myProfileReducer = createReducer<
    MyProfileStateType,
    MyProfileActionsType
>(initialState)
    .handleAction(actions.getMyProfileDetails.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getMyProfileDetails.success, (state) => ({
        ...state,
        isLoading: false,
        isInitialLoaded: true,
    }))
    .handleAction(actions.getMyProfileDetails.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(
        actions.getMyProfileCustomerInfoByLogin.success,
        (state, action) => ({
            ...state,
            customer: action.payload,
        }),
    )
    .handleAction(actions.editMyProfile.request, (state) => ({
        ...state,
        isFormSaving: true,
    }))
    .handleAction(actions.editMyProfile.failure, (state) => ({
        ...state,
        isFormSaving: false,
    }));

export default myProfileReducer;
