import { AxiosResponse } from 'axios';
import qs from 'qs';
import toast from 'react-hot-toast';
import { put, takeLatest, select, delay } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { api } from '../../services/axios';
import { actions } from '../..';
import {
    Customer,
    DialingRule,
    CodecConverter,
} from '../../../services/endpoints';
import i18n from '../../../services/i18n';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import JSONFormData from '../../../utils/JSONFormData';
import { showErrorToast } from '../../../utils/showErrorToast';
import { AccountMohListInfo, MohInfo } from '../../types/AccountMoh';
import { CustomerInfo } from '../../types/CustomerInfo';
import { ServiceFeatureName, ServiceFeature } from '../../types/ServiceFeature';
import { EditCallSettingsForm } from '../../../views/CallSettings/CallSettingsDetails';
import { DialingRuleInfo } from '../../types/DialingRule';
import { ReduxState } from '../../types';
import { CallBarringRule } from '../../types/CallBarring';
import { getDidNumbersForSelect } from '../didNumbers/saga';

export function* getCallSettingsDetails() {
    try {
        // call here
        yield getExtensionMohDetails();
        yield getMusicRingDetails();
        yield getCustomerInfo();
        yield getDialingRules();
        yield getCustomerDetailsForCallSettings();
        yield getCallBarringOptions();
        yield getDidNumbersForSelect();
        yield put(actions.getCallSettingsDetails.success());
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getCallSettingsDetails.failure());
    }
}

export function* editCallSettings(
    action: ActionType<typeof actions.editCallSettings.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editDialingRules(action, dataToSave);
        yield editCustomerServiceFeatures(action, dataToSave);
        yield updateCallBarringRules(action, dataToSave);

        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        yield delay(1000);

        yield put(actions.editCallSettings.success());

        location?.replace(
            `?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.editCallSettings.failure());
    }
}

export function* getExtensionMohDetails() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const mohListRes: AxiosResponse<{
            moh_list_info: AccountMohListInfo;
        }> = yield api.post(Customer.GetCustomerMohListInfo, body);

        const mohList: MohInfo[] =
            mohListRes?.data.moh_list_info?.aggregated_moh_list;

        yield put(
            actions.getCallSettingsMohDetails.success({
                items: mohList,
            }),
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getMusicRingDetails() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );
        const serviceFeatures = res.data.customer_info
            ?.service_features as ServiceFeature[];

        const extToExtFeature = serviceFeatures?.find(
            (v: ServiceFeature) =>
                v.name === ServiceFeatureName.DistinctiveRingVPN,
        );

        const musicOnHoldFeature = serviceFeatures?.find(
            (v: ServiceFeature) => v.name === ServiceFeatureName.MusicOnHold,
        );
        if (musicOnHoldFeature || extToExtFeature) {
            yield put(
                actions.getMusicRingDetails.success({
                    onHoldMusicStatus:
                        musicOnHoldFeature &&
                        musicOnHoldFeature.flag_value === 'Y' &&
                        musicOnHoldFeature.effective_flag_value === 'Y',
                    extToExtStatus:
                        extToExtFeature &&
                        extToExtFeature.flag_value === 'Y' &&
                        extToExtFeature.effective_flag_value === 'Y',
                }),
            );
        }
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getDialingRules() {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        with_internal: '1',
        usage_type: 'D',
    });

    const res: AxiosResponse<{
        dialing_rules_list: DialingRuleInfo[];
    }> = yield api.post(DialingRule.GetiDialingRulesList, body);

    const customerServiceFeatures: ServiceFeature[] = yield select(
        (state: ReduxState) =>
            state.callSettings?.customerServiceFeatures || [],
    );

    const voiceDialingFeature = customerServiceFeatures?.find(
        (v) => v.name === ServiceFeatureName.VoiceDialing,
    ) as ServiceFeature;

    const dialId = voiceDialingFeature?.attributes.find(
        (v) => v.name === 'i_dial_rule',
    )?.values[0];

    if (
        dialId !== undefined &&
        !res.data.dialing_rules_list.find(
            (v) => v.i_dialing_rule === parseInt(dialId),
        )
    ) {
        body.setParams({
            i_dialing_rule: dialId,
        });

        const rule: AxiosResponse<{
            dialing_rule_info: DialingRuleInfo;
        }> = yield api.post(DialingRule.GetDialingRuleInfo, body);

        res.data.dialing_rules_list.push(rule.data.dialing_rule_info);
    }

    yield put(actions.getDialingRules.success(res.data.dialing_rules_list));
}

export function* getCustomerInfo() {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const res: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );

    if (res.data.customer_info.service_features) {
        yield put(
            actions.getCustomerServiceFeatures.success(
                res.data.customer_info.service_features,
            ),
        );
    }
}

export function* getCustomerDetailsForCallSettings() {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const res: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );
    const serviceFeatures = res.data.customer_info
        ?.service_features as ServiceFeature[];

    const callBarring = serviceFeatures?.find(
        (e: ServiceFeature) => e.name === ServiceFeatureName.CallBarring,
    );

    if (callBarring) {
        yield put(
            actions.getCustomerInfoCallBarring.success({
                callBarring: callBarring,
                customerInfo: res.data.customer_info,
            }),
        );
    }
}

export function* editDialingRules(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    if (data.rules) {
        for (let i = 0; i < data.rules.length; i++) {
            const comparedRule = compareObjectsAndReturnDifferencesInValues(
                action.payload.initialValues.rules[i],
                data.rules[i],
            );

            if (Object.keys(comparedRule).length) {
                body.setParams({
                    dialing_rule_info: {
                        i_owner: data.rules[i].i_owner,
                        name: data.rules[i].name,
                        i_dialing_rule: data.rules[i].i_dialing_rule,
                        dial_codes: data.rules[i].dial_codes,
                    },
                });
                yield api.post(DialingRule.UpdateDialingRule, body);
            }
        }
    }
}

export function* editCustomerServiceFeatures(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const params: { service_features: ServiceFeature[] } = {
        service_features: [],
    };

    const customerServiceFeatures: ServiceFeature[] = yield select(
        (state) => state.callSettings.customerServiceFeatures,
    );

    if (data.selectedRuleId !== undefined) {
        const voiceDialingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.VoiceDialing,
        ) as ServiceFeature;
        params.service_features.push({
            name: ServiceFeatureName.VoiceDialing,
            attributes: [
                {
                    effective_values: [data.selectedRuleId],
                    name: 'i_dial_rule',
                    values: [data.selectedRuleId],
                },
            ],
            effective_flag_value: voiceDialingFeature.effective_flag_value,
            flag_value: voiceDialingFeature.flag_value,
        });
    }

    if (data.callParkingStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.CallParking,
            effective_flag_value: data.callParkingStatus ? 'Y' : 'N',
            flag_value: data.callParkingStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.pagingIntercomStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.Paging,
            effective_flag_value: data.pagingIntercomStatus ? 'Y' : 'N',
            flag_value: data.pagingIntercomStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.groupPickupStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.GroupPicking,
            effective_flag_value: data.groupPickupStatus ? 'Y' : 'N',
            flag_value: data.groupPickupStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.callSupervisionStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.CallSupervision,
            effective_flag_value: data.callSupervisionStatus ? 'Y' : 'N',
            flag_value: data.callSupervisionStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.defaultCompanyNumber !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.Cli,
            effective_flag_value: data.defaultCompanyNumber.length ? 'Y' : 'N',
            flag_value: data.defaultCompanyNumber.length ? 'Y' : 'N',
            attributes: [
                {
                    name: 'centrex',
                    values: [data.defaultCompanyNumber],
                    effective_values: [data.selectedRuleId],
                },
            ],
        });
    }

    if (params.service_features.length > 0) {
        body.setParams({ customer_info: params });
        yield api.post(Customer.UpdateCustomer, body);
    }
}

export function* getCallBarringOptions() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<{
            options: CallBarringRule[];
        }> = yield api.post(Customer.GetCallBarringOptions, body);

        yield put(
            actions.getCallBarringOptions.success({
                callBarringOptions: res.data.options,
            }),
        );
    } catch (err) {
        yield put(actions.getCallBarringOptions.failure());
    }
}

export function* updateCallBarringRules(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const { mohDetails, onHoldMusicStatus } = yield select(
        (state) => state.callSettings,
    );
    const body = new JSONFormData(session_id, csrf_token);
    let item;
    if (data.selectedMohFile)
        item = mohDetails.items.find(
            (e: MohInfo) => e.name === data.selectedMohFile,
        );

    if (
        data.onHoldMusicSwitcher !== undefined ||
        (onHoldMusicStatus && data.selectedMohFile !== undefined)
    ) {
        const status =
            data.onHoldMusicSwitcher !== undefined
                ? data.onHoldMusicSwitcher
                    ? 'Y'
                    : 'N'
                : onHoldMusicStatus
                ? 'Y'
                : 'N';
        if (status === 'Y') {
            body.setParams({
                service_features: [
                    {
                        name: ServiceFeatureName.MusicOnHold,
                        flag_value: status,
                        effective_flag_value: status,
                        attributes: [
                            {
                                effective_values: [item.i_moh],
                                name: 'i_moh',
                                values: [item.i_moh],
                            },
                        ],
                    },
                ],
            });
        } else {
            body.setParams({
                service_features: [
                    {
                        name: ServiceFeatureName.MusicOnHold,
                        flag_value: status,
                        effective_flag_value: status,
                    },
                ],
            });
        }
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (data.extToExtSwitcher !== undefined) {
        const status = data.extToExtSwitcher ? 'Y' : 'N';
        body.setParams({
            service_features: [
                {
                    name: ServiceFeatureName.DistinctiveRingVPN,
                    flag_value: status,
                    effective_flag_value: status,
                },
            ],
        });
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (
        data.individualRules !== undefined ||
        data.callBarringStatus !== undefined
    ) {
        const individualRules =
            data.individualRules ??
            action.payload.initialValues.individualRules;
        const callBarringStatus =
            data.callBarringStatus ??
            action.payload.initialValues.callBarringStatus;

        body.setParams({
            service_features: [
                {
                    name: ServiceFeatureName.CallBarring,
                    flag_value: callBarringStatus
                        ? individualRules
                            ? '~'
                            : 'Y'
                        : 'N',
                    effective_flag_value: callBarringStatus
                        ? individualRules
                            ? ''
                            : 'Y'
                        : 'N',
                },
            ],
        });
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (data.callBarringItems !== undefined) {
        body.setParams({
            options: data.callBarringItems,
        });

        yield api.post(Customer.UpdateCallBarringOptions, body);
    }
}

export function* uploadMusicFile(
    action: ActionType<typeof actions.uploadMusicFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: 'moh',
            file_name: action.payload.name,
        });

        body.append('upload_file', action.payload.file);

        yield api.post(CodecConverter.AddFile, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (action.payload.callback) {
            action.payload?.callback();
            yield getExtensionMohDetails();
        }

        toast(i18n.t<string>('common:fileUploaded'));
        yield put(actions.uploadRingbackTone.success());
    } catch (err) {
        action.payload.errorCallback && action.payload.errorCallback();
        yield put(actions.uploadRingbackTone.failure(err));
    }
}

export const callSettingsSaga = [
    takeLatest(actions.getCallSettingsDetails.request, getCallSettingsDetails),
    takeLatest(actions.editCallSettings.request, editCallSettings),
    takeLatest(actions.uploadMusicFile.request, uploadMusicFile),
];
