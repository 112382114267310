import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import { CustomerDIDNumberType } from '../../../../store/types/CustomerDIDNumber';
import * as Yup from 'yup';
import {
    EditExtensionForm,
    ChangeDirtyStateDetailsTab,
} from '../../../../views/Extensions/ExtensionDetails/utils';
import { RingGroupType } from '../../../../store/types/RingGroup';
import i18n from '../../../../services/i18n';
import { TimeZone } from '../../../../store/types/TimeZone';
import { Country } from '../../../../store/types/Country';
import { PasswordRules } from '../../../../store/types/ConfigData';
import { Subdivision } from '../../../../store/types/Subdivision';
import { APIErrorInterface } from '../../../../store/types';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '32px 0px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
        padding: '0px 16px 24px 16px',

        '& > :first-child': {
            marginRight: 32,
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },
    groupsContainer: {
        padding: '24px 16px',
        maxWidth: 944,
    },

    columnItemsContainer: {
        flexDirection: 'column',
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },
    emergencyHeaderContainer: {
        padding: '0px 24px 0px 24px !important',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 16,
        marginTop: -28,
    },
    emergencyHeader: {
        color: Colors.Text,
        fontWeight: 700,
        marginRight: 12,
    },
    includeSwitcher: {
        maxWidth: '944px !important',
    },

    outsideContainer: {
        paddingRight: 0,
    },
    includeContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -5,
    },
    includeRowContainer: {
        '& > :first-child': {
            flex: 1,
            paddingLeft: 0,
        },
    },
    topContainer: {
        paddingTop: 22,
    },
}));

export type ExtensionFormType = {
    extensionNumber: string;
    extensionName: string;
    didNumber: string[];
    displayNumber: string;
    emailAddress: string;
    timezone: string;
    portalLogin: string;
    portalPassword: string;
    office: string;
    country: string;
    address: string;
    city: string;
    postalCode: string;
    state: string;
    includeCorpDirectory: boolean;
    recordName: string;
    recordFile: File | null;
    extensionRingGroups: RingGroupType[];
};

export const emptyField = i18n.t('errors:common.emptyInput');
export const portalLoginError = i18n.t('errors:extensions.portalLogin');
export const portalLoginLengthError = i18n.t(
    'errors:extensions.portalLoginLength',
);
const loginValidation = Yup.string()
    .matches(/^[a-zA-Z0-9_.-@-]*$/, portalLoginError)
    .min(4, portalLoginLengthError);

const postalCodeMinError = i18n.t('errors:extensions.postalCode');
const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');

export const generateExtensionFormValidationSchema = (
    portalPasswordLength: number,
) =>
    Yup.object().shape(
        {
            extensionNumber: Yup.string()
                .max(5, numberMaxLengthError)
                .matches(/^[0-9]*$/, numberDigitsError)
                .required(emptyField),
            extensionName: Yup.string().notRequired(),
            didNumber: Yup.array().notRequired(),
            displayNumber: Yup.string().notRequired().nullable(),
            emailAddress: Yup.string().email().notRequired(),
            timezone: Yup.string().required(emptyField),
            portalLogin: Yup.string().when('portalPassword', {
                is: (v: string) => !v,
                then: loginValidation.notRequired(),
                otherwise: loginValidation.required(emptyField),
            }),
            portalPassword: Yup.string().when('portalLogin', {
                is: (v: string) => !v,
                then: Yup.string()
                    .min(
                        portalPasswordLength,
                        i18n.t('errors:extensions.portalPasswordWithLength', {
                            value: portalPasswordLength,
                        }),
                    )
                    .notRequired(),
                otherwise: Yup.string()
                    .min(
                        portalPasswordLength,
                        i18n.t('errors:extensions.portalPasswordWithLength', {
                            value: portalPasswordLength,
                        }),
                    )
                    .required(emptyField),
            }),
            office: Yup.string().notRequired(),
            country: Yup.string().nullable().notRequired(),
            address: Yup.string().notRequired(),
            city: Yup.string().notRequired(),
            postalCode: Yup.string().min(2, postalCodeMinError).notRequired(),
            state: Yup.string().nullable().notRequired(),
            includeCorpDirectory: Yup.bool().required(),
            recordName: Yup.string().notRequired(),
            recordFile: Yup.object().nullable().notRequired(),
            extensionRingGroups: Yup.array().required(),
        },
        [['portalLogin', 'portalPassword']],
    );

export const extensionFormDefaultValues: ExtensionFormType = {
    extensionNumber: '',
    extensionName: '',
    didNumber: [],
    displayNumber: '',
    emailAddress: '',
    timezone: '',
    portalLogin: '',
    portalPassword: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
    office: '',
    includeCorpDirectory: false,
    recordName: '',
    recordFile: null,
    extensionRingGroups: [],
};

export type ExtensionFormProps = {
    didNumbers?: CustomerDIDNumberType[];
    accountId?: number;
    isBranchOffice?: boolean;
    initFormData?: ExtensionFormType;
    timeZones?: TimeZone[];
    countries?: Country[];
    webConfig?: PasswordRules;
    subdivisionsList?: Subdivision[];
    apiErrors?: APIErrorInterface;
    handleSubmitForm?: (formData: Partial<EditExtensionForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    prefixEnabled?: boolean;
};

export function generateDisplayNumberList(
    value: string,
    realValue: string,
    prefixEnabled: boolean,
    prefixMark: string,
    simple = true,
): string | { value: string; label: string } {
    let displayValue = value;
    if (prefixEnabled && value != realValue) {
        const indexOfPrefix = value.indexOf(prefixMark);
        displayValue =
            prefixEnabled && indexOfPrefix !== 0
                ? `${prefixMark}${value}`
                : value;
    } else if (`+${value}` == realValue) {
        displayValue = realValue;
    }

    return simple ? displayValue : { label: displayValue, value: displayValue };
}
