import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '../TextField/TextField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import { IconButton } from '@material-ui/core';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { APIErrorInterface, ReduxState } from '../../store/types';
import { useSelector } from 'react-redux';
import i18n from '../../services/i18n';
import { useFormikContext } from 'formik';
import { ReactComponent as Copy } from '../../assets/copy-content.svg';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { ChangeDirtyStateDetailsTab } from '../../views/Extensions/ExtensionDetails/utils';
import { getConfigFromPasswordRulesOrDefault } from '../../utils/passwordGenerator';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField';

const useStyle = makeStyles(() => ({
    root: {},
    itemsContainer: {
        marginTop: -10,
        '& .MuiFormControl-root': {
            width: '100%',
            maxWidth: 440,
            marginBottom: 16,
        },
        '& .MuiGrid-root': {
            maxWidth: 440,
            margin: '16px',
            padding: 0,
        },
    },
    iconColors: {
        backgroundColor: Colors.White,
        '& .MuiSvgIcon-root': {
            '& path': {
                fill: `${Colors.PlaceHolder} !important`,
                fillOpacity: 1,
            },
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },

    controls: {
        '& .MuiFormControl-root:last-child': {
            marginBottom: 16,
        },
    },

    copyIcon: {
        height: '20px',
        cursor: 'pointer',
        '& path': {
            fill: Colors.PlaceHolder,
            fillOpacity: 1,
        },
    },

    tooltip: {
        overflowWrap: 'anywhere',
    },
}));

export interface SipFormType {
    registrationName: string;
    servicePassword: string;
    userAgent: string;
    iAccount: number;
    sipInfoErrors: APIErrorInterface | undefined;
    savingSipInfo: boolean | undefined;
    sipContact: string;
}

export const sipDefaultValues: SipFormType = {
    registrationName: '',
    servicePassword: '',
    userAgent: '',
    iAccount: 0,
    sipInfoErrors: undefined,
    savingSipInfo: false,
    sipContact: '',
};

type CopyContentIconProps = {
    toastMsg?: string;
    tooltipMsg?: string;
    copyContent?: string;
};

export const SipValidationSchema = Yup.object().shape({
    servicePassword: Yup.string().required(
        i18n.t<string>('errors:common.emptyInput'),
    ),
});

const CopyContentIcon: React.FC<CopyContentIconProps> = ({
    toastMsg,
    tooltipMsg,
    copyContent,
}) => {
    const classes = useStyle();
    const toastString = toastMsg || i18n.t('common:textIsCopied');
    const handleOnClick = () => {
        navigator.clipboard.writeText(copyContent || '');

        toast(toastString || '');
    };
    return (
        <CustomizedTooltip title={tooltipMsg} copy={false}>
            <Copy
                data-testid="copy-button"
                onClick={handleOnClick}
                className={classes.copyIcon}
            />
        </CustomizedTooltip>
    );
};

interface SipProps {
    register: boolean;
    apiErrors?: APIErrorInterface;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
}

const Sip: React.FC<SipProps> = ({
    apiErrors,
    register = true,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const textInputContactDetailsRef = useRef<HTMLInputElement>(null);
    const [copyRegNameVisible, setCopyRegNameVisible] = useState(false);
    const [copyPasswordNameVisible, setCopyPasswordNameVisible] = useState(
        false,
    );

    const [showTooltip, setShowTooltip] = useState(false);

    const rules = useSelector(
        (state: ReduxState) => state.generic.configData?.Web,
    );

    const handleChangeVisible = () => {
        setCopyRegNameVisible((prevState) => !prevState);
    };
    const handlePasswordCopyVisible = () => {
        setCopyPasswordNameVisible((prevState) => !prevState);
    };

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        handleSubmit,
        isValid,
        dirty,
    } = useFormikContext<SipFormType>();

    const notSetText = t('common:notSet');
    const setNewPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setFieldValue('servicePassword', e.target.value);
    };

    useEffect(() => {
        handleDirtyChange?.('device', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetIsValidFunc?.('device', () => isValid);
    }, [isValid]);

    useEffect(() => {
        handleSetSubmitFunc?.('device', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        if (
            values.sipContact.length &&
            textInputContactDetailsRef.current !== null
        ) {
            setShowTooltip(
                textInputContactDetailsRef.current.offsetWidth <
                    textInputContactDetailsRef.current.scrollWidth,
            );
        }
    }, [textInputContactDetailsRef]);

    return (
        <div data-testid={'sip-info-details'} data-qa={'sip-info-details'}>
            <Grid container spacing={3} className={classes.itemsContainer}>
                <Grid item xs={6} className={classes.controls}>
                    <div>
                        <TextField
                            onMouseEnter={handleChangeVisible}
                            onMouseLeave={handleChangeVisible}
                            id="registrationName"
                            label={t('screens:extensions.registrationName')}
                            onChange={handleChange}
                            value={values.registrationName}
                            icon={
                                <>
                                    <div
                                        style={{
                                            visibility: copyRegNameVisible
                                                ? 'visible'
                                                : 'hidden',
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            className={classes.visibilityIcon}
                                            data-testid="registration-name-visibility-button"
                                            data-qa="registration-name-visibility-button"
                                        >
                                            <CopyContentIcon
                                                copyContent={
                                                    values.registrationName
                                                }
                                                tooltipMsg={t(
                                                    'tooltips:extensions.copyRegistrationName',
                                                )}
                                            />
                                        </IconButton>
                                    </div>
                                    <IconWithTooltip
                                        dataQa="sip-registration-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:extensions.registrationName',
                                        )}
                                    />
                                </>
                            }
                            iconPosition="end"
                            dataQa="sip-registration-name-input"
                            readOnly
                        />
                    </div>
                    <PasswordTextField
                        onMouseEnter={handlePasswordCopyVisible}
                        onMouseLeave={handlePasswordCopyVisible}
                        id="servicePassword"
                        label={t('screens:extensions.servicePassword')}
                        value={values.servicePassword}
                        setFieldValue={setFieldValue}
                        onChange={setNewPassword}
                        dataQa="sip-service-password-input"
                        setFieldError={setFieldError}
                        error={
                            apiErrors
                                ? t('errors:common.emptyInput')
                                : errors.servicePassword
                        }
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            rules,
                        )}
                        icon={
                            <>
                                <div
                                    style={{
                                        visibility: copyPasswordNameVisible
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.visibilityIcon}
                                        data-testid="service-password-visibility-button"
                                        data-qa="service-password-visibility-button"
                                    >
                                        <CopyContentIcon
                                            copyContent={values.servicePassword}
                                            tooltipMsg={t(
                                                'tooltips:extensions.copyServicePassword',
                                            )}
                                        />
                                    </IconButton>
                                </div>
                            </>
                        }
                    />
                </Grid>

                {register && (
                    <Grid item xs={6} className={classes.controls}>
                        <TextField
                            id="userAgent"
                            label={t('screens:extensions.userAgent')}
                            onChange={handleChange}
                            value={values.userAgent || notSetText}
                            dataQa="sip-user-agent-input"
                            readOnly
                        />

                        <CustomizedTooltip
                            title={values.sipContact}
                            interactive
                            copy
                            disableHoverListener={!showTooltip}
                            classes={{ text: classes.tooltip }}
                        >
                            <div>
                                <TextField
                                    id="contactDetails"
                                    inputRef={textInputContactDetailsRef}
                                    label={t(
                                        'screens:extensions.contactDetails',
                                    )}
                                    onChange={handleChange}
                                    value={values.sipContact}
                                    dataQa="sip-user-contact-details"
                                    readOnly
                                />
                            </div>
                        </CustomizedTooltip>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default Sip;
