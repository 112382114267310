import { YesNo } from './CallScreening';

export interface UAInfo {
    name: string;
    used: string;
    ports_config: UAPortConfiguration[];
    mac: string;
    inventory_id: string;
    i_ua: number;
    type: string;
    busy_ports?: number[];
    available_ports?: number[];
    description?: string;
    profile?: string;
    i_tenant_customer?: number;
    tenant_customer_name?: string;
    is_multiservice?: number;
    shared?: string;
    ascii_key?: string;
    ua_profile_id?: number;
    i_customer?: number | null;
    i_ua_type?: number;
}

export interface UAInfoList extends UAInfo {
    id: number;
}

export interface UAPortConfiguration {
    account_id?: string;
    allowed_services?: UAPortApplicableServiceInfo[];
    i_account?: number;
    i_service_type?: number;
    physical_interface_number?: number;
    port: number;
    release_allowed: YesNo;
    extension_id?: string;
    i_did_number?: string;
    did_number?: string;
    extension_name?: string;
}

export interface UAPortApplicableServiceInfo {
    i_service_type: number;
}

export interface GetUAListResponse {
    total: number;
    ua_list: UAInfo[];
}

export enum UAUsage {
    Used = 'U',
    CompletelyUsedAllPorts = 'C',
    NotUsed = 'F',
    Default = 'A',
}

export interface GetUAListRequest {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
    get_total: number;
    offset: number;
    limit?: number;
    with_available_ports?: number;
    with_busy_ports?: number;
    i_ua?: number;
}

export interface UAListPayload {
    items: UAInfo[];
    total: number;
    active?: number;
}

export interface DeviceFilters {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
}

export interface ReleaseDevicePayload {
    i_c_ext: number;
    i_cpe?: string | null;
}

export interface ReleaseDeviceResponse {
    i_c_ext: number;
}

export interface GetUAInfoRequest {
    i_account: number;
}
export interface GetUAInfoResponse {
    ua_info: UAInfo;
}
