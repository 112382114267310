import React, { useState } from 'react';
import { Assignment, Delete, Details, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CreationButton from '../../../../components/CreationButton/CreationButton';
import AddPaymentMethodDialog from '../../../../components/Forms/Billing/AddPaymentMethodDialog/AddPaymentMethodDialog';
import CustomizedIconButton from '../../../../components/IconButton/IconButton';
import PaymentMethodDetails from '../../../../components/Payments/PaymentMethodDetails/PaymentMethodDetails';
import { ReduxState } from '../../../../store/types';
import {
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import { useStyles } from './DefaultPaymentMethod.utils';
import AlertDialog from '../../../../components/AlertDialog/AlertDialog';
import { DialogButton } from '../../../../components/AlertDialog/DialogContainer';
import { actions } from '../../../../store';
import { usePermissionContext } from '../../../../hooks/usePermissions';
import { PermissionType } from '../../../../store/types/Permission';

const DefaultPaymentMethod = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [
        isPaymentMethodDialogVisible,
        setIsPaymentMethodDialogVisible,
    ] = useState(false);
    const [paymentMethodToEdit, setPaymentMethodToEdit] = useState<
        PaymentMethodInfo | undefined
    >(undefined);
    const [
        isRemovePaymentMethodDialogVisible,
        setIsRemovePaymentMethodDialogVisible,
    ] = useState(false);

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const removePaymentMethod = () => {
        dispatch(actions.removeDefaultPaymentMethod.request());
    };
    
    const permission = usePermissionContext();

    return (
        <div className={classes.container}>
            <label className={classes.header}>
                {t('screens:billing.defaultPaymentMethod')}
            </label>

            {!ownerPaymentsMethods?.length && (
                <>
                    <span className={classes.noMethodLabel}>
                        {t('screens:billing.noSuitablePaymentMethod')}
                    </span>
                    <span>
                        {t('screens:billing.pleaseContactAdministrator')}
                    </span>
                </>
            )}

            {!!ownerPaymentsMethods?.length && paymentMethod && (
                <div className={classes.paymentMethodContainer}>
                    <div className={classes.methodInsidecontainer}>
                        <PaymentMethodDetails method={paymentMethod} />
                    </div>

                    {permission === PermissionType.ReadOnly ? (
                        <CustomizedIconButton
                            tooltipText={t('tooltips:billing.viewPaymentMethodDetails')}
                            onClick={() => {
                                setPaymentMethodToEdit(paymentMethod);
                                setIsPaymentMethodDialogVisible(true);
                            }}
                            dataTestId="view-pm-button"
                            skipPermission
                        >
                            <Assignment />
                        </CustomizedIconButton>
                    ) : (
                        <CustomizedIconButton
                            tooltipText={t('screens:billing.editPaymentMethod')}
                            onClick={() => {
                                setPaymentMethodToEdit(paymentMethod);
                                setIsPaymentMethodDialogVisible(true);
                            }}
                            dataTestId="edit-method-button"
                        >
                            <Edit />
                        </CustomizedIconButton>
                    )}
                    <CustomizedIconButton
                        tooltipText={t('screens:billing.deletePaymentMethod')}
                        className={classes.secondIcon}
                        onClick={() =>
                            setIsRemovePaymentMethodDialogVisible(true)
                        }
                        dataTestId="delete-method-button"
                    >
                        <Delete />
                    </CustomizedIconButton>
                </div>
            )}

            {!!ownerPaymentsMethods?.length && !paymentMethod && (
                <CreationButton
                    title={t('screens:billing.addMethod')}
                    className={classes.noMethodLabel}
                    onClick={() => {
                        setPaymentMethodToEdit(undefined);
                        setIsPaymentMethodDialogVisible(true);
                    }}
                />
            )}

            <AddPaymentMethodDialog
                isOpen={isPaymentMethodDialogVisible}
                paymentMethodToEdit={paymentMethodToEdit}
                toggleVisibility={() => setIsPaymentMethodDialogVisible(false)}
            />

            <AlertDialog
                isOpen={isRemovePaymentMethodDialogVisible}
                description={t('screens:billing.deleteDefaultPaymentMethod')}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() =>
                            setIsRemovePaymentMethodDialogVisible(false)
                        }
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={removePaymentMethod}
                    />,
                ]}
                dataQa="remove-payment-method-modal"
                dataTestId="remove-payment-method-modal"
            />
        </div>
    );
};

export default DefaultPaymentMethod;
