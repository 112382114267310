import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const useBasePath = () => {
    const location = useLocation();
    const params = useParams<Record<string, string>>();

    return Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
};

export default function usePageTitle() {
    const { t } = useTranslation();
    const basePath = useBasePath();

    const chooseCorrectName = () =>
        i18n.exists(`screens:pageTitlesViaPath.${basePath}`)
            ? t(`screens:pageTitlesViaPath.${basePath}`)
            : t(`screens:pageTitlesViaPath.defaultName`);

    useEffect(() => {
        document.title = chooseCorrectName();
    }, []);
}
