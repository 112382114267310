import React, {useEffect, useState} from 'react';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import {Service, ServiceIcon} from '../../../Extensions/ServiceIcon';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import {cloneDeep} from 'lodash';
import {
    CallScreeningFormType,
    generatePolicyColumns,
    generateRulesColumns,
    useStyles,
} from './CallScreeningForm.utils';
import CheckboxWithIcon from '../../../Checkbox/CheckboxWithIcon';
import DataGrid from '../../../DataGrid/DataGrid';
import classNames from 'classnames';
import {CPRuleInfoDetails, YesNo,} from '../../../../store/types/CallScreening';
import {v4 as uuidv4} from 'uuid';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import {DialogButton} from '../../../AlertDialog/DialogContainer';
import {ChangeDirtyStateDetailsTab} from '../../../../views/Extensions/ExtensionDetails/utils';
import {reorder} from '../../../DataTable/DragDropDataTable';
import {usePermissionContext} from '../../../../hooks/usePermissions';
import {PermissionType} from '../../../../store/types/Permission';

interface CallScreeningFormInterface {
    service?: Service;
    mode?: string;
    handleSubmitForm?: (formData: Partial<CallScreeningFormType>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    showPBX?: boolean;
    forceTooltip?: string;
    extensionNumber?: string;
}

const CallScreeningForm: React.VFC<CallScreeningFormInterface> = ({
                                                                      service,
                                                                      mode,
                                                                      handleDirtyChange,
                                                                      handleSetSubmitFunc,
                                                                      handleSetIsValidFunc,
                                                                      showPBX = true,
                                                                      forceTooltip,
                                                                      extensionNumber,
                                                                  }) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const permission = usePermissionContext();

    const {
        setFieldValue,
        values,
        dirty,
        handleSubmit,
        isValid,
    } = useFormikContext<CallScreeningFormType>();
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        id?: string;
        i_cp_rule?: number;
        extensionNumber?: string;
    }>({
        isOpen: false,
    });

    useEffect(() => {
        handleDirtyChange?.('callScreening', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callScreening', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callScreening', () => isValid);
    }, [isValid]);

    const handleDeleteRuleDialog = (id: string, i_cp_rule?: number) => {
        setDeleteDialog({isOpen: true, id, i_cp_rule, extensionNumber});
    };

    const handleChangeStatus = (id: string) => {
        const newRules: CPRuleInfoDetails[] = [];

        values.rules?.forEach((rule) => {
            if (rule.id == id && rule.i_cp_rule != -1) {
                newRules.push({
                    ...rule,
                    is_active:
                        rule.is_active == YesNo.Yes ? YesNo.No : YesNo.Yes,
                });
            } else if (
                rule.id == id &&
                rule.i_cp_rule == -1 &&
                rule.policyDetails
            ) {
                const newNotSelectedPolicy = rule.policyDetails
                    ? values.notSelectedPolicy?.map((policy) =>
                        policy.i_cp_policy == rule.policyDetails?.i_cp_policy
                            ? {...policy, active: false}
                            : policy,
                    )
                    : [];

                setFieldValue('notSelectedPolicy', newNotSelectedPolicy);
            } else {
                newRules.push(rule);
            }
        });

        setFieldValue('rules', newRules);
    };

    const handleChangeUnusedPolicyState = (id: number) => {
        const newRules = values.rules ? cloneDeep(values.rules) : [];
        const selectedPolicy = cloneDeep(
            values.notSelectedPolicy?.find(
                (policy) => policy.i_cp_policy == id,
            ),
        );

        if (selectedPolicy) {
            selectedPolicy.active = true;
        }

        const newNotSelectedPolicy = values.notSelectedPolicy?.map((policy) =>
            policy.i_cp_policy != id ? policy : selectedPolicy,
        );

        newRules.push({
            id: uuidv4(),
            i_cp_rule: -1,
            is_active: YesNo.Yes,
            i_cp_policy: id,
            policyDetails: selectedPolicy,
            showOnlyPolicy: true,
            order_number: values.rules ? values.rules.length + 1 : 1,
        });

        setFieldValue('rules', newRules);
        setFieldValue('notSelectedPolicy', newNotSelectedPolicy);
    };

    const columnsForRules = generateRulesColumns(
        t,
        handleDeleteRuleDialog,
        handleChangeStatus,
        classes,
    );

    const columnsForNotUsedPolicy = generatePolicyColumns(
        t,
        handleChangeUnusedPolicyState,
    );

    const handleDeleteAction = () => {
        setFieldValue(
            'rules',
            values.rules?.filter((rule) => rule.id != deleteDialog.id) || [],
        );
        setDeleteDialog({
            isOpen: false,
            id: undefined,
            i_cp_rule: undefined,
            extensionNumber: undefined,
        });
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            id: undefined,
            i_cp_rule: undefined,
            extensionNumber: undefined,
        });
    };

    const notSelectedPolicy = values.notSelectedPolicy?.filter(
        (policy) => !policy.active,
    );

    const onChangeItemPosition = (startIndex: number, endIndex: number) => {
        const rulesWithNewPosition = reorder(
            values.rules || [],
            startIndex,
            endIndex,
        ).map((rule, key) => ({
            ...rule,
            order_number: key + 1,
        }));
        setFieldValue('rules', rulesWithNewPosition);
    };

    return (
        <div className={classes.root} data-testid={'call-screening-form'}>
            <SwitchWithLabel
                id="callScreening"
                field="callScreening"
                label={t('screens:extensions.callScreening')}
                value={values.callScreening}
                disabled={values.callScreeningLocked}
                setValue={() =>
                    setFieldValue('callScreening', !values.callScreening)
                }
                icon={
                    <ServiceIcon
                        type={service ? service : Service.CallProcessing}
                        dataQa="call-processing-service"
                        dataTestId="call-processing-service"
                        mode={mode}
                        forceTooltip={forceTooltip}
                    />
                }
                className={classes.swicher}
                dataQa={'call-screening-component'}
            />
            {values.callScreening && (
                <>
                    {showPBX && (
                        <CheckboxWithIcon
                            dataQa={'pbx-managment-checkbox'}
                            dataTestId={'pbx-managment-checkbox'}
                            label={t('screens:extensions.pbxManagment')}
                            tooltipText={t('tooltips:extensions.pbxManagment')}
                            checked={values.pbxEnabled}
                            value={values.pbxEnabled}
                            onChange={() =>
                                setFieldValue('pbxEnabled', !values.pbxEnabled)
                            }
                            containerStyle={classes.pbx}
                            disabled={values.callScreeningLocked}
                        />
                    )}

                    {!values.rules?.length && !notSelectedPolicy?.length && (
                        <span className={classes.noRules}>
                            {t('screens:extensions.noRules')}
                        </span>
                    )}

                    <>
                        {!!values.rules?.length && (
                            <DataGrid
                                columns={columnsForRules}
                                data={values.rules || []}
                                rowCount={values.rules?.length || 0}
                                dragEnabled={
                                    permission === PermissionType.Visible
                                }
                                hidePagination
                                hideFooter
                                className={classNames(classes.table, permission === PermissionType.ReadOnly && classes.disabled)}
                                classes={{
                                    tableContainer: classNames(
                                        classes.tableContainer,
                                        classes.rulesTable,
                                    ),
                                }}
                                dragHeader={t('screens:extensions.order')}
                                onChangeItemPosition={onChangeItemPosition}
                                dragColumnStyle={classes.dragColumnStyle}
                                dragHeaderStyle={classes.dragHeaderStyle}
                                dataQa={'rules-table'}
                            />
                        )}

                        {!!notSelectedPolicy?.length && (
                            <DataGrid
                                columns={columnsForNotUsedPolicy}
                                data={notSelectedPolicy || []}
                                rowCount={notSelectedPolicy?.length || 0}
                                dragEnabled={false}
                                hidePagination
                                hideFooter
                                hideHeader
                                className={classNames(classes.table, permission === PermissionType.ReadOnly && classes.disabledNotUsedPolicy)}
                                classes={{
                                    tableContainer: classNames(
                                        classes.tableContainer,
                                        classes.tablePolicyContainer,
                                    ),
                                }}
                                dataQa={'policy-table'}
                            />
                        )}
                    </>
                </>
            )}

            <AlertDialog
                className={classes.deleteDialog}
                contentClass={classes.contentDialog}
                isOpen={deleteDialog.isOpen}
                dataQa={'delete-rule-dialog'}
                hideHeader
                description={t('screens:extensions.deleteRule', {
                    id: deleteDialog.extensionNumber,
                })}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={closeDeleteDialog}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                        onClick={handleDeleteAction}
                    />,
                ]}
            />
        </div>
    );
};

export default CallScreeningForm;
