import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/ar';
import 'dayjs/locale/zh';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/cs';
import 'dayjs/locale/et';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/lv';
import 'dayjs/locale/lt';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/sr';
import 'dayjs/locale/sl';
import 'dayjs/locale/es';
import 'dayjs/locale/sv';

dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);


let LOCALE = window.localStorage.getItem('user_language') || 'en';

if(LOCALE == 'no')
{
    LOCALE = 'nb'
}

dayjs.locale(LOCALE);

export default dayjs;
