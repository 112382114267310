import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    chartMainContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    chartContainer: {
        width: 110,
        position: 'relative',
    },
    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 70,
        borderRadius: '50%',
        border: `transparent solid 20px`,
    },
    chartEmptyBackground: {
        borderColor: Colors.BorderDark,
    },
    chartLoadingBackground: {
        borderColor: Colors.SmokeBackground,
    },
    sumValue: {
        width: 110,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 42,
    },
    sumText: {
        fontSize: 22,
    },
    labelsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 10px 0 10px',
    },
    dot: {
        width: 6,
        height: 6,
        borderRadius: 3,
        margin: '10px 10px 10px 0',
    },
    labelItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    labelText: {
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
    labelItemContainer: {
        display: 'flex',
    },
    loadingLabel: {
        backgroundColor: Colors.White,
        width: 110,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 42,
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
}));

export type DoughnutChartProps = {
    data: {
        label: string;
        color: string;
        value: number;
    }[];
    isLoading: boolean;
};
