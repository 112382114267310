import { makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import { Assignment } from '@material-ui/icons';
import React from 'react';
import TwoLineCell from '../../../components/Calls/TwoLineCell';
import { convertUserLocalTimeToUtc } from '../../../utils/dateWithTimezoneConversion';
import { TransactionFiltersFormType } from '../../../components/TransactionFilters/TransactionFilters';
import { Colors, getServiceColor } from '../../../styles/Colors';
import ColorMarkCell from '../../../components/Calls/ColorMarkCell';

export const mock = [
    {
        id: 1,
        type: 'Subscriptions',
        total: 12,
        amount: 3131.84,
        charged: 3131.84,
        isSummary: false,
    },
    {
        id: 2,
        type: 'Total',
        total: 12,
        amount: 3131.84,
        charged: null,
        isSummary: true,
    },
];

export interface TransactionTableRow {
    id: string;
    type: string;
    total: number;
    charged: number | null;
    amount: number;
    isSummary: boolean;
    unit: string;
    unitShort: string;
    iService?: number;
    iServiceType?: number;
    ratio?: number;
    isInteger?: boolean;
    isSpending: boolean;
}

export const getFilterPayload = (
    filters: TransactionFiltersFormType,
    timezoneOffset: number,
) => {
    return {
        from_date: filters.fromDate
            ? convertUserLocalTimeToUtc(filters.fromDate, timezoneOffset)
            : undefined,
        to_date: filters.toDate
            ? convertUserLocalTimeToUtc(filters.toDate, timezoneOffset)
            : undefined,
        show_unsuccessful: 0,
        i_service: undefined
    };
};

export const useStyles = makeStyles(() => ({

    toRight: {
        alignItems: 'end',
    },

    total: {
        padding: '10px 0',
        marginLeft: 55
    },

    typeLabelCell: {
        color: Colors.Link
    },
    
    downloadButton: {
        maxWidth: 181,
        backgroundColor: Colors.Secondary5,
        '& .MuiButton-label': {
            color: Colors.Secondary1,
        },

        '& svg > path': {
            fill: Colors.Secondary1,
        },
        '&:hover': {
            backgroundColor: Colors.Secondary1,
            '& .MuiButton-label': {
                color: Colors.White,
            },
            '& svg > path': {
                fill: Colors.White,
            },
        },
        fontWeight: 700,
        borderRadius: '4px',
        padding: '6px 12px',
        textTransform: 'none',
        fontSize: 16,
        minWidth: 96
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    isSpendings: boolean,
    currency = 'USD',
    classes: ReturnType<typeof useStyles>,
    handleDetails: (
        serviceName: string,
        i_service: number,
        i_service_type: number,
        index: number,
        ratio?: number,
        unit?: string,
    ) => void,
): Column<TransactionTableRow>[] => {
    let isSummary = false;
    
    const formatAmount = (v: number) => {
        return v.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    }

    return [
        {
            Header: t<string>('common:type'),
            accessor: 'type',
            minWidth: 40,
            width: 40,
            Cell: function Cell(params) {
                isSummary = params.row.original.isSummary;
                const text = params.row.original.type;
                const iServiceId = +(params.row.original.iService || 0);

                return isSummary ? (
                    <b className={classes.total}>{text}</b>
                ) : (
                    <ColorMarkCell
                        text={text}
                        color={getServiceColor(iServiceId)}
                        textClassName={classes.typeLabelCell}
                        onClick={() => {
                                params.row.original.iService !== undefined &&
                                params.row.original.iServiceType !== undefined &&
                                handleDetails(
                                    params.row.original.type,
                                    params.row.original.iService,
                                    params.row.original.iServiceType,
                                    params.row.index,
                                    params.row.original.ratio,
                                    params.row.original.unitShort,
                                )
                            }
                        }
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.total'),
            accessor: 'total',
            minWidth: 25,
            width: 25,
            Cell: function Cell(params) {
                return isSummary ? (
                    <span>{params.row.original.total}</span>
                ) : params.row.original.charged ? (
                    <TwoLineCell
                        headerText={
                            params.row.original.total.toString()
                        }
                        descriptionText={
                            params.row.original.isInteger
                                ? params.row.original.charged.toString() + ' ' + params.row.original.unit
                                : formatAmount(params.row.original.charged) + ' ' + params.row.original.unit
                            }
                        className={classes.toRight}
                    />
                ) : (
                    params.row.original.total
                );
            },
        },
        {
            Header: isSpendings ? t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }) : t<string>('screens:billing.amountWithCurrency', {
                currency: currency,
            }),
            accessor: 'amount',
            minWidth: 20,
            width: 20,
            Cell: function Cell(params) {
                return isSummary ? (
                    <b>{formatAmount(params.row.original.amount)}</b>
                ) : (
                    formatAmount(params.row.original.amount)
                );
            },
        },
        {
            Header: t<string>('screens:billing.details'),
            accessor: 'id',
            minWidth: 15,
            width: 15,
            Cell: function Cell(params) {
                return !isSummary ? (
                    <CustomizedIconButton
                        tooltipText={t('tooltips:billing.transactionDetails')}
                        dataQa="details-icon-button"
                        dataTestId="details-icon-button"
                        onClick={() =>
                            params.row.original.iService !== undefined &&
                            params.row.original.iServiceType !== undefined &&
                            handleDetails(
                                params.row.original.type,
                                params.row.original.iService,
                                params.row.original.iServiceType,
                                params.row.index,
                                params.row.original.ratio,
                                params.row.original.unitShort,
                            )
                        }
                    >
                        <Assignment />
                    </CustomizedIconButton>
                ) : (
                    ''
                );
            },
        },
    ];
};
