import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormikErrors, useFormikContext } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import TextField from '../TextField/TextField';
import { Colors } from '../../styles/Colors';
import Radio from '../Radio/Radio';
import SelectField from '../SelectField/SelectField';
import * as Yup from 'yup';
import { Columns } from '@material-ui/data-grid';
import { Delete } from '@material-ui/icons';
import DragDropDataTable from '../DataTable/DragDropDataTable';
import {
    daysSelectItems,
    monthsSelectItems,
    validateDayOfMonth,
    yearsSelectItems,
} from '../../utils/extensions/RingScheduleSummary';
import { Interval } from '../../store/types/RingGroup';
import ExistingIntervalRow from './ExistingIntervalRow';
import { TimeWindowIntervalsDetails } from '../../store/types/CallScreening';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import { CallForwardingRule } from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import CreationButton from '../CreationButton/CreationButton';
import TimeRangePicker from '../TimeRangePicker/TimeRangePicker';
import {
    convertRangeToStartEndTime,
    prepareDefaultRangeDate,
} from '../TimeRangePicker/TimeRangePicker.utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import i18n from '../../services/i18n';
import AlertDialog from '../AlertDialog/AlertDialog';
import { DialogButton } from '../AlertDialog/DialogContainer';
import CustomizedIconButton from '../IconButton/IconButton';
import classNames from 'classnames';

const DeleteIcon = <Delete htmlColor={Colors.Gray5} />;

type SelectItem = {
    name: string;
    value: number;
    apiValue: string;
};

export enum IntervalStatus {
    Always = '0',
    OnlyFollowingTimeInterval = '1',
}

export interface IntervalFormProps {
    activity: IntervalStatus;
    oldIntervals: TimeWindowIntervalsDetails[];
    newIntervals: Interval[];
    timeFilterIndex?: number;
}

export const intervalFormInitialValues: IntervalFormProps = {
    activity: IntervalStatus.Always,
    oldIntervals: [],
    newIntervals: [],
};

const incorrectFormat = i18n.t<string>('errors:extensions.incorrectFormat');

export const intervalFormValidationSchema = Yup.object().shape({
    activity: Yup.string().notRequired(),
    newIntervals: Yup.array()
        .of(
            Yup.object().shape({
                startTime: Yup.string().length(5, incorrectFormat),
                endTime: Yup.string().length(5, incorrectFormat),
                daysOfMonth: Yup.string()
                    .test(validateDayOfMonth)
                    .notRequired(),
                days: Yup.array().notRequired(),
                months: Yup.array().notRequired(),
            }),
        )
        .notRequired(),
});

const useStyles = makeStyles(() => ({
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiInputLabel-root': {
            marginLeft: 10,
            zIndex: 2,
        },
        '& input[type="time"]::-webkit-calendar-picker-indicator': {
            display: 'none',
            '-webkit-appearance': 'none',
        },

        '& .MuiTableContainer-root': {
            position: 'inherit',
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 1200,
            maxHeight: '60vh',
        },
    },
    groupSelect: {
        maxWidth: 392,
    },
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray7,
        marginBlockEnd: '0',
    },
    table: {
        '& .MuiTableCell-root': {
            padding: 2,
            paddingTop: 3,
            paddingBottom: 5,
            alignItems: 'flex-start',
            display: 'flex',
        },
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '10px !important',
        },
        '& .MuiFormControl-root': {
            marginRight: '10px !important',
        },
    },
    pagination: {
        visibility: 'hidden',
        '& .MuiInputBase-input:first-of-type': {
            padding: '0 !important',
        },
    },
    actionButtons: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 58,
    },
    daysOfMonthContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        padding: '0 !important',
        minWidth: 190,
        '& .MuiFormHelperText-root': {
            marginTop: 0,
        },
        flex: 1,
    },
    daysOfMonthHint: {
        color: Colors.PlaceHolder,
        lineHeight: '12px',
        fontSize: 12,
        marginTop: 4,
        marginLeft: 15,
    },
    daysMonthsSelect: {
        display: 'flex',

        '& .MuiInputBase-root': {
            paddingBottom: 5,
            height: 'auto',
            paddingLeft: 10,
        },
    },

    selectWholeDayText: {
        fontSize: 12,
        marginRight: 20,
    },
    addInterval: {
        marginTop: 20,
        '& .MuiButton-label': {
            color: Colors.White + '!important',
            paddingLeft: '0!important',
            paddingRight: '0!important',
        },
    },
    secondRadio: {
        marginLeft: 20,
    },
    selectContainer: {
        minWidth: 'unset',
        flex: 1,

        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
            padding: '0!important',
            flexGrow: 'unset',
            minWidth: 'unset',
        },
    },
    removeDialogContainer: {
        '& .MuiDialogContent-root': {
            width: 325,
            minWidth: 325,
            backgroundColor: Colors.White,
        },
    },
}));

const CustomTags = (selected: string[]) => {
    //@ts-ignore - material ui lib bug
    const renderTagsValue = selected.map((v) => v.name).join(', ');
    return (
        <div
            style={{
                flex: 1,
                marginLeft: 5,
                width: 53,
            }}
        >
            <OverflowTooltip
                text={renderTagsValue}
                tooltip={renderTagsValue}
                copy={false}
            />
        </div>
    );
};

const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    setFieldValue: (fieldName: string, value: any, validate?: boolean) => void,
    deleteItem: (indexToRemove?: number, type?: 'old' | 'new') => void,
    errors: any,
    handleChange: (e: React.ChangeEvent<any>) => void,
    setFieldError: (field: string, value: string | undefined) => void,
    withoutYears?: boolean,
    ampm?: boolean,
    defaultIntervalType?: IntervalType,
): Columns => {
    return [
        {
            field: 'startTime',
            width: 220,
            renderCell: function IdText(params) {
                const helperErrors = errors?.intervals?.newIntervals?.[
                    params.rowIndex || 0
                ] as FormikErrors<Interval>;

                return (
                    <TimeRangePicker
                        label={t('screens:extensions.time')}
                        ampm={ampm}
                        defaultValue={prepareDefaultRangeDate(
                            params.row.startTime,
                            params.row.endTime,
                            ampm,
                        )}
                        onChange={(value) => {
                            const result = convertRangeToStartEndTime(
                                value,
                                ampm,
                            );
                            setFieldValue(
                                `intervals.newIntervals.[${params.rowIndex}].[startTime]`,
                                result.startTime,
                                false,
                            );
                            setFieldValue(
                                `intervals.newIntervals.[${params.rowIndex}].[endTime]`,
                                result.endTime,
                                false,
                            );
                            setFieldError(
                                `intervals.newIntervals.[${params.rowIndex}].[startTime]`,
                                undefined,
                            );
                            setFieldError(
                                `intervals.newIntervals.[${params.rowIndex}].[endTime]`,
                                undefined,
                            );
                        }}
                        helperText={
                            helperErrors?.startTime || helperErrors?.endTime
                        }
                    />
                );
            },
        },
        {
            field: 'daysOfMonth',
            flex: 0.8,
            renderCell: function IdText(params) {
                const helperText = (errors?.intervals?.newIntervals?.[
                    params.rowIndex || 0
                ] as FormikErrors<Interval>)?.daysOfMonth
                    ? t('errors:extensions.daysOfTheMonth')
                    : '';

                return (
                    <Box className={classNames(classes.daysOfMonthContainer)}>
                        <TextField
                            id={`intervals.newIntervals.[${params.rowIndex}].[daysOfMonth]`}
                            onChange={handleChange}
                            label={t('screens:ringSchedule.daysOfTheMonth')}
                            value={params.row.daysOfMonth}
                            helperText={helperText}
                            setFieldError={setFieldError}
                        />

                        <span className={classes.daysOfMonthHint}>
                            {!helperText && t('screens:extensions.example')}
                        </span>
                    </Box>
                );
            },
        },
        {
            field: 'days',
            flex: 0.7,
            renderCell: function IdText(params) {
                return (
                    <SelectField
                        label={t('screens:ringSchedule.days')}
                        items={daysSelectItems}
                        value={
                            daysSelectItems.filter((v) =>
                                params.row.days
                                    .map((v: SelectItem) => v.value)
                                    .includes(v.value),
                            ) || null
                        }
                        multiple
                        onChange={(_, value) => {
                            setFieldValue(
                                `intervals.newIntervals.[${params.rowIndex}].[days]`,
                                value,
                            );
                        }}
                        renderTags={CustomTags}
                        className={classes.daysMonthsSelect}
                        getOptionLabel={(v: SelectItem) => v.name}
                        getOptionSelected={(
                            option: SelectItem,
                            value: SelectItem,
                        ) => value.value === option.value}
                        classes={{
                            container: classes.selectContainer,
                        }}
                    />
                );
            },
        },
        {
            field: 'months',
            flex: 0.7,
            renderCell: function IdText(params) {
                return (
                    <SelectField
                        label={t('screens:ringSchedule.months')}
                        items={monthsSelectItems}
                        value={
                            monthsSelectItems.filter((v) =>
                                params.row.months
                                    .map((v: SelectItem) => v.value)
                                    .includes(v.value),
                            ) || null
                        }
                        multiple
                        onChange={(_, value) => {
                            setFieldValue(
                                `intervals.newIntervals.[${params.rowIndex}].[months]`,
                                value,
                            );
                        }}
                        renderTags={CustomTags}
                        className={classes.daysMonthsSelect}
                        getOptionLabel={(v: SelectItem) => v.name}
                        getOptionSelected={(
                            option: SelectItem,
                            value: SelectItem,
                        ) => value.value === option.value}
                        classes={{
                            container: classes.selectContainer,
                        }}
                    />
                );
            },
        },
        !withoutYears
            ? {
                  field: 'years',
                  flex: 0.7,
                  renderCell: function IdText(params) {
                      return (
                          <SelectField
                              label={t('screens:ringSchedule.years')}
                              items={yearsSelectItems}
                              value={
                                  yearsSelectItems.filter((v) =>
                                      params.row.years
                                          .map((v: SelectItem) => v.value)
                                          .includes(v.value),
                                  ) || null
                              }
                              multiple
                              onChange={(_, value) => {
                                  setFieldValue(
                                      `intervals.newIntervals.[${params.rowIndex}].[years]`,
                                      value,
                                  );
                              }}
                              renderTags={CustomTags}
                              className={classes.daysMonthsSelect}
                              getOptionLabel={(v: SelectItem) => v.name}
                              getOptionSelected={(
                                  option: SelectItem,
                                  value: SelectItem,
                              ) => value.value === option.value}
                              classes={{
                                  container: classes.selectContainer,
                              }}
                          />
                      );
                  },
              }
            : {
                  field: 'years',
                  width: 0,
              },
        {
            field: 'actions',
            width: 50,
            renderCell: function IdText(params) {
                return (
                    <div className={classes.actionButtons}>
                        <CustomizedIconButton
                            onClick={() =>
                                deleteItem(
                                    params.rowIndex,
                                    params.row.type ||
                                        defaultIntervalType ||
                                        'old',
                                )
                            }
                            dataTestId="remove-interval-button"
                            tooltipText={t('common:delete')}
                        >
                            {DeleteIcon}
                        </CustomizedIconButton>
                    </div>
                );
            },
        },
    ];
};

type IntervalSelectProps = {
    withoutYears?: boolean;
    defaultIntervalType?: IntervalType;
};

type IntervalType = 'old' | 'new';

const IntervalSelect: React.VFC<IntervalSelectProps> = ({
    withoutYears,
    defaultIntervalType = 'old',
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [
        intervalToDeleteType,
        setIntervalToDeleteType,
    ] = useState<IntervalType>(defaultIntervalType);

    useEffect(() => {
        setIntervalToDeleteType(defaultIntervalType);
    }, [defaultIntervalType]);

    const [intervalToDeleteIndex, setIntervalToDeleteIndex] = useState<
        number | undefined
    >(undefined);

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const confirmDelete = (indexToRemove?: number, type?: IntervalType) => {
        if (indexToRemove !== undefined) {
            setIntervalToDeleteIndex(indexToRemove);
            setIntervalToDeleteType(type || 'old');
        }
    };

    const deleteOldInterval = () => {
        if (intervalToDeleteIndex !== undefined) {
            setFieldValue(
                'intervals.oldIntervals',
                values.intervals?.oldIntervals.filter(
                    (_, index) => index !== intervalToDeleteIndex,
                ),
                false,
            );
            setIntervalToDeleteIndex(undefined);
        }
    };

    const deleteNewInterval = () => {
        if (intervalToDeleteIndex !== undefined) {
            setFieldValue(
                'intervals.newIntervals',
                values.intervals?.newIntervals.filter(
                    (_, index) => index !== intervalToDeleteIndex,
                ),
                false,
            );
            setIntervalToDeleteIndex(undefined);
        }
    };

    const addInterval = () => {
        setFieldValue(
            'intervals.newIntervals',
            [
                ...(values.intervals?.newIntervals || []),
                {
                    startTime: '00:00',
                    endTime: '23:59',
                    daysOfMonth: '',
                    days: [],
                    months: [],
                    years: [],
                    wholeDay: false,
                    type: 'new',
                },
            ],
            false,
        );
    };

    const {
        values,
        setFieldValue,
        errors,
        handleChange,
        setFieldError,
    } = useFormikContext<CallForwardingRule>();

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                setFieldValue,
                confirmDelete,
                errors,
                handleChange,
                setFieldError,
                withoutYears,
                outTimeFormat?.includes('AM'),
                intervalToDeleteType,
            ),
        [
            errors,
            values.intervals?.newIntervals,
            values.intervals?.oldIntervals,
            withoutYears,
            outTimeFormat,
            intervalToDeleteType,
        ],
    );

    return (
        <div>
            <Grid item className={classes.itemsContainer}>
                <p className={classes.activeHeader}>
                    {t('screens:addToRingGroup.active')}
                </p>

                <Grid>
                    <Radio
                        dataQa="status-always"
                        checked={
                            values.intervals?.activity === IntervalStatus.Always
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                            setFieldValue(
                                'intervals.activity',
                                IntervalStatus.Always,
                            );
                            setFieldValue('values.intervals.oldIntervals', []);
                            setFieldValue('values.intervals.newIntervals', []);
                        }}
                        label={t('screens:ringSchedule.always')}
                    />

                    <Radio
                        dataQa="status-following-intervals"
                        checked={
                            values.intervals?.activity ===
                            IntervalStatus.OnlyFollowingTimeInterval
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() =>
                            setFieldValue(
                                'intervals.activity',
                                IntervalStatus.OnlyFollowingTimeInterval,
                            )
                        }
                        label={t('screens:ringSchedule.onlyFollowingTime')}
                        className={classes.secondRadio}
                    />
                </Grid>

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                    <Grid>
                        {values.intervals?.oldIntervals?.map((o, index) => (
                            <ExistingIntervalRow
                                interval={o}
                                key={index}
                                onDelete={() =>
                                    confirmDelete(index, intervalToDeleteType)
                                }
                            />
                        ))}
                    </Grid>
                )}

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                    <DragDropDataTable
                        className={classes.table}
                        classes={{ pagination: classes.pagination }}
                        rows={values.intervals?.newIntervals}
                        columns={columns}
                        rowCount={values.intervals?.newIntervals?.length}
                        dragEnable={false}
                        hidePagination
                    />
                )}
            </Grid>

            {values.intervals?.activity ===
                IntervalStatus.OnlyFollowingTimeInterval && (
                <CreationButton
                    title={t('screens:ringSchedule.addInterval')}
                    onClick={addInterval}
                    className={classes.addInterval}
                />
            )}

            <AlertDialog
                isOpen={intervalToDeleteIndex !== undefined}
                description={t('screens:extensions.deleteTimeInterval')}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIntervalToDeleteIndex(undefined)}
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={
                            intervalToDeleteType === 'new'
                                ? deleteNewInterval
                                : deleteOldInterval
                        }
                    />,
                ]}
                dataQa="remove-interval-modal"
                dataTestId="remove-interval-modal"
            />
        </div>
    );
};

export default IntervalSelect;
