import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import { Edit } from '@material-ui/icons';
import { ReactComponent as CallQueue } from './../../../assets/call_queue.svg';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import classNames from 'classnames';
import { PermissionType } from '../../../store/types/Permission';
import { usePermissionContext } from '../../../hooks/usePermissions';
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import CustomizedTooltip from '../../Tooltip/Tooltip';

type RingGroupItemProps = {
    name: string;
    strategy: string;
    callQueue?: boolean;
    onEdit?: () => void;
    dataQa?: string;
};

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.BorderDark}`,
        padding: '8px 14px 10px 14px',
        borderRadius: 4,
        transition: 'border 0.4s, box-shadow 0.2s ',
        margin: 8,
        width: 'calc(100% * (1/4) - 16px)',
        cursor: 'pointer',

        '&:hover': {
            border: `1px solid ${Colors.Secondary1}`,
            boxShadow: `0px 4px 8px ${Colors.Gray8}`,

            '& .MuiSvgIcon-root': {
                fill: Colors.Gray5,
            },
        },
    },
    disabled: {
        backgroundColor: 'transparent',

        '&:hover': {
            border: `1px solid ${Colors.BorderDark}`,
            boxShadow: 'none',

            '& .MuiSvgIcon-root': {
                fill: Colors.Gray3,
            },
        },
    },

    nameHeader: {
        fontWeight: 'bold',
        fontSize: 14,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '24px',
    },
    strategyText: {
        fontSize: 11,
        color: Colors.Gray5,
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px',
    },
    strategyName: {
        fontWeight: 'bold',
    },
    icon: {
        height: 16,
        width: 16,
        marginLeft: 10,
        marginTop: 4,
    },
    callQueueIcon: {
        marginRight: 9,
    },
}));

const RingGroupItem: React.FC<RingGroupItemProps> = ({
    name,
    strategy,
    callQueue,
    onEdit,
    dataQa,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const permission = usePermissionContext();

    return (
        <CustomizedTooltip
            title={t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission === PermissionType.Visible}
        >
            <Grid
                item
                className={classNames(
                    classes.root,
                    permission === PermissionType.ReadOnly && classes.disabled,
                )}
                data-qa={dataQa}
                onClick={
                    permission === PermissionType.Visible ? onEdit : undefined
                }
            >
                <Grid container justify="space-between">
                    <OverflowTooltip
                        copy={false}
                        tooltip={name}
                        text={name}
                        classes={{ text: classes.nameHeader }}
                        dataQa="name-header"
                    />

                    <Edit
                        htmlColor={Colors.Gray3}
                        className={classes.icon}
                        onClick={
                            permission === PermissionType.Visible
                                ? onEdit
                                : undefined
                        }
                        data-testid="extension-ring-group-edit"
                        data-qa="extension-ring-group-edit"
                    />
                </Grid>
                <div className={classes.strategyText} data-qa="strategy-text">
                    {callQueue && (
                        <CallQueue
                            className={classes.callQueueIcon}
                            data-testid="call-queue-icon"
                            data-qa="call-queue-icon"
                        />
                    )}
                    <span>
                        <span className={classes.strategyName}>
                            {t('screens:ringGroups.ringStrategy')}:{' '}
                        </span>
                        {t(`enums:ringStrategy.${strategy}`)}
                    </span>
                </div>
            </Grid>
        </CustomizedTooltip>
    );
};

export default RingGroupItem;
