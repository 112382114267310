import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from './../../history';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './../../store/actions';
import { ReduxState } from '../../store/types';
import { ApiLoginPayload } from '../../store/services/auth-api';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import { Routes } from '../../routes/routes';
import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import usePageTitle from '../../hooks/usePageTitle';

const SignIn = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePageTitle();

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [passedLogin, setPassedLogin] = useState('');
    const { errors, changePasswordActive, login, oldPassword } = useSelector(
        (state: ReduxState) => state.auth,
    );

    if (changePasswordActive && login && oldPassword) {
        history.push(Routes.PasswordExpiration);
    }

    const dispatchLoginAction = (payload: ApiLoginPayload) => {
        dispatch(
            actions.logIn.request({
                login: payload.login,
                password: payload.password,
            }),
        );
    };

    const handleOnCloseSnackbar = () => {
        dispatch(actions.loginClearErrors());
    };

    const handleOnClickOnLoginRecovery = (login?: string) => {
        dispatch(actions.setEnteredLogin({ login: login || '' }));
        history.push(Routes.PasswordRecovery);
    };

    useEffect(() => {
        login && setPassedLogin(login);
    }, [login]);

    return (
        <AuthWrapper
            title={t('screens:signIn.welcome')}
            message={snackbarMessage}
            onSnackbarClose={handleOnCloseSnackbar}
        >
            <LoginForm
                onSubmitAction={dispatchLoginAction}
                onRecoveryClick={handleOnClickOnLoginRecovery}
                validateOnChange={false}
                setSnackbarMessage={setSnackbarMessage}
                apiErrors={errors ? errors : undefined}
                passedLogin={passedLogin}
            />
        </AuthWrapper>
    );
};

export default SignIn;
