import { Colors } from '../../../styles/Colors';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        backgroundColor: Colors.SmokeBackground,
        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        paddingBottom: 40,
    },
    extensionHeader: {
        backgroundColor: Colors.White,
        display: 'flex',
        alignItems: 'center',
        color: Colors.Text,
        fontWeight: 300,
        fontSize: 32,
    },
    extensionTop: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },
}));
