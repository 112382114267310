import * as actions from '../../actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { UAInfo } from '../../types/Devices';
import { ExtensionsListItem } from '../extensions/extensions/reducer';

export interface DevicesStateType {
    items?: UAInfo[];
    total?: number;
    allTotal?: number;
    active?: number;
    isLoading?: boolean;
    isReleasingDevice?: boolean;
    extension?: ExtensionsListItem;
    isExtensionLoading?: boolean;
    isEditing?: boolean;
}

export type DevicesActionsType = ActionType<typeof actions>;

export const initialState: DevicesStateType = {};

const devicesReducer = createReducer<DevicesStateType, DevicesActionsType>(
    initialState,
)
    .handleAction(actions.devicesList.request, (state) => ({
        ...state,
        isLoading: true,
        isReleasingDevice: undefined,
    }))
    .handleAction(actions.devicesList.success, (state, action) => ({
        ...state,
        isLoading: false,
        ...action.payload,
        allTotal: state.allTotal || action.payload.total,
    }))
    .handleAction(actions.devicesList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.releaseDevice.request, (state) => ({
        ...state,
        isReleasingDevice: true,
    }))
    .handleAction(actions.releaseDevice.success, (state) => ({
        ...state,
        isReleasingDevice: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.releaseDevice.failure, (state) => ({
        ...state,
        isReleasingDevice: false,
    }))
    .handleAction(actions.getExtensionById.request, (state) => ({
        ...state,
        isExtensionLoading: true,
        extension: undefined,
    }))
    .handleAction(actions.getExtensionById.success, (state, action) => ({
        ...state,
        isExtensionLoading: false,
        extension: action.payload.extension,
    }))
    .handleAction(actions.getExtensionById.failure, (state) => ({
        ...state,
        isExtensionLoading: false,
        extension: undefined,
    }))
    .handleAction(actions.assignDevice.request, (state) => ({
        ...state,
        isEditing: true,
    }))
    .handleAction(actions.assignDevice.success, (state) => ({
        ...state,
        isEditing: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.assignDevice.failure, (state) => ({
        ...state,
        isEditing: false,
    }));

export default devicesReducer;
