import React, { useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-i18next';
import history from '../../history';
import { ReactComponent as Logout } from '../../assets/logout-right.svg';
import SidebarSection from './SidebarSection';
import {
    menuLocalStoreKey,
    useSidebarContext,
} from '../../hooks/useSidebarContext';
import { sections, sectionsIcons, useStyles } from './Sidebar.utils';
import LogoutMenuItem from '../LogoutMenuItem/LogoutMenuItem';
import Logo from '../Logo/Logo';
import Config from '../../config.json';
import CustomSidebarItem from './CustomSidebarItem';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { CustomerInfo, CustomerOfficeType } from '../../store/types/CustomerInfo';
import { actions } from '../../store';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { PermissionType, PortalPermission } from '../../store/types/Permission';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { groupsOpenStatuses, changeGroupStatus } = useSidebarContext();

    const classes = useStyles();
    const { t } = useTranslation();

    const [showShadow, setShowShadow] = useState(false);
    const scrollBoxRef = useRef<HTMLInputElement | null>(null);
    
    const i_office_type = useSelector<ReduxState, CustomerOfficeType | undefined>(
        (state) => state.generic?.customerOfficeType
    );
    const customer_info = useSelector<ReduxState, CustomerInfo | undefined>(
        (state) => state.generic?.globalCustomerInfo
    );
    const customerSubdivisionsList = useSelector<ReduxState, CustomerInfo[] | undefined>(
        (state) => state.generic?.customerSubdivisionsList
    );
    const permissions = useSelector<ReduxState, PortalPermission | undefined>(
        (state) => state.permissions?.permissions,
    );
    
    const isAllTheKeysHidden = useMemo(
        () => {
            if(!permissions) {
                return false;
            }
            for(const key of Object.keys(permissions.components)) {
                if(permissions.components[key]?.permission !== PermissionType.Hidden) {
                    return false;
                }
            }
            return true;
        },
        [permissions]
    );

    const customLinks = useMemo(() => {
        let links = Config.CUSTOM_LINKS.split(';');
        const customLinksArray: { label: string; url: string }[] = [];

        if (links.length % 2 === 1) {
            links = links.slice(0, links.length - 1);
        }

        for (let i = 0; i < links.length; i += 2) {
            customLinksArray.push({
                label: links[i],
                url: links[i + 1],
            });
        }

        return customLinksArray;
    }, []);
    
    useEffect(() => {
        if(i_office_type === CustomerOfficeType.Hq) {
            if(customerSubdivisionsList === undefined) {
                dispatch(actions.getCustomerSubdivisionsList.request());
            } else if(customerSubdivisionsList !== null && customerSubdivisionsList.length === 0) {
                dispatch(actions.officeTypeUpdated(CustomerOfficeType.NoOffices));
            }
        }
    }, [i_office_type, customerSubdivisionsList]);

    useEffect(() => {
        handleScroll();
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (scrollBoxRef.current) {
            const value =
                scrollBoxRef.current.clientHeight +
                    scrollBoxRef.current.scrollTop <
                scrollBoxRef.current.scrollHeight;

            if (value !== showShadow) {
                setShowShadow(value);
            }
        }
    };

    return (
    <div><div
            className={classes.sidebar}
            data-qa="sidebar-container"
            data-testid="sidebar-container"
        >
            <div className={classes.logoContainer}>
                <div className={classes.logo}>
                    <Logo toWhite />
                </div>
            </div>

            <List
                component="nav"
                data-qa="admin-dashboard"
                style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}
                onScroll={handleScroll}
                ref={scrollBoxRef}
            >
                {Object.keys(sections).map((v, index) => (
                    <PermissionProvider
                        key={index}
                        permission={sections[v].permission}
                    >
                        <SidebarSection
                            title={t(`sidebar.${v}`)}
                            items={sections[v].sections.map((w) => ({
                                title: t(`sidebar.${w.route}`),
                                route: w.route,
                                permission: w.permission,
                            }))}
                            isSidebarOpen
                            currentPath={history.location.pathname}
                            active={sections[v].sections
                                .map((v) => v.route)
                                .includes(history.location.pathname)}
                            sectionOpened={groupsOpenStatuses[index]}
                            toggleSection={() => changeGroupStatus?.(index)}
                            sectionIcon={sectionsIcons[v]}
                            index={index}
                        />
                    </PermissionProvider>
                ))}

                {!isAllTheKeysHidden && customLinks.map((v, index) => (
                    <CustomSidebarItem
                        key={`custom-item-${index}`}
                        title={v.label}
                        url={v.url}
                    />
                ))}
            </List>
            <LogoutMenuItem
                sectionIcon={<Logout />}
                onLogoutIconClick={() => {
                    localStorage.removeItem('session_id');
                    localStorage.removeItem('user_info');
                    localStorage.removeItem('user_email');
                    localStorage.removeItem('csrf_token');
                    localStorage.removeItem('default_portal_view_configuration');
                    localStorage.removeItem('user_login');
                    localStorage.removeItem(menuLocalStoreKey);
                    window.location.reload();
                }}
                showShadow={showShadow}
            />
        </div>
        
        <div className={classes.hqIndicatorContainer}>
        <CustomizedTooltip
            copy={false}
            title={
                <div className={classes.tooltipText}>
                    <div>{customer_info?.customer_info?.name || ''}</div>
                    <div>{customer_info?.customer_info?.companyname || ''}</div>
                </div>
            }
            data-qa={`hq-indicator-tooltip`}
            above={true}
        >
            <div className={classNames(classes.hqIndicator,
                                    i_office_type === CustomerOfficeType.Hq && customerSubdivisionsList !== undefined && classes.hq,
                                    i_office_type === CustomerOfficeType.Hq && customerSubdivisionsList === undefined && classes.hidden,
                                    i_office_type === CustomerOfficeType.Branch && classes.branch,
                                    (!i_office_type || i_office_type === CustomerOfficeType.NoOffices) && classes.hidden
                                    )} 
                                    data-testid={'hq-branch-indicator'}>
                {i_office_type === CustomerOfficeType.Hq ? t(`sidebar.hq`) : t(`sidebar.branch`)}
            </div>
        </CustomizedTooltip>
    </div></div>
    );
};

export default Sidebar;
