import { Fade } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import AnimatedNumberText from '../AnimatedNumber/AnimatedNumber';
import { DoughnutChartProps, useStyles } from './doughnutChartUtils';

const DoughnutChart: React.VFC<DoughnutChartProps> = ({ data, isLoading }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const dataset = useMemo(() => {
        return {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.color),
                    borderColor: data.map(() => Colors.White),
                },
            ],
        };
    }, [data]);

    const dataSum = dataset.datasets[0].data.reduce((v, w) => v + w, 0);
    return (
        <div className={classes.mainContainer}>
            <div className={classes.chartMainContainer}>
                <div className={classes.chartContainer}>
                    <div
                        className={classNames(
                            classes.chartPlaceholder,
                            isLoading && classes.chartLoadingBackground,
                            dataSum === 0 && classes.chartEmptyBackground,
                        )}
                    />
                    {!isLoading && (
                        <Doughnut
                            className={classes.chart}
                            data={dataset}
                            height={200}
                            width={200}
                            options={{
                                responsive: true,
                                //@ts-ignore
                                cutout: 35,
                                //@ts-ignore
                                borderWidth: 1,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: false,
                                    },
                                },
                            }}
                        />
                    )}

                    {!isLoading && (
                        <div className={classes.sumValue}>
                            <AnimatedNumberText
                                className={classes.sumText}
                                hideThousands
                                value={dataSum}
                                dataTestId="doughnut-chart-sum-value"
                                dataQa="doughnut-chart-sum-value"
                            />
                        </div>
                    )}

                    <Fade in={isLoading} timeout={1000} appear={isLoading}>
                        <div className={classes.loadingLabel}>
                            {t('common:loading')}
                        </div>
                    </Fade>
                </div>
            </div>
            <div className={classes.labelsContainer}>
                {data.map((v) => (
                    <div key={v.label} className={classes.labelItemContainer}>
                        <div
                            className={classes.dot}
                            style={{ backgroundColor: v.color }}
                        />
                        <div className={classes.labelItemTextContainer}>
                            <span className={classes.labelText}>{v.label}</span>
                            {!isLoading ? (
                                <AnimatedNumberText
                                    value={v.value}
                                    dataTestId="doughnut-chart-legend-value"
                                    dataQa="doughnut-chart-legend-value"
                                />
                            ) : (
                                <span>—</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DoughnutChart;
