import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import dayjs from '../../services/customDayJs';
import { IconButton, makeStyles } from '@material-ui/core';
import { actions } from '../../store';
import { ReactComponent as RefreshIcon } from '../../assets/refresh.svg';
import { Colors } from '../../styles/Colors';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
        marginTop: 10,
        color: Colors.Text,
    },
    button: {
        marginLeft: 10,
    },
    timeText: {
        fontStyle: 'italic',
        marginLeft: 10,
    },
}));

const ActiveCallsInfo: React.VFC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [timeAgo, setTimeAgo] = useState('');

    const refreshTime = useSelector<ReduxState, string>(
        (state) => state.calls.activeCalls.refreshTime,
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (refreshTime) {
                setTimeAgo(dayjs(refreshTime, dateFormat).fromNow());
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    const refreshActiveCalls = useCallback(() => {
        dispatch(actions.getActiveCalls.request());
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshActiveCalls();
        }, 60000 * 30);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    return (
        <div className={classes.container}>
            {timeAgo && (
                <>
                    {t('screens:calls.lastUpdated')}
                    {':'}
                    <span className={classes.timeText}>{timeAgo}</span>
                    <IconButton
                        size="small"
                        onClick={refreshActiveCalls}
                        className={classes.button}
                        data-testid="refresh-calls-button"
                        data-qa="refresh-calls-button"
                    >
                        <RefreshIcon />
                    </IconButton>
                </>
            )}
        </div>
    );
};

export default ActiveCallsInfo;
