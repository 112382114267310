import { ListItem, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';

export type CustomSidebarItemProps = {
    active?: boolean;
    title: string;
    url: string;
};

const useStyles = makeStyles(() => ({
    activeItem: {
        color: `${Colors.WhiteWithOpacity} !important`,
        backgroundColor: Colors.Secondary6,
        borderRadius: 4,
    },
    item: {
        cursor: 'pointer',
        width: 'unset',
        paddingLeft: 40,
        marginLeft: 15,
        marginRight: 15,
        '&:hover': {
            backgroundColor: Colors.Secondary6,
        },
    },
    separator: {
        border: `1px solid ${Colors.White}`,
        opacity: 0.3,
        marginLeft: 24,
        marginRight: 29,
        marginBottom: 10,
        marginTop: 10,
    },
}));

const CustomSidebarItem: React.FC<CustomSidebarItemProps> = ({
    active,
    title,
    url,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className={classes.separator} />
            <a href={url}>
                <ListItem
                    className={classNames(
                        active && classes.activeItem,
                        classes.item,
                    )}
                    onClick={() => window.location.replace(url)}
                >
                    {t(`enums:customSidebarLinks.${title}`,title)}
                </ListItem>
            </a>
        </>
    );
};

export default CustomSidebarItem;
