import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { ChangeDirtyStateDetailsTab } from '../../views/Extensions/ExtensionDetails/utils';

export const containerWidth = 978;
export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '22px 16px',
        width: containerWidth,
        display: 'flex',
        flexDirection: 'column',
    },

    releaseButton: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 110,
        '& .MuiButton-label': {
            fontSize: 16,
            paddingLeft: 0,
            paddingRight: 0,
            color: Colors.Gray5 + '!important',
        },

        '& svg': {
            '& path': {
                fill: Colors.Gray5,
                fillOpacity: 1,
            },
        },
    },

    changeButton: {
        width: 109,
        height: 40,
        marginLeft: 30,
    },

    assignDeviceButtonContainer: {
        '& > :first-child': {
            width: 'fit-content'
        },
    },

    assignButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
        },
        margin: '16px 0 31px 8px',
        width: 'auto',
        height: 40,
        paddingLeft: 13,
    },

    actionButtons: {
        marginTop: 39,
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 8,
    },
    sipInfo: {
        width: containerWidth,
    },
    titleContainer: {
        '& h5': {
            marginLeft: 3,
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 17,
            marginTop: 0,
        },
    },
    releaseContainer: {
        '& .MuiDialogContent-root': {
            width: 233,
            minWidth: 'unset',
            padding: 24,
            paddingBottom: 27,
            background: Colors.White,
        },
    },

    marginTop: {
        marginTop: 7,
    },
    field: {
        '& .MuiInputBase-root ': {
            maxWidth: 440,
        },
    },
    container: {
        '& .MuiGrid-root': {
            maxWidth: 440,
            margin: '16px',
            padding: 0,
        },
    },
}));

export interface DeviceProps {
    iAccount: number;
    register: boolean;
    i_c_ext: number;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    extensionNumber?: string;
    assignPermission?: string[];
    releasePermission?: string[];
    changePermission?: string[];
}
