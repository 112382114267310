import { OperationMode } from '../../store/actions/extensions/payloads';
import i18n from '../../services/i18n';

export type TransformBitMask = {
    bits: string;
    translation: string;
    index: number[];
    modes: string[];
};

export enum BitMask {
    Any = '1111111111',
}

export enum CallScreeningMode {
    Deafault = 'Normal (Default)',
}

export function translateBitMak(mask: number, operationModes: OperationMode[]) {
    const output: TransformBitMask = {
        bits: (mask >>> 0).toString(2),
        translation: '',
        index: [],
        modes: [],
    };

    if (output.bits == BitMask.Any) {
        return {
            bits: (mask >>> 0).toString(2),
            translation: i18n.t<string>('screens:extensions.any'),
            index: [],
            modes: [],
        };
    }

    const bitStringLength = output.bits.length;

    for (let i = 0; i < bitStringLength; i++) {
        if (output.bits[i] === '1') {
            output.index.push(bitStringLength - 1 - i);
        }
    }

    output.index = output.index.sort(function (a, b) {
        return a - b;
    });

    output.modes = output.index.map(
        (shortCode) =>
            operationModes.find((mode) => mode.short_code == shortCode)?.name ||
            shortCode.toString(),
    );
    output.modes = output.modes.map((item) =>
        item == CallScreeningMode.Deafault
            ? i18n.t<string>('enums:callScreeningMode.Default')
            : item,
    );
    output.translation = output.modes.join(',').replace(/,/g, ', ');

    return output;
}
