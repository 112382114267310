import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100vw - 420px)',
        minWidth: '1071',
        '& label': {
            marginRight: 16,
            fontSize: 32,
            color: Colors.Text,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },

    displayInline: {
        display:"inline-flex"
    },

    button: {
        marginTop: -14,
        paddingRight: 17,
        marginLeft: 20,
    },
    addIcon: {
        height: 22,
        width: 22,
        marginRight: 6,
        marginLeft: -3,
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    outsideBalanceContainer: {
        display: 'flex',
        marginTop: 8,
    },
    statusBox: {
        border: `1px solid ${Colors.Primary2}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.Primary2,
        textTransform: 'uppercase',
        fontSize: 12,
        lineHeight: '20px',
        borderRadius: 5,
        minWidth: 230,
    },
    warningStatusBox: {
        border: `1px solid ${Colors.Error}`,
        color: Colors.Error,
    },
    balanceContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingRight: 33,
        minWidth: 80,
    },
    secondBalance: {
        paddingRight: 0,
    },
    semiHeader: {
        fontWeight: 400,
        color: Colors.Gray9,
    },
    boldedValue: {
        fontWeight: 'bold',
    },
    value: {
        color: Colors.Text,
        lineHeight: '20px',
        whiteSpace: 'nowrap',
    },
}));
