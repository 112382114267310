import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { TFunction } from 'react-i18next';
import { PhoneBookListItemExtended } from './EntryModal';
import { Column } from 'react-table';
import EmptyRowContent from '../../components/DataTable/EmptyRowContent';
import { Delete, Edit, Add } from '@material-ui/icons';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import DataItemsActions from '../../components/DataTable/DataItemActions';
import { Permission } from '../../store/types/Permission';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import TextButton from '../../components/TextButton/TextButton';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: Colors.SmokeBackground,

        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '50px 90px 120px 90px',
    },

    outsideContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },

    header: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 40,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
            marginBottom: 0,
        },
        '& .MuiButtonBase-root': {
            marginTop: '24px',
            marginBottom: '48px',
            width: '135px',
        },
    },
    row: {
        borderBottom: 0,
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    wait: {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        zIndex: 1301,
    },
    table: {
        marginTop: 30,
    },
    speedDialContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    speedDial: {
        color: Colors.Secondary1,
        cursor: 'pointer',
        fontWeight: 400,
        textAlign: 'center',
        alignSelf: 'center',
    },
    tableContainer: {
        maxWidth: 1040,
        '& .MuiTableRow-head': {
            '& :nth-child(1)': {
                textAlign: 'center',
            },
            '& :nth-child(4)': {
                textAlign: 'center',
            },
        },
    },
    tableContainerWithMultiSelect: {
        maxWidth: 1040,
        '& .MuiTableRow-head': {
            '& :nth-child(2)': {
                textAlign: 'center',
            },
            '& :nth-child(5)': {
                textAlign: 'center',
            },
        },
    },
    actions: {
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
    },
    actionsWrapper: {
        justifyContent: 'center',
    },
    containerDialog: {
        '& .MuiPaper-root': {
            width: 336,
            '& .MuiDialogContent-root': {
                minWidth: 'unset',
                background: Colors.White,
            },
        },
    },
    buttonsSeparation: {
        marginLeft: 30,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    setAddEntryModalVisible: (item: PhoneBookListItemExtended) => void,
    removeEntry: (itemToDelete: PhoneBookListItemExtended) => void,
    classes: ReturnType<typeof useStyles>,
): Column<PhoneBookListItemExtended>[] => {
    return [
        {
            accessor: 'id',
            Header: t<string>('screens:phoneBook.speedDial'),
            width: 0.75,
            Cell: function Cell(params) {
                return (
                    <div className={classes.speedDialContainer}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.PhoneBook
                                    .PhoneBookEntryDetails.value
                            }
                        >
                            <TextButton
                                onClick={() =>
                                    setAddEntryModalVisible(
                                        params.row
                                            .original as PhoneBookListItemExtended,
                                    )
                                }
                                dataQa={`ring-group-number-cell-${
                                    params.row.original.id ?? params.row.index
                                }`}
                                className={classes.speedDial}
                            >
                                {params.row.original.abbreviated_number}
                            </TextButton>
                        </PermissionProvider>
                    </div>
                );
            },
        },
        {
            accessor: 'number_to_dial',
            width: 0.75,
            Header: t<string>('common:number'),
            Cell: function Number(params) {
                const number = params.row.original.number_to_dial;
                if (number) {
                    return (
                        <OverflowTooltip
                            tooltip={number}
                            text={<span>{number}</span>}
                            copy={true}
                        />
                    );
                }
                return <EmptyRowContent />;
            },
        },
        {
            accessor: 'description',
            width: 1.25,
            Header: t<string>('common:description'),
            Cell: function Description(params) {
                const description = params.row.original.description;
                if (description) {
                    return (
                        <OverflowTooltip
                            tooltip={description}
                            text={<span>{description}</span>}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent />;
            },
        },
        {
            accessor: 'actions',
            width: 0.9,
            Header: t<string>('common:actions'),
            Cell: function ActionButtons(params) {
                return (
                    <div className={classes.actions}>
                        <div className={classes.actionsWrapper}>
                            <DataItemsActions
                                onEdit={() =>
                                    setAddEntryModalVisible(
                                        params.row
                                            .original as PhoneBookListItemExtended,
                                    )
                                }
                                onDelete={() =>
                                    removeEntry(
                                        params.row
                                            .original as PhoneBookListItemExtended,
                                    )
                                }
                                classes={{
                                    deleteButton: classes.buttonsSeparation,
                                }}
                                editPermission={
                                    Permission.CloudPBX.PhoneBook
                                        .PhoneBookEntryDetails.value
                                }
                                deletePermission={
                                    Permission.CloudPBX.PhoneBook
                                        .DeletePhoneBookEntry.value
                                }
                                skipEditPermissionReadOnly={false}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];
};

export const PlusIcon = (
    <Add
        htmlColor={Colors.White}
        style={{ height: 14, width: 14, marginRight: 10 }}
    />
);
