import { createAsyncAction } from 'typesafe-actions';
import { DialingRuleInfo } from '../../types/DialingRule';
import { ServiceFeature } from '../../types/ServiceFeature';
import * as payloads from './payloads';

export const getCallSettingsDetails = createAsyncAction(
    'GET_CALL_SETTINGS_DETAILS',
    'GET_CALL_SETTINGS_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_DETAILS_FAILED',
)<undefined, undefined, undefined>();

export const editCallSettings = createAsyncAction(
    'EDIT_CALL_SETTINGS_DETAILS',
    'EDIT_CALL_SETTINGS_DETAILS_SUCCESS',
    'EDIT_CALL_SETTINGS_DETAILS_FAILED',
)<payloads.SetCallSettings, undefined, undefined>();

export const getCallSettingsMohDetails = createAsyncAction(
    'GET_CALL_SETTINGS_MOG_DETAILS',
    'GET_CALL_SETTINGS_MOG_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MOG_DETAILS_FAILED',
)<undefined, payloads.CallSettingsMohDetailsResponsePayload, Error>();

export const getMusicRingDetails = createAsyncAction(
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_FAILED',
)<undefined, payloads.MusicRingingResponsePayload, Error>();

export const uploadMusicFile = createAsyncAction(
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_FAILED',
)<
    payloads.UploadMusicFileRequest,
    payloads.MusicRingingResponsePayload,
    Error
>();

export const getDialingRules = createAsyncAction(
    'GET_DIALING_RULES',
    'GET_DIALING_RULES_SUCCESS',
    'GET_DIALING_RULES_FAILED',
)<undefined, DialingRuleInfo[], undefined>();

export const getCustomerServiceFeatures = createAsyncAction(
    'GET_CUSTOMER_SERVICE_FEATURES',
    'GET_CUSTOMER_SERVICE_FEATURES_SUCCESS',
    'GET_CUSTOMER_SERVICE_FEATURES_FAILED',
)<undefined, ServiceFeature[], undefined>();

export const getCustomerInfoCallBarring = createAsyncAction(
    'GET_CUSTOMER_INFO_CALL_BARRING',
    'GET_CUSTOMER_INFO_CALL_BARRING_SUCCESS',
    'GET_CUSTOMER_INFO_CALL_BARRING_FAILED',
)<undefined, payloads.CustomerInfoResponsePayload, undefined>();

export const getCallBarringOptions = createAsyncAction(
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS',
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS_SUCCESS',
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS_FAILED',
)<undefined, payloads.CallBarringOptionsResponsePayload, undefined>();
