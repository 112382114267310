import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { Colors } from '../../styles/Colors';
import CustomizedTooltip from '../Tooltip/Tooltip';

type TextButtonProps = {
    onClick?: () => void;
    dataQa?: string;
    className?: string;
    role?: string;
};

const useStyles = makeStyles(() => ({
    idText: {
        color: Colors.Secondary1,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    disabled: {
        color: Colors.Secondary1,
        pointerEvents: 'none',
    },
    readOnly: {
        opacity: 0.5,
    },
}));

const TextButton: React.FC<TextButtonProps> = ({
    onClick,
    dataQa,
    children,
    className,
    role,
}) => {
    const classes = useStyles();
    const permission = usePermissionContext();
    const { t } = useTranslation();

    return (
        <CustomizedTooltip
            title={t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission !== PermissionType.Hidden}
        >
            <span
                onClick={
                    permission === PermissionType.Hidden ? undefined : onClick
                }
                className={classNames(
                    classes.idText,
                    permission === PermissionType.Hidden && classes.disabled,
                    permission === PermissionType.ReadOnly && classes.readOnly,
                    className,
                )}
                data-qa={dataQa}
                role={role}
            >
                {children}
            </span>
        </CustomizedTooltip>
    );
};

export default TextButton;
