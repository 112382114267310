import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Snackbar from '../Snackbar/Snackbar';
import SignInHeaderComponent from './SignInHeaderComponent';
import { useStyles } from './AuthWraper.utils';
import { useTranslation } from 'react-i18next';
import * as actions from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import camelCase from 'camelcase';

interface AuthWrapper {
    title: string;
    message?: string;
    onSnackbarClose?: () => void;
}

const AuthWrapper: React.FC<AuthWrapper> = ({
    title,
    message,
    onSnackbarClose,
    children,
}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [showSnackbar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { errors, msg } = useSelector((state: ReduxState) => state.auth);

    useEffect(() => {
        if (msg) {
            setShowSnackBar(true);
            setSnackbarMessage(t('screens:signIn.' + msg));
        } else if (message) {
            setShowSnackBar(true);
            setSnackbarMessage(message);
        } else if (errors) {
            setShowSnackBar(true);
            const camelFoultCode = camelCase(errors.faultcode);
            if (i18n.exists('errors:loginForm.' + camelFoultCode)) {
                setSnackbarMessage(t('errors:loginForm.' + camelFoultCode));
            } else {
                setSnackbarMessage(errors.faultstring);
            }
        }
    }, [errors, msg, message]);

    const handleOnCloseSnackbar = () => {
        setShowSnackBar(false);
        onSnackbarClose?.();
        dispatch(actions.loginClearErrors());
    };

    return (
        <Grid container className={classes.root}>
            <Snackbar
                isOpen={showSnackbar}
                snackbarMessage={snackbarMessage}
                onButtonClick={handleOnCloseSnackbar}
                handleClose={handleOnCloseSnackbar}
                actionLabel={t('common:closeSnackbar')}
                dataQa="login-errors-snackbar"
            />
            <div className={classes.wrapper}>
                <SignInHeaderComponent welcomeText={title} />
                <Grid item className={classes.credentials}>
                    {children}
                </Grid>
            </div>
        </Grid>
    );
};

export default AuthWrapper;
