import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import {
    CPConditionInfo,
    CPConditionInfoType,
    CPRuleInfo,
    YesNo,
} from '../../../types/CallScreening';
import { GetCallProcessingPolicyListEntity } from '../../../actions/extensions/payloads';

export type CallScreeningConditions = {
    items?: CPConditionInfo[];
    total?: number;
    filters?: {};
    FromNumber?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
    ToNumber?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
    TimeWindow?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
};

export type CallScreeningRules = {
    items?: CPRuleInfo[];
    total?: number;
    limit?: number;
    offset?: number;
};

export interface CallScreeningReducerState {
    callScreeningConditions?: CallScreeningConditions;
    callScreeningRules?: CallScreeningRules;
    callProcessingPolicyList?: GetCallProcessingPolicyListEntity[];
}

export const initialState: CallScreeningReducerState = {};

export type CallScreeningReducerActions = ActionType<typeof actions>;

export const callScreeningReducer = createReducer<
    CallScreeningReducerState,
    CallScreeningReducerActions
>(initialState)
    .handleAction(actions.getCallScreeningRules.request, (state, action) => {
        return {
            ...state,
            callScreeningRules: {
                ...state.callScreeningRules,
                limit: action.payload.limit,
                offset: action.payload.offset,
            },
        };
    })
    .handleAction(
        actions.getCallScreeningConditions.request,
        (state, action) => {
            const { offset, limit, name, type } = action.payload;

            const condType: CPConditionInfoType | string = action.payload?.type
                ? action.payload?.type
                : 'default';

            switch (condType) {
                case CPConditionInfoType.From:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            FromNumber: {
                                ...state.callScreeningConditions?.FromNumber,
                                items:
                                    state.callScreeningConditions?.FromNumber
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                    };
                case CPConditionInfoType.To:
                    return {
                        ...state,

                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            ToNumber: {
                                ...state.callScreeningConditions?.ToNumber,
                                items:
                                    state.callScreeningConditions?.ToNumber
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                    };
                case CPConditionInfoType.TimeWindow:
                    return {
                        ...state,

                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            TimeWindow: {
                                ...state.callScreeningConditions?.TimeWindow,
                                items:
                                    state.callScreeningConditions?.TimeWindow
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                    };
                default:
                    return {
                        ...state,
                        filters: { offset, limit, name, type },
                    };
            }
        },
    )
    .handleAction(actions.getCallScreeningRules.success, (state, action) => {
        return {
            ...state,
            callScreeningRules: {
                ...state.callScreeningRules,
                items: action.payload.cp_rule_list,
                total: action.payload.total,
            },
        };
    })
    .handleAction(
        actions.getCallScreeningConditions.success,
        (state, action) => {
            const condType: CPConditionInfoType | string = action.payload?.type
                ? action.payload?.type
                : 'default';

            switch (condType) {
                case CPConditionInfoType.From:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            FromNumber: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                    };
                case CPConditionInfoType.To:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            ToNumber: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                    };
                case CPConditionInfoType.TimeWindow:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            TimeWindow: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                    };
                default:
                    const from =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.From,
                        ) || [];

                    const to =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.To,
                        ) || [];

                    const time =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.TimeWindow,
                        ) || [];
                    return {
                        ...state,

                        callScreeningConditions: {
                            items: action.payload.cp_condition_list,
                            total: action.payload.total,
                            FromNumber: {
                                items: [...from],
                                total: from.length,
                            },
                            ToNumber: {
                                items: [...to],
                                total: to.length,
                            },
                            TimeWindow: {
                                items: [...time],
                                total: time.length,
                            },
                        },
                    };
            }
        },
    )
    .handleAction(actions.getCpRuleList.success, (state, action) => ({
        ...state,
        callScreeningRules: { items: action.payload },
    }))
    .handleAction(
        actions.getCallProcessingPolicyList.success,
        (state, action) => ({
            ...state,
            callProcessingPolicyList: action.payload,
        }),
    );

export default callScreeningReducer;
