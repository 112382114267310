import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import CustomizedSwitch from '../Switch/Switch';
import { MenuAction } from '../../store/types/AutoAttendant';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import TextField from '../TextField/TextField';
import React from 'react';
import { useStyles } from './utils';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { SettingsFormType } from '../Forms/AutoAttendantsMenus/SettingsForm.utils';
import { ActionsFormType } from '../Forms/AutoAttendantsMenus/ActionsForm.utils';

const AllowCellars = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { values, setFieldValue, handleChange } = useFormikContext<
        SettingsFormType & ActionsFormType
    >();

    return (
        <>
            <Grid
                className={classNames(
                    classes.centeredGrid,
                    classes.switchContainer,
                )}
            >
                <CustomizedSwitch
                    checked={values.allowCallersToDialKnownNumber}
                    dataQa="allow-callers-to-dial-known-number-action-status"
                    dataTestId="allow-callers-to-dial-known-number-action-status"
                    onChange={(value) =>
                        setFieldValue(
                            'allowCallersToDialKnownNumber',
                            value.target.checked,
                        )
                    }
                    disabled={
                        !!values.actions.find(
                            (v) =>
                                v.action === MenuAction.DialExtensionDirectly,
                        )
                    }
                />
                <span className={classes.activeHeader}>
                    {t(
                        'screens:autoAttendants.allowCallersToDialKnownExtensionDirectly',
                    )}
                </span>

                <IconWithTooltip
                    dataQa="play-before-action-tooltip"
                    tooltipText={t(
                        'tooltips:autoAttendants.allowCallersToDialKnownExtensionDirectly',
                    )}
                />
            </Grid>

            {values.allowCallersToDialKnownNumber && (
                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.numberPickerGrid,
                    )}
                >
                    <TextField
                        id="callersToDialKnownNumberTimeout"
                        label={t('screens:extensions.timeout')}
                        onChange={handleChange}
                        value={values.callersToDialKnownNumberTimeout.toString()}
                        type="number"
                        icon={
                            <span className={classes.numberLabel}>
                                {t('screens:extensions.sec')}
                            </span>
                        }
                        iconPosition="end"
                        className={classes.numberInput}
                        dataQa="incomingform-answering-timeout-select"
                        inputProps={{
                            inputProps: {
                                min: 1,
                                max: 999,
                            },
                            pattern: '[0-9]*',
                        }}
                        widthFromLiableText
                    />

                    <IconWithTooltip
                        dataQa="play-before-action-tooltip"
                        tooltipText={t(
                            'tooltips:autoAttendants.allowCallersToDialKnownExtensionDirectly',
                        )}
                        type="small"
                    />
                </Grid>
            )}
        </>
    );
};

export default AllowCellars;
