import DataGrid from '../../../components/DataGrid/DataGrid';
import { TransactionTableRow } from "./TransactionList.utils";
import React, { useMemo, useState } from 'react';
import { Column } from "react-table";
import { Fade, makeStyles } from '@material-ui/core';
import { Colors, getServiceColor } from '../../../styles/Colors';
import classNames from 'classnames';
import { Doughnut, Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import AnimatedNumber from 'animated-number-react';
import type * as AllChart from 'chart.js';

type TransactionChartProps = {
    columns: Column<TransactionTableRow>[];
    inputdata: TransactionTableRow[];
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
    currency: string;
    dataQa: string;
}

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(1)': {
            display: ' flex',
            justifyContent: 'start',
        },
        '& th:nth-child(2)': {
            display: ' flex',
            justifyContent: 'end',
            width: 55
        },
        '& th:nth-child(3)': {
            display: ' flex',
            justifyContent: 'end',
            width: '138px',
            paddingLeft: '10px',
            paddingRight: 0,
            flex: 'unset',
            boxSizing: 'unset'
        },
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'center',
            paddingRight: '11px !important',
            flex: '15 0 auto',
            padding: 0
        },
        '& tr:last-child': {
            borderBottom: '1px solid ' + Colors.Border
        },
        '& .MuiTableCell-head': {
            paddingRight: 0
        }
    },

    tableContent: {
        marginLeft: 32,
        width: 'calc(100vw - 732px)',
        minWidth: 750,
        overflowX: 'hidden',
        border: 'none',
        '& td': {
            alignItems: 'center !important',
        },

        '& td:nth-child(1)': {
            display: 'flex',
            justifyContent: 'start',
        },
        '& td:nth-child(2)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(3)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(4)': {
            display: 'flex',
            justifyContent: 'center'
        },
        '& .MuiTableCell-body': {
            padding: '1px'
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        },
        '& tr:last-child': {
            borderBottom: 'none'
        }
    },

    tabContainer: {
        height: 'fit-content',
        paddingTop: '17px',
        paddingLeft: '40px',
        paddingBottom: '44px',
        paddingRight: '47px',
        background: Colors.Background,
        display: 'inline-flex',
        minHeight: 'calc(283px - 17px - 44px)',
        minWidth: 992
    },
    chartContainerInternal: {
        width: 200,
        position: 'relative',
        marginTop: 22,
        height: 200,
    },
    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 160,
        borderRadius: '50%',
        border: `transparent solid 20px`
    },
    chartEmptyBackground: {
        borderColor: '#E4EBF3',
    },
    chartLoadingBackground: {
        borderColor: 'transparent',
    },
    sumValue: {
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        top: 90,
    },
    sumText: {
        width: 'fit-content',
        height: 'fit-content',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        color: '#121212'
    },
    sumHeader: {
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        top: 66,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    loadingLabel: {
        backgroundColor: 'transparent',
        width: 200,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 90,
        left: 0,
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
}));

const formatTotalSum = (v: number) => {
    return v.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
}

const TransactionChart: React.VFC<TransactionChartProps> = ({
    columns,
    inputdata,
    isLoading,
    currency,
    dataQa
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [lastMousePoint, setLastMousePoint] = useState({x: -1, y: -1});

    const total: number = inputdata.filter(e => !e.isSummary).reduce((a, b) => a + (b.total || 0), 0);
    const dataSum: number = inputdata.filter(e => !e.isSummary).reduce((a, b) => a + (Math.abs(b.amount) || 0), 0);

    const dataset = useMemo(() => {
        return {
            labels: inputdata.filter(e => !e.isSummary).map((v) => v.type),
            datasets: [
                {
                    data: inputdata.filter(e => !e.isSummary).map((e) => Math.abs(e.amount)),
                    backgroundColor: inputdata.filter(e => !e.isSummary).map((e) => getServiceColor(+(e.iService || 0))),
                    borderColor: inputdata.filter(e => !e.isSummary).map(() => Colors.White),
                },
            ]
        } as AllChart.ChartData;
    }, [inputdata]);

    const capitalizeFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onMouseMoveOverCanvas = (e: any) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setLastMousePoint({x:x, y:y});
    }

    const tooltipPlugin = Chart?.registry?.getPlugin('tooltip');

    if (tooltipPlugin) {
        //@ts-ignore
        tooltipPlugin.positioners.middle = (elements) => {
            const model = elements[0]?.element;
            return {
                x: model?.x + (model?.width || 0) / 2 - 30,
                y: model?.y + (model?.height || 0) / 2 - 13,
            };
        };
    }
    
    const getOrCreateTooltip = (chart: any) => {
        const toolTipId = 'chart-tooltip-id';
        let tooltipEl = chart.canvas.parentNode.querySelector('#' + toolTipId);
    
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = toolTipId;
            tooltipEl.style.background = Colors.Text;
            tooltipEl.style.borderRadius = '4px';
            tooltipEl.style.padding = '4px';
    
            tooltipEl.style.color = Colors.White;
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transition = 'all .1s ease';
    
            const table = document.createElement('table');
            table.style.margin = '0px';
    
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
    
        return tooltipEl;
    };
    
    const externalTooltipHandler = (context: any, chartData: AllChart.ChartData, currency: string) => {
        const { chart, tooltip } = context;
        
        const tooltipEl = getOrCreateTooltip(chart);
    
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
    
        if (tooltip.body) {
            const element = document.createElement('div');
    
            const dataIndex = tooltip.dataPoints[0].dataIndex;
            const objes = (chartData.datasets[0]?.data as number[]) || [];
            const sum = objes.reduce((a:number, b: number) => a + (b || 0), 0);
    
            if(sum > 0)
            {
                const value = objes[dataIndex] || 0;
                const label = ((chartData.labels || [])[dataIndex] || '') + '';
                const percent = (parseFloat(value+'') * parseFloat('100.0')) / parseFloat(sum+'');
                const subTitleText = formatTotalSum(sum) + ' ' + currency + ' (' + formatTotalSum(percent) + '%)';
        
                if (label.length > 1) {
                    const titleLabel = document.createElement('div');
                    titleLabel.style.whiteSpace = 'nowrap';
                    titleLabel.appendChild(document.createTextNode(label));
                    element.appendChild(titleLabel);
                    
                    const subTitle = document.createElement('div');
                    subTitle.style.whiteSpace = 'nowrap';
                    subTitle.appendChild(document.createTextNode(subTitleText));
                    element.appendChild(subTitle);
                }
            }
    
            while (tooltipEl?.firstChild) {
                tooltipEl.firstChild.remove();
            }
    
            tooltipEl.appendChild(element);
        }
    
        tooltipEl.style.opacity = 1;
        if(lastMousePoint && lastMousePoint.x >= 0 && lastMousePoint.y >= 0) {
            tooltipEl.style.left = lastMousePoint.x + 'px';
            tooltipEl.style.top = lastMousePoint.y + 'px';
        }
        tooltipEl.style.fontSize = '12px';
        tooltipEl.style.lineHeight = '18px';
        tooltipEl.style.padding = '4px 8px';
        tooltipEl.style.zIndex = '5';
        tooltipEl.style.height = '36px';
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.fontFamily = 'Roboto';
        tooltipEl.style.fontStyle = 'normal';
        tooltipEl.style.fontWeight = 400;
        tooltipEl.style.color = '#F5F7F8';
    };

    return (
        <div className={classes.tabContainer}>
            <div className={classes.chartContainerInternal} onMouseOver={(e) => onMouseMoveOverCanvas(e)}
            onMouseEnter={(e) => onMouseMoveOverCanvas(e)}
            onMouseMove={(e) => onMouseMoveOverCanvas(e)}
            >
                <div className={classNames(
                        classes.chartPlaceholder,
                        isLoading && classes.chartLoadingBackground,
                        dataSum === 0 && classes.chartEmptyBackground,
                    )}/>
                {!isLoading && (
                    <div>
                        <Doughnut
                            className={classes.chart}
                            data={dataset}
                            height={200}
                            width={200}
                            options={{
                                responsive: true,
                                //@ts-ignore
                                cutout: 76,
                                //@ts-ignore
                                borderWidth: 1,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: false,
                                        //@ts-ignore
                                        position: 'middle',
                                        external: (context) =>
                                            externalTooltipHandler(context, dataset, currency)
                                    },
                                },
                            }}
                        />
                        <div className={classes.sumHeader}>
                            {capitalizeFirstLetter(t('common:total'))}, {currency}
                        </div>
                        <div className={classes.sumValue}>
                            <AnimatedNumber
                                className={classes.sumText}
                                value={dataSum}
                                formatValue={formatTotalSum}
                            />
                        </div>
                    </div>
                )}
                <Fade in={isLoading} timeout={1000} appear={isLoading}>
                    <div className={classes.loadingLabel}>
                        {t('common:loading')}
                    </div>
                </Fade>
            </div>            
            <DataGrid<TransactionTableRow>
                dataQa={dataQa}
                columns={columns}
                data={inputdata}
                rowCount={total}
                hideFooter
                classes={{
                    header: classes.header,
                    tableContainer: classes.tableContent
                }}
                loading={isLoading}
            />
        </div>
    );
}

export default TransactionChart;