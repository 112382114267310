import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0',
        maxWidth: 965,
        display: 'flex',
        flexDirection: 'row',
    },

    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 106,
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.Gray5,
        fontSize: 16,
        '& ul': {
            padding: '0 0 0 24px',
            margin: 0,
            lineHeight: '30px',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        padding: '22px 16px 2px 16px',
        '& .MuiFormControl-root': {
            marginBottom: 32,
        },

        '& .MuiInputBase-root': {
            width: 440,
            marginTop: 0,
        },
    },
    header: {
        fontWeight: 500,
        fontSize: 32,
        marginTop: 58,
        color: Colors.Gray7,
    },
    resetButton: {
        '& .MuiButton-label': {
            color: `${Colors.Primary} !important`,
        },
        marginLeft: 4,
    },
}));
