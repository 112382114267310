import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import { EditAutoAttendantForm } from './AutoAttendantsDetails';
import i18n from 'i18next';

export interface AutoAttendantDetailsPayload {
    init: Partial<EditAutoAttendantForm>;
    updated: Partial<EditAutoAttendantForm>;
}

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        position: 'relative',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },

        '& #wrapped-tabpanel-0': {
            '& .MuiBox-root': {
                height: 'calc(100vh - 290px)',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            },
        },
    },

    tabs: {
        '& header': {
            paddingLeft: 110,
        },
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SmokeBackground,
    },
    extensionHeader: {
        padding: '40px 90px 24px 90px',
        backgroundColor: Colors.Gray2,
        display: 'flex',
        alignItems: 'center',
    },
    extensionTop: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '30px 90px 30px 90px',
        borderTop: `1px rgba(0, 0, 0, 0.12) solid`,
        backgroundColor: Colors.White,
        margin: 0,
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },

    deleteDialogButtons: {
        '& button': {
            minWidth: 90,
        },

        '& .MuiDialogContent-root': {
            paddingTop: 24,
            paddingBottom: 24,

            '& p': {
                marginBottom: 0,
            },
        },
    },
}));
