import React from 'react';
import { makeStyles } from '@material-ui/core';
import { usePermissions } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoPermission } from '../../assets/no-permission.svg';
import CustomizedButton from '../../components/Button/Button';
import { Link, useHistory } from 'react-router-dom';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SmokeBackground,
    },
    title: {
        fontSize: 32,
        fontWeight: 500,
        color: Colors.Text,
    },
    description: {
        fontWeight: 400,
        color: Colors.Gray5,
    },
    button: {
        marginTop: 30,
        marginBottom: 60,
        width: 210,
        '& .MuiButton-label': {
            color: Colors.White,
            padding: '0 25px',
        },
    },
    link: {
        textDecoration: 'none !important',
    },
}));

type PermissionPlaceholderProps = {
    permission: string[];
    skipReadOnly?: boolean;
};

const PermissionPlaceholder: React.FC<PermissionPlaceholderProps> = ({
    permission,
    children,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const permissionValue = usePermissions(...permission);

    if (permissionValue === PermissionType.Hidden) {
        return (
            <div className={classes.root}>
                <NoPermission />
                <h2 className={classes.title}>{t('common:accessDenied')}</h2>
                <span className={classes.description}>
                    {t('common:noPermissionToPage')}
                </span>

                <CustomizedButton
                    className={classes.button}
                    primary
                    onClick={history.goBack}
                >
                    {t('common:goBack')}
                </CustomizedButton>
            </div>
        );
    }

    return <>{children}</>;
};

export default PermissionPlaceholder;
