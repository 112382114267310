import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Account } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import { api } from '../../../services/axios';
import { AccountPermittedProxyInfo } from '../../../types/SipProxies';

export function* getPermittedSipProxies(
    action: ActionType<typeof actions.getPermittedSipProxies.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_account: action.payload.i_account,
        detailed_info: '1',
        with_period_definition: 1,
    });

    const sipInfoResponse: AxiosResponse<AccountPermittedProxyInfo> = yield api.post(
        Account.GetPermittedSipProxiesInfo,
        body,
    );

    yield put(
        actions.getPermittedSipProxies.success(
            sipInfoResponse.data.permitted_sip_proxies,
        ),
    );
}
