import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions';
import { APIErrorInterface } from '../../types';
import { ApiFile, SwitchModeApiFile } from '../../types/ApiFile';
import { RingGroupType } from '../../types/RingGroup';
import { ExtensionsListItem } from '../extensions/extensions/reducer';

export interface RingGroupsStateType {
    ringGroupsList: {
        total: number;
        items: RingGroupType[];
        filters: {
            name?: string;
            extension?: string | number;
        };
    };
    huntGroupList?: RingGroupType[];
    responseMessageList?: SwitchModeApiFile[];
    isLoading: boolean;
    ringbackTonesList?: ApiFile[];
    onHoldList?: ApiFile[];
    isFormLoading?: boolean;
    groupMembersDetails?: {
        ringGroups: RingGroupType[];
        extensions: ExtensionsListItem[];
    };
    errors?: APIErrorInterface;
    ringGroupDetails?: RingGroupType;
    isFormSending?: boolean;
}

export const initialState: RingGroupsStateType = {
    ringGroupsList: {
        total: 0,
        items: [],
        filters: {},
    },
    isLoading: false,
    ringbackTonesList: [],
    isFormLoading: false,
    groupMembersDetails: {
        ringGroups: [],
        extensions: [],
    },
};

export type RingGroupsActionsType = ActionType<typeof actions>;

const ringGroupsReducer = createReducer<
    RingGroupsStateType,
    RingGroupsActionsType
>(initialState)
    .handleAction(actions.ringGroupsList.request, (state, action) => ({
        ...state,
        ringGroupsList: {
            ...state.ringGroupsList,
            filters: {
                name: action.payload.nameFilter,
                extension: action.payload.extensionFilter,
            },
        },
        isLoading: true,
    }))
    .handleAction(actions.ringGroupsList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))

    .handleAction(actions.ringGroupsList.success, (state, action) => {
        const ringGroupsList = action.payload;
        return {
            ...state,
            ringGroupsList: {
                ...state.ringGroupsList,
                ...ringGroupsList,
            },
            isLoading: false,
        };
    })
    .handleAction(actions.ringbackTonesList.success, (state, action) => {
        return {
            ...state,
            ringbackTonesList: action.payload,
        };
    })
    .handleAction(actions.onHoldList.success, (state, action) => {
        return {
            ...state,
            onHoldList: action.payload,
        };
    })
    .handleAction(actions.prepareDataForAddNewRingGroup.request, (state) => {
        return {
            ...state,
            isFormLoading: true,
            errors: undefined,
        };
    })
    .handleAction(actions.prepareDataForAddNewRingGroup.success, (state) => {
        return {
            ...state,
            isFormLoading: false,
        };
    })

    .handleAction(actions.groupMembersDetails.success, (state, action) => {
        return {
            ...state,
            groupMembersDetails: action.payload,
        };
    })
    .handleAction(actions.addRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.addRingGroup.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload,
            isFormSending: false,
        };
    })
    .handleAction(actions.ringGroupDetails.success, (state, action) => {
        return {
            ...state,
            ringGroupDetails: action.payload,
        };
    })
    .handleAction(actions.ringGroupDetails.request, (state) => {
        return {
            ...state,
            isFormLoading: true,
        };
    })
    .handleAction(actions.editRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.editRingGroup.success, (state) => {
        return {
            ...state,
            isFormSending: false,
        };
    })
    .handleAction(actions.editRingGroup.failure, (state, action) => {
        return {
            ...state,
            isFormSending: false,
            errors: action.payload,
        };
    })
    .handleAction(
        actions.editRingGroupCallQueueStatus.success,
        (state, action) => {
            const ringGroupIndex = state.ringGroupsList.items.findIndex(
                (v) => v.i_c_group === action.payload.i_c_group,
            );

            const items = [...state.ringGroupsList.items];

            if (ringGroupIndex !== -1) {
                items[ringGroupIndex].assigned_callqueue = action.payload
                    .callQueueStatus
                    ? { i_c_queue: action.payload.i_c_queue }
                    : undefined;
            }

            return {
                ...state,
                ringGroupsList: {
                    ...state.ringGroupsList,
                    items,
                },
            };
        },
    )
    .handleAction(actions.createNewRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.createNewRingGroup.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload,
            isFormSending: false,
        };
    })
    .handleAction(actions.createNewRingGroup.success, (state) => {
        return {
            ...state,
            isFormSending: false,
        };
    })
    .handleAction(actions.uploadRingbackTone.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.uploadRingbackTone.success, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
    .handleAction(actions.uploadRingbackTone.failure, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.getHuntGroupList.success, (state, action) => ({
        ...state,
        huntGroupList: action.payload,
    }))
    .handleAction(actions.onResponseMessageList.success, (state, action) => ({
        ...state,
        responseMessageList: action.payload,
    }));
export default ringGroupsReducer;
