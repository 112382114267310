import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import { CustomerAgreementCondition } from '../../../store/types/CustomerAgreementCondition';
import Config from '../../../config.json';

export const useStyles = makeStyles(() => ({
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
        whiteSpace: 'nowrap',
        paddingLeft: 15,
    },
}));

export enum MainProductType {
    Extensions = 'EXTENSIONS',
    AutoAttendants = 'AUTO_ATTENDANTS',
    FaxMailboxes = 'FAX_MAILBOXES',
}

export function filterProductsByType(
    type?: MainProductType,
    products?: CustomerAgreementCondition[],
): CustomerAgreementCondition[] {
    let listProducts = products ?? [];

    if (type && Config.I_PRODUCT_GROUPS?.[type]) {
        const i_groups = Config.I_PRODUCT_GROUPS[type].split(',');

        listProducts = listProducts?.filter(
            (p) =>
                !p.i_product_group ||
                i_groups.includes(p.i_product_group.toString()),
        );
    } else {
        listProducts = listProducts?.filter((p) => !p.i_product_group);
    }

    return listProducts;
}
