import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {AutoAttendantListItem} from '../store/types/AutoAttendant';

export const useAutoAttendantDetailsForm = (id: string) => {
    const extension = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.extension,
    );

    const umDomain = useSelector(
        (state: ReduxState) => state.generic.configData?.MediaServerDomain,
    );

    const autoAttendantDetails = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails,
    );

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.autoAttendants.isFormDataLoading,
    );

    const isCallScreeningTabLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callScreeningTab
                ?.isLoading || false,
    );

    const isCallForwardingTabLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callForwardingTab
                ?.isLoading || false,
    );
    
    const isCallFlowTabLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants?.autoAttendantDetails?.callFlowTab
                ?.isLoading || false,
    );

    const {autoAttendantList} = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    const autoattendantFoundInState = useMemo(() => {
        const autoattendantsFoundInState = autoAttendantList?.items?.filter((e: AutoAttendantListItem) => e.extension_id == id);

        const returnValue = autoattendantsFoundInState && autoattendantsFoundInState.length > 0
            ? autoattendantsFoundInState[0]
            : null;

        return returnValue;
    }, [autoAttendantList]);

    const loadingInProgress = useMemo(() => {
        let loadingInProgress = false;

        if (loading) {
            loadingInProgress = true;
        } else if (!isCallFlowTabLoading) {
            loadingInProgress = false;
        } else if (autoAttendantDetails) {
            let aaTabsLoading = false;
            loadingInProgress = true;

            Object.keys(autoAttendantDetails).forEach((atr: string) => {
                if (
                    // @ts-ignore
                    autoAttendantDetails[atr] &&
                    // @ts-ignore
                    autoAttendantDetails[atr]?.isLoading === true
                ) {
                    aaTabsLoading = true;
                }
            });

            if (
                !aaTabsLoading &&
                !isCallScreeningTabLoading &&
                !isCallForwardingTabLoading
            ) {
                loadingInProgress = false;
            }
        }

        return loadingInProgress;
    }, [
        autoAttendantDetails,
        loading,
        isCallScreeningTabLoading,
        isCallForwardingTabLoading,
    ]);

    return useMemo(() => {
        if (autoattendantFoundInState) {
            return {
                ...autoattendantFoundInState,
                extension_name: autoattendantFoundInState.name,
                account_id: autoattendantFoundInState.id,
                um_domain: autoattendantFoundInState.account_info?.um_domain,
                allTabsLoaded: !loadingInProgress,
            };
        }

        return {
            i_account: extension?.i_account,
            is_blocked: extension?.account_info?.blocked === 'Y',
            didNumbers: extension?.didNumbers,
            sip_status: !!extension?.account_info?.sip_status,
            i_c_ext: extension?.i_c_ext,
            extension_id: extension?.id,
            i_customer: extension?.i_customer,
            account_id: extension?.account_info?.id,
            um_domain: extension?.account_info?.um_domain || umDomain,
            country: extension?.account_info?.country,
            delete_enable: extension?.delete_enable || false,
            extension_name: extension?.name,
            allTabsLoaded: !loadingInProgress,
        };
    }, [extension, umDomain, autoAttendantDetails]);
};
