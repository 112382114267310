import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';

const requiredFieldError = i18n.t('errors:common.emptyInput');

export const OnHoldMusicValidationSchema = Yup.object().shape({
    fileName: Yup.string().required(requiredFieldError),
    file: Yup.mixed().required(requiredFieldError),
});

export const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    itemsContainer: {
        padding: '0px 0px 17px 0px',
        marginTop: 11,
        maxWidth: 876,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            '& div': {
                '& div': {
                    height: 'unset',
                    marginBottom: 0,
                },
            },
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
        marginBottom: 0,
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 20,

        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            color: Colors.Text,
            fontSize: 14,
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        paddingTop: 30,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
        '& div': {
            maxWidth: 522,
        },
    },
    columnItemsContainer: {
        flexDirection: 'column',

        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        padding: '11px 16px',
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 20,
            width: 520,
        },
        '& .MuiGrid-item': {
            marginBottom: 10,
        },
    },
    fileRow: {
        display: 'flex',
        alignItems: 'flex-start',
        marginRight: '0 !important',
        marginBottom: 21,
    },
    fileBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 358,
        height: 56,
    },
    uploadMargin: {
        marginLeft: 24,
        marginTop: 8,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    descriptionFormats: {
        fontSize: 12,
        marginRight: 16,
        color: Colors.Gray5,
    },
    actionButtonDisabled: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    option: {
        height: 47,
    },
    switcher: {
        marginBottom: '0 !important',
        height: 'unset !important',
        maxWidth: 185,
    },
    switchMargin: {
        // marginLeft: -10,
    },
}));

export const initialValues = {
    musicFile: '',
    fileName: '',
    file: null,
};

export type OnHoldMusicFormType = {
    switch: boolean;
};

export type OnHoldMusicProps = {
    setValue: (field: string, value: boolean) => void;
    value: boolean;
    disabled?: boolean;
    id?: string;
    accountId?: number;
    mohItems?: any[];
    selectedMohFile: string;
    withTooltip?: boolean;
    onSave?: (file: File, name: string, callback: () => void) => void;
    customTooltip?: string;
    fileSelectId?: string;
    disableSelectHide?: boolean;
    customLabel?: string;
    classes?: {
        switcher?: string;
        container?: string;
        selectContainer?: string;
        outsideContainer?: string;
    };
    customSelectLabel?: string;
    hideSwitcher?: boolean;
    helperText?: string;
    withoutUpload?: boolean;
};

export type MusicUpdateFormProps = {
    musicFile: string;
    fileName: string;
    file: File | null;
};
