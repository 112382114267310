import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import classNames from 'classnames';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import Checkbox from '../../Checkbox/Checkbox';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';

import {
    CallQueueFormType,
    CallQueueFormProps,
    useStyles,
} from './callQueueFormUtils';
import FileSelect from '../../FileSelect/FileSelect';
import Separator from '../../Separator/Separator';

export const callQueueFormDefaultValues: CallQueueFormType = {
    callQueueStatus: false,
    announceNumberOfCallersInQueue: false,
    maximumNumberOfQueuedCallers: 10,
    announceEstimatedWaitTime: false,
    averageHandleTime: '1',
    intervalBetweenAnnouncements: '5',
    playOnHoldMusicStatus: false,
    onHoldMusicFileName: '',
    onHoldMusicFile: null,
};

const CallQueueForm: React.FC<CallQueueFormProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        values,
        errors,
        handleChange,
        setFieldValue,
    } = useFormikContext<CallQueueFormType>();

    return (
        <div className={classes.inputs}>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.extraPadding,
                )}
            >
                <Box
                    className={classNames(classes.rowBox, classes.switchMargin)}
                >
                    <SwitchWithLabel
                        label={t('screens:ringGroups.callQueue')}
                        setValue={setFieldValue}
                        value={values.callQueueStatus}
                        field={'callQueueStatus'}
                        id={'callQueueStatus'}
                        icon={
                            <IconWithTooltip
                                dataQa="callQueue-tooltip"
                                tooltipText={t('tooltips:ringGroups.callQueue')}
                            />
                        }
                    />
                </Box>
            </Grid>

            {values.callQueueStatus && (
                <>
                    <Grid item className={classNames(classes.itemsContainer)}>
                        <Box>
                            <TextField
                                id="maximumNumberOfQueuedCallers"
                                label={t(
                                    'screens:ringGroups.maximumNumberInQueuedCallers',
                                )}
                                onChange={handleChange}
                                value={values.maximumNumberOfQueuedCallers?.toString()}
                                type="number"
                                icon={
                                    <IconWithTooltip
                                        dataQa="ring-group-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.maximumNumberInQueuedCallers',
                                        )}
                                        className={classes.numberInputTooltip}
                                    />
                                }
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 999,
                                    },
                                    pattern: '[0-9]*',
                                    startAdornment:
                                        values.maximumNumberOfQueuedCallers !==
                                        0 ? (
                                            <span className={classes.minText}>
                                                {t('common:callers')}
                                            </span>
                                        ) : undefined,
                                }}
                                iconPosition="end"
                                className={classNames(
                                    classes.numberInput,
                                    classes.noMarginBottom,
                                )}
                                dataQa="maximum-number-of-queued-callers-input"
                                required
                                helperText={errors.maximumNumberOfQueuedCallers}
                            />
                        </Box>
                    </Grid>
                    <Separator />
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <Checkbox
                                dataQa="announce-number-of-callers-in-queue-status"
                                checked={values.announceNumberOfCallersInQueue}
                                onChange={(e) =>
                                    setFieldValue(
                                        'announceNumberOfCallersInQueue',
                                        e.target.checked,
                                        false,
                                    )
                                }
                                label={t(
                                    'screens:ringGroups.announceNumberOfCallersInQueue',
                                )}
                            />
                            <IconWithTooltip
                                dataQa="announceNumberOfCallers-tooltip"
                                tooltipText={t(
                                    'tooltips:ringGroups.announceNumberOfCallers',
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.rowBox}
                        >
                            <Checkbox
                                dataQa="announce-estimated-wait-time-status"
                                checked={values.announceEstimatedWaitTime}
                                onChange={(e) =>
                                    setFieldValue(
                                        'announceEstimatedWaitTime',
                                        e.target.checked,
                                        false,
                                    )
                                }
                                label={t(
                                    'screens:ringGroups.announceEstimatedWaitTime',
                                )}
                            />
                            <IconWithTooltip
                                dataQa="ring-group-ringback-tone-tooltip"
                                tooltipText={t(
                                    'tooltips:ringGroups.announceEstimated',
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classNames(
                                !values.announceEstimatedWaitTime &&
                                    !values.announceNumberOfCallersInQueue &&
                                    classes.tinyMargin,
                            )}
                        >
                            {values.announceEstimatedWaitTime && (
                                <TextField
                                    id="averageHandleTime"
                                    label={t(
                                        'screens:ringGroups.averageHandleTime',
                                    )}
                                    onChange={handleChange}
                                    value={values.averageHandleTime?.toString()}
                                    type="number"
                                    icon={
                                        <IconWithTooltip
                                            dataQa="ring-group-name-tooltip"
                                            tooltipText={t(
                                                'tooltips:ringGroups.averageHandleTime',
                                            )}
                                            className={
                                                classes.numberInputTooltip
                                            }
                                        />
                                    }
                                    inputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment:
                                            values.averageHandleTime !== '' ? (
                                                <span
                                                    className={classes.minText}
                                                >
                                                    min
                                                </span>
                                            ) : undefined,
                                    }}
                                    iconPosition="end"
                                    className={classNames(classes.numberInput)}
                                    dataQa="average-handle-time-input"
                                    required
                                    helperText={errors.averageHandleTime}
                                    maxLength={3}
                                />
                            )}
                            {(values.announceEstimatedWaitTime ||
                                values.announceNumberOfCallersInQueue) && (
                                <TextField
                                    id="intervalBetweenAnnouncements"
                                    label={t(
                                        'screens:ringGroups.intervalBetweenAnnouncements',
                                    )}
                                    onChange={handleChange}
                                    value={values.intervalBetweenAnnouncements?.toString()}
                                    type="number"
                                    icon={
                                        <IconWithTooltip
                                            dataQa="ring-group-name-tooltip"
                                            tooltipText={t(
                                                'tooltips:ringGroups.intervalBetweenAnnouncements',
                                            )}
                                            className={
                                                classes.numberInputTooltip
                                            }
                                        />
                                    }
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment:
                                            values.intervalBetweenAnnouncements !==
                                            '' ? (
                                                <span
                                                    className={classes.minText}
                                                >
                                                    min
                                                </span>
                                            ) : undefined,
                                    }}
                                    iconPosition="end"
                                    className={classNames(
                                        classes.numberInput,
                                        classes.noMarginBottom,
                                    )}
                                    dataQa="interval-between-announcements-input"
                                    required
                                    helperText={
                                        errors.intervalBetweenAnnouncements
                                    }
                                />
                            )}
                        </Box>
                    </Grid>
                </>
            )}

            {values.callQueueStatus && (
                <>
                    <Separator />

                    <Grid item className={classNames(classes.itemsContainer)}>
                        <Box
                            className={classNames(
                                classes.rowBox,
                                classes.switchMargin,
                            )}
                        >
                            <SwitchWithLabel
                                label={t('screens:extensions.onHoldMusic')}
                                setValue={setFieldValue}
                                value={values.playOnHoldMusicStatus}
                                field={'playOnHoldMusicStatus'}
                                id={'playOnHoldMusicStatus'}
                                icon={
                                    <IconWithTooltip
                                        dataQa="on-hold-music"
                                        tooltipText={t(
                                            'tooltips:ringGroups.onHoldMusic',
                                        )}
                                    />
                                }
                            />
                        </Box>

                        {values.playOnHoldMusicStatus && (
                            <FileSelect
                                file={values.onHoldMusicFile}
                                nameValue={values.onHoldMusicFileName || ''}
                                onFileChange={(file) => {
                                    setFieldValue(
                                        'onHoldMusicFileName',
                                        file.name,
                                    );
                                    setFieldValue('onHoldMusicFile', file);
                                }}
                                customLabel={t(
                                    'screens:callSettings.musicTitle',
                                )}
                                required
                                helperText={errors.onHoldMusicFileName}
                            />
                        )}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default CallQueueForm;
