import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import React, { useEffect, useState } from 'react';
import { TransactionDialogProps, useStyles } from './TransactionDialog.utils';
import TransactionFilters, {
    TransactionFilterDateModes,
    TransactionFiltersFormType,
} from '../TransactionFilters/TransactionFilters';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { getFilterPayload } from '../../views/Billing/Transactions/TransactionList.utils';
import { ReduxState } from '../../store/types';
import TransactionTableFactory from '../TransactionTables/TransactionTableFactory';
import { PageChangeEvent } from '../DataGrid/types';
import Button from '@material-ui/core/Button';
import { CloudDownload } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import CustomizedTooltip from '../Tooltip/Tooltip';

const TransactionDialog: React.VFC<TransactionDialogProps> = ({
    serviceName,
    i_service,
    i_service_type,
    isOpen,
    initData,
    toggleVisibility,
    userDateTimeFormat,
    timezoneOffset,
    onClickCsvButton,
    currency,
    initialPageSize,
    ratio,
    unit,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [filters, setFilters] = useState<TransactionFiltersFormType>(
       initData,
    );

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const tansactionsDetailsList = useSelector(
        (state: ReduxState) =>
            state.billing.transactionsTab?.transactionDetails?.xdr_list?.map((e) => {
            e.description === (t('screens:billing.description.allTransactionsToDate') + ':')
                ? e.description = t('screens:billing.description.allTransactionsToDate')
                : e.description;
            return e;
        })
    );

    const total = useSelector(
        (state: ReduxState) =>
            state.billing.transactionsTab?.transactionDetails?.total,
    );

    const isLoading = useSelector(
        (state: ReduxState) =>
            state.billing.transactionsTab?.transactionDetails?.isLoading,
    );

    const handlePageChange = (param: PageChangeEvent) => {
        setPage(param.page);
    };

    const handlePageSizeChange = (param: PageChangeEvent) => {
        setPageSize(param.pageSize);
        setPage(0);
    };

    const fetchData = (newFilters?: TransactionFiltersFormType) => {
        if (i_service) {
            const actualFilters = newFilters || filters;
            dispatch(
                actions.getTransactionDetails.request({
                    ...getFilterPayload(actualFilters, timezoneOffset || 0),
                    limit: pageSize,
                    offset: page * pageSize,
                    i_service: i_service || 0,
                }),
            );
        }
    };

    useEffect(() => {
        setFilters(initData);
    }, [initData]);

    useEffect(() => {
        fetchData();
    }, [pageSize, page]);

    useEffect(() => {
        if (isOpen && i_service) {
            dispatch(actions.transactionsHistoryRefresh());
            setPage(0);
            setPageSize(initialPageSize);
            fetchData();
        }
    }, [isOpen]);
    
    return (
        <DialogContainer
            header={serviceName}
            isOpen={isOpen}
            dialogActionsButtons={[
                <DialogButton
                    key="close"
                    label={t('common:close')}
                    onClick={toggleVisibility}
                />,
            ]}
            dialogLeftSideActionsButtons={[
                <div key="download-csv">
                    <CustomizedTooltip
                        title={t('tooltips:billing:downloadCsvButtonTooltip')}
                        copy={false}
                        disableHoverListener={false}
                        above
                    >
                        <Button
                            data-qa={'download-csv-button'}
                            data-testid={'download-csv-button-at-dialog'}
                            type="button"
                            onClick={() => {
                                onClickCsvButton({
                                    ...filters,
                                    i_service
                                }, timezoneOffset || 0);
                            }}
                            className={classes.downloadButton}
                        >
                            <CloudDownload
                                htmlColor={Colors.Secondary2}
                                style={{ height: 22, width: 22, marginRight: 6, marginLeft: -3 }}
                            />
                            {t('screens:billing:downloadCsvButtonLabel')}
                        </Button>
                    </CustomizedTooltip>
                </div>
            ]}
            className={classes.modalContainer}
        >
            <TransactionFilters
                initData={filters}
                userDateTimeFormat={userDateTimeFormat}
                onChange={(values: TransactionFiltersFormType) => {
                    let needReload = false;
                    if(filters.dateMode != values.dateMode && values.dateMode !== TransactionFilterDateModes.Custom) {
                        needReload = true;
                    }
                    setFilters(values);
                    if(needReload) {
                        fetchData(values);
                    }
                }}
                onClick={fetchData}
                className={classes.filters}
            />
            <TransactionTableFactory
                iService={i_service || 0}
                iServiceType={i_service_type || 0}
                loading={isLoading}
                rowCount={total || 0}
                initialPageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                data={tansactionsDetailsList || []}
                currency={currency}
                unit={unit || 'USD'}
                ratio={ratio || 1}
                userDateTimeFormat={userDateTimeFormat}
            />
        </DialogContainer>
    );
};

export default TransactionDialog;
