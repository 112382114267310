import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import {
    EditExtensionForm,
    ChangeDirtyStateDetailsTab,
} from '../../../../views/Extensions/ExtensionDetails/utils';
import { DefaultAnsweringActionFlag } from '../../../../store/types/ServiceFeature';
import { Colors } from '../../../../styles/Colors';
import { ApiFile } from '../../../../store/types/ApiFile';
import {
    unifiedMessagingFormDefaultValues,
    UnifiedMessagingFormType,
    unifiedMessagingValidationSchema,
} from '../UnifiedMessaging/UnifiedMessagingForm.utils';
import {
    callBarringFormDefaultValues,
    CallBarringFormType,
    callBarringFormValidationSchema,
} from '../../CallSettings/CallBarring/utils';
import {
    callRecordingFormDefaultValues,
    CallRecordingFormType,
    callRecordingFormValidationSchema,
} from '../CallRecording/CallRecordingForm.utils';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '22px 16px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
        '& > :first-child': {
            marginRight: 24,
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    numberInput: {
        maxWidth: 125,
        width: 125,
        marginRight: 20,

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },

    defaultAnswringModeInput: {
        maxWidth: 320,
    },
    minText: {
        position: 'absolute',
        marginTop: 17,
        marginLeft: 58,
    },
    mohContainer: {
        marginTop: 0,
    },
    selectedMohContainer: {
        marginBottom: 10,
        marginTop: 15,
        marginLeft: 38,
    },

    musicOnHoldSwitcher: {
        maxWidth:'fit-content'
    },

    switchContainer: {
        marginBottom: 0,
    },
    extraPadding: {
        paddingLeft: 16,
    },
}));

export type ExtensionCallSettingsFormType = {
    defaultAnsweringMode: DefaultAnsweringActionFlag;
    defaultAnsweringTimeout: number;
    onHoldMusicStatus: boolean;
    onHoldMusicName?: string;
    onHoldMusicFile?: File | null;
    extToExtCallDisctintiveRingStatus: boolean;
    extToExtCallDisctintiveRingBlocked: boolean;
    callWaitingStatus: boolean;
    callWaitingBlocked: boolean;
} & UnifiedMessagingFormType &
    CallBarringFormType &
    CallRecordingFormType;

export const callSettingsValidationSchema = Yup.object()
    .shape({
        defaultAnsweringMode: Yup.string().required(),
        defaultAnsweringTimeout: Yup.number().required(),
        onHoldMusicStatus: Yup.boolean().required(),
        onHoldMusicName: Yup.string().notRequired(),
        onHoldMusicFile: Yup.object().notRequired(),
        extToExtCallDisctintiveRingStatus: Yup.boolean().notRequired(),
        callWaitingStatus: Yup.boolean().notRequired(),
        callWaitingBlocked: Yup.boolean().notRequired(),
    })
    .concat(unifiedMessagingValidationSchema)
    .concat(callBarringFormValidationSchema)
    .concat(callRecordingFormValidationSchema);

export const callSettingsDefaultValues: ExtensionCallSettingsFormType = {
    defaultAnsweringMode: DefaultAnsweringActionFlag.RingForwardVoicemail,
    defaultAnsweringTimeout: 15,
    onHoldMusicStatus: false,
    extToExtCallDisctintiveRingStatus: false,
    extToExtCallDisctintiveRingBlocked: false,
    callWaitingStatus: false,
    callWaitingBlocked: false,
    ...unifiedMessagingFormDefaultValues,
    ...callBarringFormDefaultValues,
    ...callRecordingFormDefaultValues,
};

export type CallSettingsFormProps = {
    accountId?: string;
    i_account?: number;
    mohItems: ApiFile[];
    handleSubmitForm?: (formData: Partial<EditExtensionForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
};
