import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import IntervalSelect, {
    IntervalFormProps,
    IntervalStatus,
} from '../../IntervalSelect/IntervalSelect';
import { ActionsFormType } from './ActionsForm.utils';
import WhenMenuIsActive from '../../WhenMenuIsActive/WhenMenuIsActive';
import AllowCellars from '../../AllowCellers/AllowCellars';
import DisconnectCall from '../../DisconnectCall/DisconnectCall';
import classNames from 'classnames';
import {
    SettingsFormType,
    SettingsFormProps,
    useStyles,
} from './SettingsForm.utils';
import Separator from '../../Separator/Separator';

const SettingsForm: React.FC<SettingsFormProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { values, errors, handleChange, setFieldError } = useFormikContext<
        SettingsFormType & ActionsFormType & { intervals: IntervalFormProps }
    >();

    return (
        <div className={classes.inputs}>
            {values.name !== 'ROOT' && (
                <Grid item className={classes.itemsContainer}>
                    <TextField
                        id="name"
                        label={t('screens:autoAttendants.menuName')}
                        onChange={handleChange}
                        value={values.name}
                        dataQa="menu-name-input"
                        setFieldError={setFieldError}
                        className={classes.nameInput}
                        required
                        helperText={errors.name}
                        maxLength={64}
                    />
                </Grid>
            )}

            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.intervalsContainer,
                )}
            >
                <IntervalSelect />
            </Grid>

            <Separator />

            {values.intervals.activity !== IntervalStatus.Always && (
                <>
                    <Grid item className={classes.itemsContainer}>
                        <WhenMenuIsActive />
                    </Grid>
                    <div className={classes.separator} />
                </>
            )}

            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.callerContainer,
                )}
            >
                <AllowCellars />
            </Grid>

            <Separator />

            <Grid
                item
                className={classNames(classes.itemsContainer, classes.noBorder)}
            >
                <DisconnectCall />
            </Grid>
        </div>
    );
};

export default SettingsForm;
