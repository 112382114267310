import React from 'react';
import { Colors } from '../../styles/Colors';

const Separator = () => {
    const style = {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
        marginTop: 10,
        marginBottom: 28,
    };

    return <div style={style} />;
};

export default Separator;
