import React from 'react';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import {
    UserInput,
    MenuAction,
    AutoAttendantMenu,
} from '../../../store/types/AutoAttendant';
import DataItemActions from '../../../components/DataTable/DataItemActions';
import { ReactComponent as MusicPlayCircle } from '../../../assets/music-play-circle.svg';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import { CallQueue } from '../../../store/types/CallQueue';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import MenuActionAdditionalInfo from '../../AutoAttendants/MenuActionAdditionalInfo';
import TextButton from '../../TextButton/TextButton';
import { Permission } from '../../../store/types/Permission';

export const PlusIcon = (
    <Add
        htmlColor={Colors.White}
        style={{ height: 18, width: 18, marginRight: 10 }}
    />
);

export type ActionsFormType = {
    actions: MenuActionFormType[];
};

export type MenuActionFormType = {
    userInput?: UserInput;
    action?: MenuAction;
    timeout?: string;
    announceExtensionNumbers?: boolean;
    playBeforeActionStatus?: boolean;
    playBeforeActionFileName?: string;
    playBeforeActionFile?: File | null;
    transferCallerToPhoneNumberExtension?: string;
    maxDigits?: string;
    menu?: AutoAttendantMenu;
    queue?: CallQueue;
    transitionId?: number;
};

export const actionsFormValidationSchema = Yup.object().shape({
    actions: Yup.array().notRequired(),
});

export const actionsFormDefaultValues: ActionsFormType = {
    actions: [],
};

export type ActionsFormProps = {};

export const useStyles = makeStyles(() => ({
    addButton: {
        marginBottom: 32,
        display: 'flex',
        '& .MuiButton-label': {
            paddingLeft: '0!important',
            paddingRight: '0!important',
        },
    },
    idText: {
        color: Colors.Secondary1,
        cursor: 'pointer',
    },
    recordedPromptName: {
        marginLeft: 5,
        flex: 1,
    },
    descriptionText: {
        color: Colors.Gray5,
    },
    editButton: {
        padding: '5px 0',
        marginRight: 25,
        '& .MuiButton-label': {
            color: `${Colors.Gray5}!important`,
            paddingLeft: `5px!important`,
            paddingRight: `5px!important`,
        },
        '& svg': {
            '& path': {
                fill: `${Colors.Gray5}!important`,
            },
        },
    },
    promptBox: {
        marginLeft: 45,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    removeDialogContainer: {
        '& .MuiDialogContent-root': {
            width: 232,
            minWidth: 232,
            backgroundColor: Colors.White,
        },
    },
    actionsForm: {
        marginTop: 8,
    },
    table: {
        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(1)': {
            paddingLeft: 40,
        },
        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(4)': {
            marginRight: -25,
        },
        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(5)': {
            marginRight: -20,
        },

        '& tr td.MuiTableCell-root:nth-child(1)': {
            paddingLeft: 40,
        },
    },
    tableContainer: {
        maxWidth: 1040,
        overflowX: 'hidden',
    },
    playBefore: {
        color: Colors.Gray3,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    onPressEdit: (action: MenuActionFormType) => void,
    onPressRemove: (action: MenuActionFormType) => void,
): Column<MenuActionFormType>[] => {
    return [
        {
            Header: t<string>('common:name'),
            accessor: 'userInput',
            width: 1,
            minWidth: 120,
            maxWidth: 180,
            Cell: function Cell(params) {
                return (
                    <TextButton
                        onClick={() => onPressEdit(params.row.original)}
                    >
                        {t(`enums:userInput.${params.row.original.userInput}`)}
                    </TextButton>
                );
            },
        },
        {
            Header: t<string>('screens:autoAttendants.active'),
            accessor: 'action',
            width: 1,
            maxWidth: 200,
            minWidth: 200,
            Cell: function Cell(params) {
                return (
                    <span>
                        {t(
                            `enums:inactiveMenuAction.${params.row.original.action}`,
                        )}
                    </span>
                );
            },
        },
        {
            Header: t<string>('screens:autoAttendants.details'),
            accessor: 'menu',
            width: 2,
            Cell: function Cell(params) {
                return (
                    <MenuActionAdditionalInfo action={params.row.original} />
                );
            },
        },
        {
            Header: t<string>('screens:autoAttendants.recordedPrompt'),
            accessor: 'playBeforeActionFile',
            width: 1,

            Cell: function Cell(params) {
                if (
                    !params.row.original.playBeforeActionFileName ||
                    !params.row.original.playBeforeActionStatus
                ) {
                    return <span></span>;
                }

                return (
                    <div className={classes.promptBox}>
                        <CustomizedTooltip
                            copy={false}
                            title={
                                <>
                                    <span className={classes.playBefore}>
                                        {`${t<string>(
                                            'screens:autoAttendants.playBeforeAction',
                                        )}:`}
                                    </span>
                                    {
                                        params.row.original
                                            .playBeforeActionFileName
                                    }
                                </>
                            }
                        >
                            <MusicPlayCircle />
                        </CustomizedTooltip>
                    </div>
                );
            },
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'announceExtensionNumbers',
            width: 1,
            maxWidth: 140,
            minWidth: 140,
            Cell: function Cell(params) {
                return (
                    <>
                        <DataItemActions
                            onDelete={() => onPressRemove(params.row.original)}
                            onEdit={() => onPressEdit(params.row.original)}
                            skipEditPermissionReadOnly={false}
                            editPermission={
                                Permission.CloudPBX.AutoAttendants
                                    .AutoAttendantDetails.Menu.MenuDetails
                                    .MenuActions.value
                            }
                            deletePermission={
                                Permission.CloudPBX.AutoAttendants
                                    .AutoAttendantDetails.Menu.MenuDetails
                                    .MenuActions.value
                            }
                        />
                    </>
                );
            },
        },
    ];
};
