import { put, takeLatest, select, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import { api } from '../../services/axios';
import { AxiosResponse } from 'axios';
import { ReduxState } from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import { CountryListResponse } from '../../types/Country';
import {
    LocaleLanguagesResponse,
    TimeZoneListResponse,
} from '../../types/TimeZone';
import { SessionData } from '../../types/Session';
import { Subdivision } from '../../types/Subdivision';
import { ActionType } from 'typesafe-actions';
import { ConfigData } from '../../types/ConfigData';
import {
    CustomerInfo,
    CustomerInfoDetails,
    CustomerISO,
    GetGlobalCurrencyInfoResponse,
} from '../../types/CustomerInfo';
import { ExtensionFilters } from '../../reducers/extensions/extensions/reducer';
import { AccountListRequest } from '../../types/Account';
import { ServiceFeatureName } from '../../types/ServiceFeature';
import { Account, Customer, Generic } from '../../../services/endpoints';

export const LEGACY_TIME_FORMAT = 'Mi';
export const TRANSFORM_LEGACY_TIME_FORMAT = 'MI';
const toCheck = ['out_time_format', 'out_date_time_format', 'out_date_format'];

export function* getCountriesList() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<CountryListResponse> = yield api.post(
            Generic.GetCountriesList,
            body,
        );

        yield put(actions.countriesList.success(res.data.countries_list));
    } catch (err) {
        yield put(actions.countriesList.failure(err));
    }
}

export function* getTimeZonesList() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        const res: AxiosResponse<TimeZoneListResponse> = yield api.post(
            Generic.GetTimeZoneList,
            body,
        );

        yield put(actions.timeZonesList.success(res.data.time_zone_list));
    } catch (err) {
        yield put(actions.timeZonesList.failure(err));
    }
}

export function* getSubdivisionsData(
    action: ActionType<typeof actions.getSubdivisionData.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ iso_3166_1_a2: action.payload.iso_3166_1_a2 });

        const res: AxiosResponse<{
            subdivisions_list: Subdivision[];
        }> = yield api.post(Generic.GetSubdivisionsList, body);

        yield put(
            actions.getSubdivisionData.success(res.data.subdivisions_list),
        );
    } catch (err) {
        yield put(actions.getSubdivisionData.failure(err));
    }
}

function isLangUpdate(lang: string) {
    const storedLang = localStorage.getItem('user_language');
    return !!storedLang && storedLang != lang;
}

export function transformSessionData(data: SessionData): SessionData {
    toCheck.forEach((v) => {
        // @ts-ignore
        if (!!data[v] && data[v].indexOf(LEGACY_TIME_FORMAT) !== -1) {
            // @ts-ignore
            data[v] = data[v].replace(/Mi/g, TRANSFORM_LEGACY_TIME_FORMAT);
        }
    });

    return data;
}

export function transformCustomerData(
    data: CustomerInfoDetails,
): CustomerInfoDetails {
    toCheck.forEach((v) => {
        // @ts-ignore
        if (!!data[v] && data[v].indexOf(LEGACY_TIME_FORMAT) !== -1) {
            // @ts-ignore
            data[v] = data[v].replace(/Mi/g, TRANSFORM_LEGACY_TIME_FORMAT);
        }
    });

    return data;
}

export function* getSessionData() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<SessionData> = yield api.post(
            Generic.GetSessionData,
            body,
        );

        const reload = isLangUpdate(res.data.language);

        localStorage.setItem('user_info', JSON.stringify(res.data));
        localStorage.setItem('user_language', res.data.language);
        const resData = transformSessionData(res.data);

        yield put(actions.getSessionData.success({ ...resData, reload }));
    } catch (err) {
        yield put(actions.getSessionData.failure(err));
    }
}

export function* getConfigData() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<{ config_data: ConfigData }> = yield api.post(
            Generic.GetConfigData,
            body,
        );

        yield put(actions.getConfigData.success(res.data.config_data));
    } catch (err) {
        yield put(actions.getConfigData.failure(err));
    }
}

export function* getGlobalCurrencyData() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<CustomerISO> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        body.setParams({ iso_4217: res.data.customer_info.iso_4217 });
        const globalCurrencyResponse: AxiosResponse<GetGlobalCurrencyInfoResponse> = yield api.post(
            Generic.GetGlobalCurrencyInfo,
            body,
        );

        yield put(
            actions.getGlobalCurrencyData.success(
                globalCurrencyResponse.data.global_currency_info,
            ),
        );
    } catch (err) {
        yield put(actions.getGlobalCurrencyData.failure(err));
    }
}

export function* getLocalLanguagesList() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        const res: AxiosResponse<LocaleLanguagesResponse> = yield api.post(
            Generic.GetLocaleLanguagesList,
            body,
        );

        yield put(
            actions.localLanguagesList.success(res.data.locale_languages),
        );
    } catch (e) {
        yield put(actions.localLanguagesList.failure());
    }
}

export function* getGlobalCustomerInfo() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({ get_main_office_info: 1 });
        const res: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        const resData = transformCustomerData(res.data.customer_info);
        yield put(
            actions.getGlobalCustomerInfo.success({
                ...res.data,
                customer_info: resData,
            }),
        );
    } catch (err) {
        yield put(actions.getGlobalCustomerInfo.failure());
    }
}

export function* fetchAccountList(
    filters?: ExtensionFilters,
    params?: Partial<AccountListRequest>,
) {
    const { session_id, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        get_only_real_accounts: 1,
        get_statuses: 1,
        get_total: 1,
        get_not_closed_accounts: '1',
        limit_alias_did_number_list: 100,
        get_service_features: [
            ServiceFeatureName.AutoAttendant,
            ServiceFeatureName.CallBarring,
            ServiceFeatureName.CallProcessing,
            ServiceFeatureName.CallRecording,
            ServiceFeatureName.ConfEnabled,
            ServiceFeatureName.UnifiedMessaging,
        ],
        ...params,
    });

    return yield api.post(Account.GetAccountList, body);
}

export function* getCustomerSubdivisionsList() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<{
            customer_list: CustomerInfo[];
            total: number;
        }> = yield api.post(Customer.GetCustomerSubdivisionsList, body);

        yield put(
            actions.getCustomerSubdivisionsList.success(res.data.customer_list),
        );
    } catch (err) {
        yield put(actions.getCustomerSubdivisionsList.failure());
    }
}

export const genericSaga = [
    takeLatest(actions.countriesList.request, getCountriesList),
    takeLatest(actions.timeZonesList.request, getTimeZonesList),
    takeLatest(actions.getSubdivisionData.request, getSubdivisionsData),
    takeLatest(actions.getSessionData.request, getSessionData),
    takeLatest(actions.getConfigData.request, getConfigData),
    takeLatest(actions.getGlobalCurrencyData.request, getGlobalCurrencyData),
    takeLatest(actions.localLanguagesList.request, getLocalLanguagesList),
    takeLatest(actions.getGlobalCustomerInfo.request, getGlobalCustomerInfo),
    takeLatest(
        actions.getCustomerSubdivisionsList.request,
        getCustomerSubdivisionsList,
    ),
];
