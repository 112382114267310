import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { RowCellProps } from './types';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
    },
}));

const RowCell: <T extends Object>(
    p: RowCellProps<T>,
) => React.ReactElement<RowCellProps<T>> = ({
    cell,
    onCellHover,
    rowIndex,
    colIndex,
}) => {
    const classes = useStyles();

    const cellProps = cell.getCellProps();

    return (
        <TableCell
            className={classes.container}
            {...cellProps}
            style={{
                ...cellProps.style,
                maxWidth: cell.column.maxWidth,
                minWidth: cell.column.minWidth,
            }}
            onMouseOver={() => onCellHover?.(cell)}
            data-rowindex={rowIndex}
            aria-colindex={colIndex}
        >
            {cell.render('Cell')}
        </TableCell>
    );
};

export default RowCell;
