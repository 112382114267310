import { createAsyncAction } from 'typesafe-actions';
import * as payloads from '../payloads';
import {
    FetchMailboxesDidNumberPayload,
    ResultFetchMailboxesDidNumberPayload,
} from './payloads';
import { APIErrorInterface } from '../../types';
import { DeleteCustomerExtension } from '../../types/Extension';

export const getFaxMailboxesList = createAsyncAction(
    'GET_FAX_MAILBOXES_LIST',
    'GET_FAX_MAILBOXES_LIST_SUCCESS',
    'GET_FAX_MAILBOXES_LIST_FAILED',
)<{ id?: string | number }, payloads.MailboxesSuccessPayload, undefined>();

export const fetchDIDNumber = createAsyncAction(
    'GET_FAX_MAILBOXES_DID_NUMBERS',
    'GET_FAX_MAILBOXES_DID_NUMBERS_SUCCESS',
    'GET_FAX_MAILBOXES_DID_NUMBERS_FAILED',
)<
    FetchMailboxesDidNumberPayload,
    ResultFetchMailboxesDidNumberPayload,
    FetchMailboxesDidNumberPayload
>();

export const createNewFaxMailbox = createAsyncAction(
    'CREATE_NEW_FAX_MAILBOX',
    'CREATE_NEW_FAX_MAILBOX_SUCCESS',
    'CREATE_NEW_FAX_MAILBOX_FAILED',
)<payloads.CreateNewFAXMailboxRequestPayload, undefined, APIErrorInterface>();

export const getFaxMailboxesDetails = createAsyncAction(
    'GET_FAX_MAILBOXES_DETAILS',
    'GET_FAX_MAILBOXES_DETAILS_SUCCESS',
    'GET_FAX_MAILBOXES_DETAILS_FAILED',
)<
    payloads.GetFaxMailboxesDetailsRequestPayload,
    payloads.FaxMailboxDetails,
    undefined
>();

export const getFaxMailboxAccount = createAsyncAction(
    'GET_FAX_MAILBOX_ACCOUNT',
    'GET_FAX_MAILBOX_ACCOUNT_SUCCESS',
    'GET_FAX_MAILBOX_ACCOUNT_FAILED',
)<{ id: string | number }, payloads.MailboxesSuccessPayload, undefined>();

export const setFaxMailboxesStatus = createAsyncAction(
    'SET_FAX_MAILBOXES_STATUS',
    'SET_FAX_MAILBOXES_STATUS_SUCCESS',
    'SET_FAX_MAILBOXES_STATUS_FAILED',
)<
    payloads.UpdateExtensionStatusPayload,
    payloads.UpdateExtensionStatusPayload,
    undefined
>();

export const editFaxMailbox = createAsyncAction(
    'EDIT_FAX_MAILBOX',
    'EDIT_FAX_MAILBOX_SUCCESS',
    'EDIT_FAX_MAILBOX_FAILED',
)<payloads.EditFaxMailboxesRequestPayload, undefined, APIErrorInterface>();

export const deleteCustomerFaxMailbox = createAsyncAction(
    'DELETE_CUSTOMER_FAX_MAILBOX',
    'DELETE_CUSTOMER_FAX_MAILBOX_SUCCESS',
    'DELETE_CUSTOMER_FAX_MAILBOX_FAILED',
)<DeleteCustomerExtension, { id: string }, undefined>();
