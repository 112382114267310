import { Colors } from '../../styles/Colors';
import { Cancel, Edit } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button/Button';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import { Permission } from '../../store/types/Permission';

const useStyles = makeStyles(() => ({
    root: {
        textTransform: 'uppercase',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    editButton: {
        width: 'unset!important',
        minWidth: 'unset',
    },
}));

type ActionsButtonsProps = {
    release?: boolean;
    releaseClick?: () => void;
    editClick?: () => void;
};

const ActionsButtons: React.FC<ActionsButtonsProps> = ({
    releaseClick,
    release = true,
    editClick,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <span className={classes.root}>
            <PermissionProvider
                permission={Permission.Inventory.Devices.EditPort.value}
            >
                <Button
                    onClick={editClick}
                    dataTestId="edit-list-item-button"
                    dataQa="edit-list-item-button"
                    icon={<Edit htmlColor={Colors.Gray5} />}
                    ternary
                    className={classes.editButton}
                >
                    {t('common:edit')}
                </Button>
            </PermissionProvider>
            {release && (
                <PermissionProvider
                    permission={Permission.Inventory.Devices.ReleasePort.value}
                >
                    <Button
                        dataTestId="release-list-item-button"
                        dataQa="release-list-item-button"
                        onClick={releaseClick}
                        ternary
                        icon={<Cancel htmlColor={Colors.Gray5} />}
                    >
                        {t('common:release')}
                    </Button>
                </PermissionProvider>
            )}
        </span>
    );
};

export default ActionsButtons;
