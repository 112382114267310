import React from 'react';
import { PermissionContext, usePermissions } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

type PermissionProviderProps = {
    permission: string[];
    skipReadOnly?: boolean;
};

const PermissionProvider: React.FC<PermissionProviderProps> = ({
    permission,
    children,
    skipReadOnly,
}) => {
    const permissionValue = usePermissions(...permission);

    return (
        <PermissionContext.Provider
            value={
                skipReadOnly
                    ? permissionValue === PermissionType.ReadOnly
                        ? PermissionType.Visible
                        : permissionValue
                    : permissionValue
            }
        >
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
