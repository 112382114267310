import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 163,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24,
    },

    welcome: {
        fontWeight: 400,
        fontSize: 22,
        color: Colors.Gray5,
    },

    logo: {
        display: 'flex',
        width: '100%',
        height: 99,
        marginTop: 8,
        marginBottom: 8,
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxHeight: 99,
            width: 'auto',
        },
    },
}));
