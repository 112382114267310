import React from 'react';
import {
    useTabStyles,
} from '../../Extensions/ExtensionDetails/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import MenuForm from '../../../components/Forms/AutoAttendants/MenuForm';
import {TabInterface} from "../../../components/Tabs/Tabs.utils";

interface MenuTabInterface extends TabInterface {
    autoAttendantId: number | string;
}
const MenuTab: React.VFC<MenuTabInterface> = ({ autoAttendantId }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.callFlowTab?.isLoading ||
            false,
    );

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-flow-details-loader" />
        </div>
    ) : (
        <MenuForm autoAttendantId={autoAttendantId} />
    );
};

export default MenuTab;
