import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid, { GridSize } from '@material-ui/core/Grid';
import TextField from '../TextField/TextField';
import { UAInfo } from '../../store/types/Devices';
import { useTranslation } from 'react-i18next';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';

const useStyle = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    itemsContainer: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },

    dual: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: 12,
        '& .MuiFormControl-root': {
            maxWidth: 440,
        },
    },

    descriptionTooltipContainer: {
        // necessary margin - trick for showing tooltip on input
        position: 'absolute',
        marginTop: -35,
        marginLeft: 9,
        right: 32,
        left: 32,
        opacity: 0,
    },

    tooltipTitle: {
        wordBreak: 'break-word',
    },
}));

type DeviceInfoProps = {
    device?: UAInfo | null;
    dataQa?: string;
    className?: string;
    definition?: DeviceInfoDefinition[];
    customClasses?: { field?: string; container?: string };
};

export interface DeviceInfoDefinition {
    deviceField: keyof UAInfo;
    disable?: boolean;
    readonly?: boolean;
    size: GridSize;
    label?: string;
    value?: string | number;
}

const renderByDefinition = (
    device?: UAInfo | null,
    def?: DeviceInfoDefinition[],
): React.ReactNode => {
    if (!def || !device) {
        return <></>;
    }

    const items = def.map((d) => (
        <Grid item xs={d.size} key={d.deviceField}>
            <TextField
                label={d.label || d.deviceField}
                value={d.value ?? device[d.deviceField]?.toString()}
                disabled={d.disable}
                readOnly={d.readonly}
            />
        </Grid>
    ));

    return <>{items}</>;
};

const DeviceInfo: React.FC<DeviceInfoProps> = ({
    device,
    className,
    dataQa,
    definition,
    customClasses,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const infoFromDefinition = renderByDefinition(device, definition);

    return (
        <div
            className={classNames(classes.root, className)}
            data-qa={dataQa}
            data-testid={'device-info'}
        >
            <Grid
                container
                spacing={3}
                className={classNames(
                    classes.itemsContainer,
                    customClasses?.container || '',
                )}
            >
                {definition?.length ? (
                    infoFromDefinition
                ) : (
                    <>
                        <div className={classes.dual}>
                            <TextField
                                label={t('screens:devices.deviceName')}
                                value={device?.name}
                                readOnly
                                className={customClasses?.field || ''}
                            />
                            <TextField
                                label={t('screens:devices.macAddress')}
                                value={device?.mac}
                                readOnly
                                className={customClasses?.field || ''}
                            />
                        </div>

                        <div className={classes.dual}>
                            <TextField
                                label={t('screens:devices.model')}
                                value={device?.type}
                                readOnly
                                className={customClasses?.field || ''}
                            />

                            <TextField
                                label={t('screens:devices.inventoryId')}
                                value={device?.inventory_id}
                                readOnly
                                className={customClasses?.field || ''}
                            />
                        </div>

                        <Grid item xs={12}>
                            <div>
                                <TextField
                                    label={t('common:description')}
                                    value={device?.description}
                                    readOnly
                                />

                                <OverflowTooltip
                                    classes={{
                                        text:
                                            classes.descriptionTooltipContainer,
                                        titleText: classes.tooltipTitle,
                                    }}
                                    tooltip={device?.description}
                                    text={device?.description}
                                />
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default DeviceInfo;
