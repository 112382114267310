import React, { useState, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import CustomizedDialog from '../Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    container: {
        '& .MuiDialog-paperWidthSm': {
            '& .MuiDialogContent-root': {
                overflow: 'hidden',
                padding: '8px 24px',
            },
        },
    },
}));

const PreventLeavingPage = ({
    isOpen,
    onSave,
}: {
    isOpen?: boolean;
    onSave?: () => void;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const location = useLocation();
    const history = useHistory();
    const [lastLocation, setLastLocation] = useState(location);

    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showModal = (location: typeof useLocation) => {
        setIsModalOpen(true);
        setLastLocation(location);
    };

    const handleBlockedRoute = (nextLocation: any) => {
        if (!confirmedNavigation && isOpen) {
            showModal(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        onSave && onSave();
        closeModal();
        setConfirmedNavigation(true);
    };

    const handleConfirmDiscard = () => {
        setConfirmedNavigation(true);
        closeModal();
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, history]);

    return (
        <>
            <Prompt when={isOpen} message={handleBlockedRoute} />
            <CustomizedDialog
                isOpen={isModalOpen}
                handleClose={handleConfirmDiscard}
                handleSubmit={handleConfirmNavigationClick}
                content={<span>{t('common:discardChanges')}</span>}
                submitButtonText={t('common:save')}
                closeButtonText={t('common:discard')}
                dataQa="remove-call-records-dialog"
                customClasses={{ container: classes.container }}
            />
        </>
    );
};
export default PreventLeavingPage;
