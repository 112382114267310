import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    GetInvoiceFilePayload,
    GetInvoiceListPayload,
    GetTransactionsListPayload,
    InvoiceList,
    TransactionList,
} from '../../types/Billing';
import { APIErrorInterface } from '../../types';
import { CustomerInfoDetails } from '../../types/CustomerInfo';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentDetails,
    PaymentMethodInfo,
} from '../../types/Payment';
import { CallHistory } from '../../types/CallHistory';
import { AutoPaymentsForm } from '../../../views/Billing/PaymentMethod/PaymentMethod.utils';
import { PaymentMethodFormType } from '../../../components/Forms/Billing/PaymentMethodForm/PaymentMethodForm.utils';
import { MakePaymentFormType } from '../../../components/Forms/Billing/MakePaymentDialog/MakePaymentDialog.utils';

export const getBasicBillingInfo = createAsyncAction(
    'GET_BASIC_BILLING_INFO',
    'GET_BASIC_BILLING_INFO_SUCCESSES',
    'GET_BASIC_BILLING_INFO_FAILED',
)<undefined, CustomerInfoDetails, APIErrorInterface>();

export const getInvoicesListAction = createAsyncAction(
    'GET_INVOICES_LIST',
    'GET_INVOICES_LIST_SUCCESS',
    'GET_INVOICES_LIST_FAILED',
)<GetInvoiceListPayload, InvoiceList, undefined>();

export const getInvoiceSelectedFile = createAsyncAction(
    'GET_INVOICE_FILE',
    'GET_INVOICE_FILE_SUCCESS',
    'GET_INVOICE_FILE_FAILED',
)<GetInvoiceFilePayload, undefined, undefined>();

export const getTransactionList = createAsyncAction(
    'GET_TRANSACTION_LIST',
    'GET_TRANSACTION_LIST_SUCCESS',
    'GET_TRANSACTION_LIST_FAILED',
)<GetTransactionsListPayload, TransactionList, undefined>();

export const getTransactionCsvFile = createAsyncAction(
    'GET_TRANSACTION_CSV_FILE',
    'GET_TRANSACTION_CSV_FILE_SUCCESS',
    'GET_TRANSACTION_CSV_FILE_FAILED',
)<
    GetTransactionsListPayload & { fileName: string; callback?: () => void },
    undefined,
    undefined
>();

export const getTransactionDetails = createAsyncAction(
    'GET_TRANSACTION_DETAILS',
    'GET_TRANSACTION_DETAILS_SUCCESS',
    'GET_TRANSACTION_DETAILS_FAILED',
)<
    GetTransactionsListPayload & {
        i_service: number;
        callback?: () => void;
        offset?: number;
        limit?: number;
    },
    { xdr_list: CallHistory[]; total: number },
    undefined
>();

export const getPaymentData = createAsyncAction(
    'GET_PAYMENT_DATA',
    'GET_PAYMENT_DATA_SUCCESSES',
    'GET_PAYMENT_DATA_FAILED',
)<
    undefined,
    {
        ownerPaymentsMethods: OwnerPaymentMethod[];
        customerPaymentMethod?: PaymentMethodInfo;
        autoPaymentInfo: AutoPaymentInfo;
    },
    APIErrorInterface
>();

export const transactionsHistoryRefresh = createAction(
    'TANSACTION_HISTORY_REFRESH',
)();

export const editAutoPayments = createAsyncAction(
    'EDIT_AUTO_PAYMENTS',
    'EDIT_AUTO_PAYMENTS_SUCCESSES',
    'EDIT_AUTO_PAYMENTS_FAILED',
)<
    {
        changedValues: AutoPaymentsForm;
        initialValues: AutoPaymentsForm;
        redirectTab: number;
    },
    undefined,
    APIErrorInterface
>();

export const setDefaultPaymentMethod = createAsyncAction(
    'SET_DEFAULT_PAYMENT_METHOD',
    'SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    'SET_DEFAULT_PAYMENT_METHOD_FAILED',
)<PaymentMethodFormType, undefined, APIErrorInterface>();

export const removeDefaultPaymentMethod = createAsyncAction(
    'REMOVE_DEFAULT_PAYMENT_METHOD',
    'REMOVE_DEFAULT_PAYMENT_METHOD_SUCCESS',
    'REMOVE_DEFAULT_PAYMENT_METHOD_FAILED',
)<undefined, undefined, APIErrorInterface>();

export const estimateTaxes = createAsyncAction(
    'ESTIMATE_TAXES',
    'ESTIMATE_TAXES_SUCCESS',
    'ESTIMATE_TAXES_FAILED',
)<{ amount: number }, number, APIErrorInterface>();

export const clearEstimatedTaxes = createAction(
    'CLEAR_ESTIMATED_TAXES',
)<undefined>();

export const makePayment = createAsyncAction(
    'MAKE_PAYMENT',
    'MAKE_PAYMENT_SUCCESS',
    'MAKE_PAYMENT_FAILED',
)<MakePaymentFormType, undefined, APIErrorInterface>();

export const getPaymentDetails = createAsyncAction(
    'GET_PAYMENT_DETAILS',
    'GET_PAYMENT_DETAILS_SUCCESS',
    'GET_PAYMENT_DETAILS_FAILED',
)<
    { payment_id: string; withFinalization?: boolean },
    PaymentDetails | undefined,
    APIErrorInterface
>();

export const setPaymentProcessing = createAction(
    'SET_PAYMENT_PROCESSING',
)<boolean>();

export const setPaymentError = createAction('SET_PAYMENT_ERROR')<boolean>();
