import React, { useEffect, useMemo } from 'react';
import { Grid, Box } from '@material-ui/core';
import DialogContainer, {
    DialogButton,
} from '../../components/AlertDialog/DialogContainer';
import { useFormik } from 'formik';
import IconWithTooltip from '../../components/Tooltip/IconWithTooltip';
import TextField from '../../components/TextField/TextField';
import { useTranslation } from 'react-i18next';
import SelectField from '../../components/SelectField/SelectField';
import CustomKeyboardDateTimePicker from '../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import { ReduxState } from '../../store/types';
import { ConfigData } from '../../store/types/ConfigData';
import {
    useStyles,
    CreateNewPortalUserFormData,
    nameMaxLengthError,
    requiredFieldError,
    emailValidError,
} from '../../components/PortalUsers/utils';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { actions } from '../../store';
import Loader from '../../components/Loader/Loader';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField';
import { getConfigFromPasswordRulesOrDefault } from '../../utils/passwordGenerator';
import * as Yup from 'yup';
import { PortalUsersErrors } from '../../services/apiErrors';
import dayjs from '../../services/customDayJs';
import { convertUtcToUserLocalTime } from '../../utils/dateWithTimezoneConversion';

interface CreateNewPortalUserDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
}

const CreateNewPortalUserDialog: React.VFC<CreateNewPortalUserDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        roleList,
        isFormSaving,
        isAddPortalUserDataLoading,
        apiErrors,
    } = useSelector((state: ReduxState) => state.company);
    const { timeZonesList, languagesList } = useSelector(
        (state: ReduxState) => state.generic,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const i_lang = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.i_lang || '',
    );
    const i_time_zone = useSelector<ReduxState, number>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.i_time_zone || 0,
    );
    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const today = new Date();
    today.setDate(today.getDate());

    const initialValues = useMemo(
        () => ({
            login: '',
            password: '',
            role: '',
            email: '',
            activationDate: null,
            expirationDate: null,
            timezone: timeZonesList.find((e) => e.i_time_zone === i_time_zone)
                ?.time_zone_name,
            language: languagesList?.find((e) => e.iso_639_1 === i_lang)?.name,
        }),
        [i_lang, i_time_zone, timeZonesList, languagesList],
    );

    const CreateNewPortalUserValidationSchema = Yup.object().shape({
        role: Yup.string().required(requiredFieldError).nullable(),
        password: Yup.string()
            .test(
                'passLength',
                t(`screens:portalUsers.portalPassword`, {
                    value: config?.Accounts.CreditAccMinServicePasswdLength,
                }),
                function (value) {
                    if (
                        value !== undefined &&
                        config?.Accounts.CreditAccMinServicePasswdLength
                    ) {
                        return (
                            value.length >=
                            Number(
                                config?.Accounts
                                    .CreditAccMinServicePasswdLength,
                            )
                        );
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                t('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, { createError, path }) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                          path,
                          message: t('errors:portalUsers.activationDate', {
                              value: convertedValue,
                          }),
                      });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, { createError, path }) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                          path,
                          message: t('errors:portalUsers.activationDate', {
                              value: convertedValue,
                          }),
                      });
            })
            .test(
                'dobY',
                t('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                              ctx.parent.activationDate,
                              'YYYY-MM-DD',
                          ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    });

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
    } = useFormik<CreateNewPortalUserFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(
                actions.saveNewPortalUser.request({
                    login: form.login,
                    password: form.password,
                    email: form.email,
                    i_role:
                        roleList?.find((e) => e.name === form.role)?.i_role ||
                        0,
                    activation_date: form.activationDate,
                    expiration_date: form.expirationDate,
                    i_time_zone: timeZonesList.find(
                        (e) => e.time_zone_name === form.timezone,
                    )?.i_time_zone,
                    lang: languagesList?.find((e) => e.name === form.language)
                        ?.iso_639_1,
                    callback: toggleVisibility,
                }),
            );
        },
        enableReinitialize: true,
        validationSchema: CreateNewPortalUserValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        if (isOpen) {
            dispatch(actions.getAddPortalUserData.request());
        }
    }, [isOpen]);

    useEffect(() => {
        if (
            apiErrors?.faultstring.includes(PortalUsersErrors.PortalUserExist)
        ) {
            setFieldError(
                'login',
                t('errors:portalUsers.loginExists', { value: values.login }),
            );
        }
    }, [apiErrors]);

    const handleCancel = () => {
        toggleVisibility?.();
        resetForm();
    };
    const isSaveDisabled = useMemo(
        () => !values.login || !values.password || !values.role,
        [values],
    );

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="sip-dialog"
            header={t('screens:portalUsers.addNewPortalUser')}
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={handleCancel}
                />,
                <DialogButton
                    className={classNames(
                        !dirty || isSaveDisabled
                            ? classes.primaryActionButton
                            : classes.saveButton,
                    )}
                    disabled={!dirty || isSaveDisabled}
                    key="save"
                    label={t('common:save')}
                    onClick={handleSubmit}
                />,
            ]}
        >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="create-extension-form"
            >
                <Grid
                    item
                    className={classNames(
                        classes.itemsContainer,
                        classes.marginNone,
                    )}
                >
                    <Box className={classes.headerBox} width={350}>
                        <span className={classes.rowBoxHeader}>
                            {t('screens:myCompany.general')}
                        </span>

                        <IconWithTooltip
                            dataQa="my-company-address"
                            tooltipText={t('tooltips:myCompany.general')}
                        />
                    </Box>
                    <Box className={classes.rowBox}>
                        <TextField
                            id="login"
                            label={t('common:login')}
                            onChange={handleChange}
                            value={values.login}
                            setFieldError={setFieldError}
                            dataQa="create-portal-user-login"
                            helperText={errors?.login}
                            required
                            maxLength={64}
                        />
                        <PasswordTextField
                            id="password"
                            label={t('common:password')}
                            value={values.password}
                            onChange={handleChange}
                            dataQa="create-extension-name-input"
                            setFieldError={setFieldError}
                            error={errors.password}
                            isRequired={true}
                            passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                                config?.Web,
                            )}
                            setFieldValue={setFieldValue}
                            maxLength={32}
                            inputProps={{
                                inputProps: {
                                    min:
                                        config?.Accounts
                                            .CreditAccMinServicePasswdLength ||
                                        0,
                                    maxLength: 32,
                                },
                            }}
                        />
                    </Box>
                    <Box className={classes.rowBox}>
                        <TextField
                            id="email"
                            label={t('common:emailAddress')}
                            onChange={handleChange}
                            value={values.email}
                            setFieldError={setFieldError}
                            dataQa="create-portal-user-email"
                            helperText={errors?.email}
                            maxLength={128}
                        />

                        <SelectField
                            id="role"
                            onChange={(e, v) => setFieldValue('role', v)}
                            label={t('screens:portalUsers.role')}
                            items={
                                roleList
                                    ?.filter((e) => e.name !== 'Any')
                                    ?.map((v) => v.name) || []
                            }
                            value={values.role}
                            dataQa="create-portal-user-role"
                            icon={
                                roleList?.find((e) => e.name === values.role)
                                    ?.description ? (
                                    <IconWithTooltip
                                        dataQa="my-company-address"
                                        tooltipText={
                                            roleList?.find(
                                                (e) => e.name === values.role,
                                            )?.description || ''
                                        }
                                    />
                                ) : undefined
                            }
                            helperText={errors?.role}
                            required
                            setFieldError={setFieldError}
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    className={classNames(
                        classes.itemsContainer,
                        classes.marginBottom,
                    )}
                >
                    <Box className={classes.headerBox} width={350}>
                        <span className={classes.rowBoxHeader}>
                            {t('screens:portalUsers.changeStatus')}
                        </span>

                        <IconWithTooltip
                            dataQa="my-company-address"
                            tooltipText={t('tooltips:myCompany.changeStatus')}
                        />
                    </Box>
                    <Box
                        className={classNames(
                            classes.rowBox,
                            classes.smallerMargin,
                        )}
                    >
                        <CustomKeyboardDateTimePicker
                            id="activationDate"
                            label={t('screens:portalUsers.activationDate')}
                            value={values.activationDate}
                            onChange={(v) => setFieldValue('activationDate', v)}
                            dataQa={'portal-user-activation-date'}
                            dataTestId={'portal-user-activation-date'}
                            minDate={new Date()}
                            withHours={false}
                            allowEmptyDate={true}
                            userDateTimeFormat={userDateFormat}
                            setFieldError={setFieldError}
                            helperText={errors?.activationDate}
                        />
                        <CustomKeyboardDateTimePicker
                            id="expirationDate"
                            label={t('screens:portalUsers.expirationDate')}
                            value={values.expirationDate}
                            onChange={(v) => setFieldValue('expirationDate', v)}
                            dataQa={'portal-user-expieration-date'}
                            dataTestId={
                                'portal-user-activexpierationation-date'
                            }
                            withHours={false}
                            allowEmptyDate={true}
                            minDate={today}
                            userDateTimeFormat={userDateFormat}
                            setFieldError={setFieldError}
                            helperText={errors?.expirationDate}
                        />
                    </Box>
                    <Box className={classes.rowBox}>
                        <SelectField
                            id="timezone"
                            onChange={(e, v) => setFieldValue('timezone', v)}
                            label={t('common:timezone')}
                            items={
                                timeZonesList?.map((v) => v.time_zone_name) ||
                                []
                            }
                            value={values.timezone || null}
                            dataQa="create-portal-user-timezone"
                        />

                        <SelectField
                            id="language"
                            onChange={(e, v) => setFieldValue('language', v)}
                            label={t('common:language')}
                            items={languagesList?.map((v) => v.name) || []}
                            value={values.language || null}
                            dataQa="create-portal-user-language"
                        />
                    </Box>
                </Grid>
            </form>
            {(isFormSaving || isAddPortalUserDataLoading) && (
                <Loader
                    dataQa="create-auto-attendant-data-fetching-loader"
                    absolutePosition
                />
            )}
        </DialogContainer>
    );
};

export default CreateNewPortalUserDialog;
