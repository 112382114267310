import React from 'react';
import { makeStyles } from '@material-ui/styles';
//@ts-ignore
import AnimatedNumber from 'animated-number-react';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 18,
        color: Colors.Text2,
        fontWeight: 500,
        display: 'inline',
    },
}));

export type AnimatedNumberProps = {
    value: number;
    hideThousands?: boolean;
    className?: string;
    dataTestId?: string;
    dataQa?: string;
};

const AnimatedNumberText: React.VFC<AnimatedNumberProps> = ({
    value,
    hideThousands,
    className,
    dataTestId,
    dataQa,
}) => {
    const classes = useStyles();

    const isKNotation = hideThousands && value > 999;

    return (
        <Fade in={true} timeout={2000}>
            <div
                className={classNames(classes.text, className)}
                data-testid={dataTestId}
                data-qa={dataQa}
            >
                <AnimatedNumber
                    value={isKNotation ? value / 1000 : value}
                    formatValue={(v: number) => v.toFixed(isKNotation ? 1 : 0)}
                />
                {isKNotation && <span>K</span>}
            </div>
        </Fade>
    );
};

export default AnimatedNumberText;
