import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Loader from '../../components/Loader/Loader';
import { getTabNumber } from '../../utils/getTabNumber';
import usePageTitle from '../../hooks/usePageTitle';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import BillingHeader from './BillingHeader/BillingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import { useStyles } from './BillingDetails.utils';
import InvoiceTab from './Invoices/InvoiceTab';
import TransactionTab from './Transactions/TransactionTab';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import { useBillingFormData } from '../../hooks/useBillingFormData';
import {
    AutoPaymentsForm,
    autoPaymentsFormValidationSchemaGenerator,
} from './PaymentMethod/PaymentMethod.utils';
import { generateTabs } from '../../utils/generateTabs';
import { Permission } from '../../store/types/Permission';
import { useRawPermissions } from '../../hooks/usePermissions';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';

const BillingDetails: React.VFC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permissions = useRawPermissions();

    usePageTitle();

    const classes = useStyles();

    const { initialValues, data } = useBillingFormData();

    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));

    const handleTabindexChange = (
        event: React.ChangeEvent<{}>,
        index: number,
    ) => setTabIndex(index);

    const { isLoading, isFormLoading } = useSelector(
        (state: ReduxState) => state.billing,
    );

    useEffect(() => {
        dispatch(actions.getBasicBillingInfo.request());
    }, []);

    const { tabNames, tabs } = generateTabs(
        [
            {
                title: t('screens:billing.transactions'),
                permission: Permission.MyCompany.Billing.Transactions.value,
                tab: <TransactionTab />,
            },
            {
                title: t('screens:billing.invoices'),
                permission: Permission.MyCompany.Billing.Invoices.value,
                tab: <InvoiceTab />,
            },
            {
                title: t('screens:billing.paymentMethod'),
                permission: Permission.MyCompany.Billing.PaymentMethod.value,
                tab: <PaymentMethod />,
            },
        ],
        permissions,
    );

    if (isLoading) {
        return (
            <div className={classes.mainContainer}>
                <Loader dataQa="billing-loader" absolutePosition />
            </div>
        );
    }

    return (
        <Formik<AutoPaymentsForm>
            initialValues={initialValues}
            onSubmit={(form) => {
                dispatch(
                    actions.editAutoPayments.request({
                        changedValues: form,
                        initialValues,
                        redirectTab: tabIndex,
                    }),
                );
            }}
            enableReinitialize={true}
            validationSchema={autoPaymentsFormValidationSchemaGenerator(
                data.currentMethod?.min_allowed_payment || 0,
            )}
            validateOnChange={false}
        >
            {({ submitForm, dirty }) => (
                <PermissionPlaceholder
                    permission={Permission.MyCompany.Billing.value}
                >
                    <DetailsWrapper
                        tabs={tabs}
                        tabsNames={tabNames}
                        top={<BillingHeader />}
                        hideCancel
                        showActionButtons={tabIndex === 2}
                        saveEnable={dirty}
                        preventIsOpen={dirty}
                        defaultTabIndex={tabIndex}
                        onTabChange={handleTabindexChange}
                        onSavePress={submitForm}
                    >
                        {isFormLoading && (
                            <Loader
                                dataQa="edit-auto-attendant-sending-loader"
                                absolutePosition
                            />
                        )}
                    </DetailsWrapper>
                </PermissionPlaceholder>
            )}
        </Formik>
    );
};

export default BillingDetails;
