import React from 'react';
import CustomizedTooltip from './Tooltip';
import { Info } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { greyColor3 } from '../../styles/Colors';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';

export const useStyles = makeStyles(() => ({
    iconContainer: {
        '&:hover': {
            color: Colors.Gray5,
        },
    },
    disabled: {
        opacity: 0.7,
        pointerEvents: 'none',

        '&:hover': {
            pointerEvents: 'none',
        },
    },
    smallIcon: {
        height: 20.5,
        width: 20.5,
    },
}));

type IconWithTooltipProps = {
    IconComponent?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    dataQa?: string;
    tooltipText: string;
    iconColor?: string;
    className?: string;
    copy?: boolean;
    disabled?: boolean;
    type?: 'standard' | 'small';
    onClick?: () => void;
};

const IconWithTooltip: React.VFC<IconWithTooltipProps> = ({
    tooltipText,
    dataQa,
    IconComponent = Info,
    iconColor = greyColor3,
    className,
    copy = false,
    disabled = false,
    type = 'standard',
    onClick = undefined
}) => {
    const classes = useStyles();

    return (
        <CustomizedTooltip
            className={className}
            dataQa={dataQa}
            dataTestId={dataQa}
            title={tooltipText}
            copy={copy}
            interactive
        >
            <IconComponent
                classes={{
                    root: classNames(
                        classes.iconContainer,
                        type === 'small' && classes.smallIcon,
                        disabled ? classes.disabled : '',
                    ),
                }}
                htmlColor={iconColor}
                onClick={() => {
                    if(onClick) {
                        onClick();
                    }
                }}
            />
        </CustomizedTooltip>
    );
};

export default IconWithTooltip;
