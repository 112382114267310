import React from 'react';
import { useToaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import {
    createStyles,
    IconButton,
    makeStyles,
    SnackbarContent,
} from '@material-ui/core';
import Button from '../Button/Button';
import { Close } from '@material-ui/icons';
import { Colors, white } from '../../styles/Colors';
import CustomizedIconButton from '../IconButton/IconButton';

const useStyles = makeStyles(() =>
    createStyles({
        toasterContainer: {
            position: 'absolute',
            maxWidth: 550,
            zIndex: 2000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
            top: 20,
        },
        toast: {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-start',
            marginTop: 10,
            backgroundColor: '#121212',

            '& .MuiSnackbarContent-message': {
                marginTop: 2,
            },
        },
        close: {
            marginTop: 3,
        },
    }),
);

const Toaster: React.VFC = () => {
    const { visibleToasts, handlers } = useToaster();
    const { startPause, endPause } = handlers;

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div
            className={classes.toasterContainer}
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            data-qa={'toaster'}
            data-testid={'toaster'}
        >
            {visibleToasts.map((visibleToast) => (
                <SnackbarContent
                    key={visibleToast.id}
                    message={visibleToast.message}
                    className={classes.toast}
                    action={
                        <>
                            <CustomizedIconButton
                                dataQa="close-snackbar-button"
                                dataTestId="close-snackbar-button"
                                onClick={() => toast.dismiss(visibleToast.id)}
                                backgroundWithOpacity
                            >
                                <Close htmlColor={Colors.White} />
                            </CustomizedIconButton>
                        </>
                    }
                    data-qa="snackbar-toast"
                />
            ))}
        </div>
    );
};

export default Toaster;
