import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import { DefaultAnsweringActionFlag } from '../../../../store/types/ServiceFeature';

import { useStyles, IncomingFormProps, IncomingFormType } from './utils';
import EmailOptionSelect from '../../../EmailOptionSelect/EmailOptionSelect';
import FaxFormatSelect from '../../../FaxFormatSelect/FaxFormatSelect';
import Separator from '../../../Separator/Separator';

const IncomingForm: React.FC<IncomingFormProps> = ({
    handleSetIsValidFunc,
    handleSetSubmitFunc,
    handleDirtyChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
        handleSubmit,
        isValid,
    } = useFormikContext<IncomingFormType>();

    useEffect(() => {
        handleDirtyChange?.('incoming', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('incoming', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('incoming', () => isValid);
    }, [isValid]);

    const defaultAnsweringModes = useMemo(
        () =>
            Object.values(DefaultAnsweringActionFlag).map((v) => ({
                name: t(`enums:defaultAnsweringActionAutoAttendantEnum.${v}`),
                value: v,
            })),
        [],
    );

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.contentContainer}>
                <div className={classes.singleRow}>
                    <SelectField
                        label={t('screens:incoming.defaultAnsweringMode')}
                        items={defaultAnsweringModes}
                        value={defaultAnsweringModes.find(
                            (v) => v.value === values.defaultAnsweringMode,
                        )}
                        dataQa="incomingform-answering-mode-select"
                        onChange={(_, value) => {
                            setFieldValue('defaultAnsweringMode', value?.value);
                        }}
                        getOptionLabel={(v: { name: string; value: string }) =>
                            v.name
                        }
                        getOptionSelected={(v: {
                            name: string;
                            value: string;
                        }) => v.value === values.defaultAnsweringMode}
                        classes={{ container: classes.customContainer }}
                        disableClearable
                    />

                    <TextField
                        id="defaultAnsweringTimeout"
                        label={t('screens:incoming.timeout')}
                        onChange={handleChange}
                        value={values.defaultAnsweringTimeout.toString()}
                        type="number"
                        className={classes.numberInput}
                        dataQa="incoming-timeout"
                        inputProps={{
                            inputProps: {
                                min: 1,
                                max: 999,
                            },
                            pattern: '[0-9]*',
                            startAdornment: (
                                <span className={classes.minText}>sec</span>
                            ),
                        }}
                        widthFromLiableText
                    />
                </div>
            </Grid>
            <Separator />
            <Grid item className={classes.faxContentContainer}>
                <SwitchWithLabel
                    label={t('screens:incoming.faxMailbox')}
                    setValue={setValue}
                    value={values.faxMailboxSwitcher}
                    field={'faxMailboxSwitcher'}
                    id={'faxMailbox'}
                    disabled={values.faxMailboxLocked}
                    icon={
                        <ServiceIcon
                            type={Service.UnifiedMessagingFaxOnly}
                            dataQa="unified-messaging-fax-only-service"
                            dataTestId="unified-messaging-fax-only-service"
                        />
                    }
                />
                {values.faxMailboxSwitcher && (
                    <>
                        <div className={classes.row}>
                            <TextField
                                label={t(
                                    'screens:incoming.emailAddressIncoming',
                                )}
                                onChange={handleChange}
                                id={'emailAddressIncoming'}
                                value={values.emailAddressIncoming}
                                dataQa="incoming-emailAddressIncoming"
                                className={classes.emailAddressIncoming}
                                icon={
                                    <IconWithTooltip
                                        dataQa="incoming-emailAddressIncoming-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:incomings.emailAddressIncoming',
                                        )}
                                        copy={false}
                                    />
                                }
                                iconPosition={'end'}
                                helperText={
                                    errors.emailAddressIncoming &&
                                    t('errors:extensions.emailAddress')
                                }
                                setFieldError={setFieldError}
                                maxLength={128}
                            />

                            <EmailOptionSelect
                                value={values.emailOption}
                                label={t('screens:incoming.emailOption')}
                                copyTooltip={false}
                                tooltipMsg={t('tooltips:incomings.emailOption')}
                                dataQa="incoming-emailOption"
                                tooltipDataQa="incoming-emailOption-name-tooltip"
                                className={classes.emailOption}
                                classes={{ container: classes.emailOption }}
                                onChange={(_, value) => {
                                    setFieldValue(
                                        'emailOption',
                                        value.value,
                                        false,
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <FaxFormatSelect
                                label={t('screens:incoming.faxFormat')}
                                onChange={(e, value) =>
                                    setFieldValue('faxFormat', value.value)
                                }
                                value={values.faxFormat}
                                dataQa="incoming-faxFormat"
                                className={classes.faxFormat}
                                classes={{ container: classes.faxFormat }}
                                tooltipMsg={t('tooltips:incomings.faxFormat')}
                                copyTooltip={false}
                                tooltipDataQa="incoming-faxFormat-name-tooltip"
                            />
                        </div>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default IncomingForm;
