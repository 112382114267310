import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { Colors } from '../../styles/Colors';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import CustomizedTooltip from '../Tooltip/Tooltip';

type TextButtonWithTooltipProps = {
    onClick?: () => void;
    dataQa?: string;
    className?: string;
    text?: string;
    tooltip?: string;
};

const useStyles = makeStyles(() => ({
    idText: {
        color: Colors.Secondary1,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    disabled: {
        color: Colors.Secondary1,
        pointerEvents: 'none',
    },
    readOnly: {
        opacity: 0.5,
    },
}));

const TextButtonWithTooltip: React.VFC<TextButtonWithTooltipProps> = ({
    onClick,
    dataQa,
    className,
    text,
    tooltip,
}) => {
    const classes = useStyles();
    const permission = usePermissionContext();
    const { t } = useTranslation();

    return (
        <CustomizedTooltip
            title={t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission !== PermissionType.ReadOnly}
        >
            <div>
                <OverflowTooltip
                    text={text}
                    tooltip={
                        permission !== PermissionType.Visible
                            ? t('common:noPermissionToResource')
                            : tooltip
                    }
                    classes={{
                        text: classNames(
                            classes.idText,
                            permission === PermissionType.Hidden &&
                                classes.disabled,
                            permission === PermissionType.ReadOnly &&
                                classes.readOnly,
                            className,
                        ),
                    }}
                    onClickText={
                        permission === PermissionType.Visible
                            ? onClick
                            : undefined
                    }
                    data-qa={dataQa}
                />
            </div>
        </CustomizedTooltip>
    );
};

export default TextButtonWithTooltip;
