import React, { useEffect } from 'react';
import PasswordTextField from '../../PasswordTextField/PasswordTextField';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ApiLoginPayload } from '../../../store/services/auth-api';
import { Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import CustomButton from '../../Button/Button';
import { APIErrorInterface, ReduxState } from '../../../store/types';
import { getErrorsMsg } from './helpers';
import { initLoginFormData, useStyles, validate } from './LoginForm.utils';
import { useSelector } from 'react-redux';
import Loader from '../../Loader/Loader';

type LoginFormProps = {
    onSubmitAction: (payload: ApiLoginPayload) => void;
    onRecoveryClick: (login?: string) => void;
    validateOnChange: boolean;
    setSnackbarMessage: (text: string) => void;
    apiErrors?: APIErrorInterface;
    handleLoginChange?: (login: string) => void;
    passedLogin?: string;
};

const LoginForm: React.FC<LoginFormProps> = ({
    onSubmitAction,
    onRecoveryClick,
    setSnackbarMessage,
    validateOnChange = false,
    apiErrors,
    passedLogin,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const inProgress = useSelector<ReduxState>(
        (state) => state.auth.inProgress,
    );

    const formik = useFormik({
        initialValues: initLoginFormData,
        validate,
        validateOnChange: validateOnChange,
        onSubmit: (values) => {
            const payload = {
                login: values.login,
                password: values.password,
            };

            onSubmitAction(payload);
        },
        enableReinitialize: false,
    });

    const { values, setFieldValue } = formik;
    useEffect(() => {
        const hasErrors =
            Object.keys(formik.errors).length !== 0 ? true : false;
        if (hasErrors && (formik.values.login || formik.values.password)) {
            setSnackbarMessage(t(`errors:loginForm.${formik.errors.global}`));
        }
    }, [formik.errors.login, formik.errors.password]);

    const loginErrorMsg = getErrorsMsg(formik.errors.login, apiErrors);
    const passwordErrors = getErrorsMsg(formik.errors.password, apiErrors);
    const handleRecoveryClick = () => {
        onRecoveryClick(values.login);
    };

    useEffect(() => {
        passedLogin && setFieldValue('login', passedLogin);
    }, [passedLogin]);

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className={classes.inputs}>
                <Grid item>
                    <TextField
                        id="login"
                        dataQa="login-user-input"
                        label={t('screens:signIn.loginLabel')}
                        onChange={formik.handleChange}
                        value={formik.values.login.trim()}
                        helperText={
                            loginErrorMsg
                                ? t(`errors:loginForm.${loginErrorMsg}`)
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                        autoComplete={'username'}
                    />
                </Grid>
                <Grid item>
                    <PasswordTextField
                        id="password"
                        dataQa="login-password-input"
                        label={t('screens:signIn.passwordLabel')}
                        onChange={formik.handleChange}
                        value={formik.values.password.trim()}
                        error={
                            passwordErrors
                                ? t(`errors:loginForm.${passwordErrors}`)
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                        autoComplete={'current-password'}
                    />
                </Grid>
            </div>
            <div className={classes.action}>
                <div className={classes.recoveryButtonContainer}>
                    <CustomButton
                        dataQa="login-recovery-button"
                        onClick={handleRecoveryClick}
                        type="button"
                        className={classes.recoveryButton}
                    >
                        {t('screens:signIn.forgotPasswordButton')}
                    </CustomButton>
                </div>
                <CustomButton
                    dataQa="login-submit-button"
                    primary
                    type="submit"
                    className={classes.submitButton}
                >
                    {inProgress ? (
                        <Loader dataQa={'login-in-progress'} />
                    ) : (
                        t('screens:signIn.loginButton')
                    )}
                </CustomButton>
            </div>
        </form>
    );
};

export default LoginForm;
