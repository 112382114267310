import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/didNumbers';
import { GetCustomerDIDNumbersRequest } from '../../actions/didNumbers/payloads';
import { CustomerDIDNumberType } from '../../types/CustomerDIDNumber';
import { ExtensionType } from '../../types/Extension';

export type DidNumbersActionsType = ActionType<typeof actions>;

export interface DidNumbersStateType {
    items: CustomerDIDNumberType[] | null;
    total: number;
    allTotal?: number;
    loading: boolean;
    filters: null | GetCustomerDIDNumbersRequest;
    accountsToAssign: {
        isLoading: boolean;
        items?: ExtensionType[];
        total?: number;
        reassigned?: boolean;
    };
    numbers?: CustomerDIDNumberType[];
    assigned?: ExtensionType | undefined;
    isServicesLoading?: boolean;
}

export const initialState: DidNumbersStateType = {
    items: null,
    total: 0,
    allTotal: 0,
    loading: false,
    filters: { offset: 0 },
    accountsToAssign: {
        isLoading: false,
    },
    assigned: undefined,
    isServicesLoading: false,
};

const didNumbersReducer = createReducer<
    DidNumbersStateType,
    DidNumbersActionsType
>(initialState)
    .handleAction(actions.didNumbers.request, (state, action) => ({
        ...state,
        loading: true,
        filters: { ...state.filters, ...action.payload },
        accountsToAssign: {
            isLoading: false,
        },
    }))
    .handleAction(actions.didNumbers.success, (state, action) => ({
        ...state,
        items: action.payload.number_list,
        total: action.payload.total,
        allTotal: state.allTotal || action.payload.total,
        loading: false,
        isServicesLoading: true,
    }))
    .handleAction(actions.didNumberWithServices.success, (state, action) => ({
        ...state,
        items: action.payload.number_list.filter((item) =>
            state.items?.find(
                (storeItem) => storeItem.i_did_number == item.i_did_number,
            ),
        ),
        isServicesLoading: false,
    }))
    .handleAction(actions.didNumbers.failure, (state) => ({
        ...state,
        loading: false,
    }))
    .handleAction(actions.didAccountsToAssign.request, (state) => ({
        ...state,
        accountsToAssign: {
            isLoading: true,
            items: undefined,
            total: undefined,
        },
    }))
    .handleAction(actions.didAccountsToAssign.success, (state, actions) => {
        return {
            ...state,
            accountsToAssign: {
                isLoading: false,
                items: actions.payload.account_list,
                total: actions.payload.total,
            },
        };
    })
    .handleAction(actions.didAccountsToAssign.failure, (state) => ({
        ...state,
        accountsToAssign: {
            isLoading: false,
        },
    }))
    .handleAction(actions.assignDIDNumberToAccount.request, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: true,
        },
    }))
    .handleAction(actions.assignDIDNumberToAccount.success, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            reassigned: true,
            isLoading: false,
        },
    }))
    .handleAction(actions.assignDIDNumberToAccount.failure, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: false,
        },
    }))
    .handleAction(actions.cancelDIDNumberAssignet.request, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: true,
        },
    }))
    .handleAction(actions.cancelDIDNumberAssignet.success, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            reassigned: true,
            isLoading: false,
        },
    }))
    .handleAction(actions.cancelDIDNumberAssignet.failure, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: false,
        },
    }))
    .handleAction(actions.getAccountByIAccount.request, (state) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: true,
        },
        assigned: undefined,
    }))
    .handleAction(actions.getAccountByIAccount.success, (state, action) => ({
        ...state,
        accountsToAssign: {
            ...state.accountsToAssign,
            isLoading: false,
        },
        assigned: action.payload.assignedTo,
    }))
    .handleAction(actions.didNumbersForSelect.success, (state, action) => ({
        ...state,
        numbers: action.payload.numbers,
    }))

    .handleAction(actions.fetchAccountInformation.request, (state) => ({
        ...state,
        loading: true,
    }))
    .handleAction(actions.fetchAccountInformation.success, (state, action) => ({
        ...state,
        items: action.payload.items,
        loading: false,
    }))
    .handleAction(actions.getFilteredDidNumbers.request, (state, action) => ({
        ...state,
        loading: true,
        filters: action.payload,
    }))
    .handleAction(actions.deleteDIDNumber.request, (state) => ({
        ...state,
        loading: true,
    }))
    .handleAction(actions.deleteDIDNumber.success, (state) => ({
        ...state,
        loading: false,
    }))
    .handleAction(actions.deleteDIDNumber.failure, (state) => ({
        ...state,
        loading: false,
    }));

export default didNumbersReducer;
