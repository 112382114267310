import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { AccountFollowMeSettings } from '../../../types/AccountFollowMeSettings';

export interface CallForwardingReducerState {
    accountFollowMeSettings?: AccountFollowMeSettings;
}

export const initialState: CallForwardingReducerState = {};

export type CallForwardingReducerActions = ActionType<typeof actions>;

export const callForwadingReducer = createReducer<
    CallForwardingReducerState,
    CallForwardingReducerActions
>(initialState).handleAction(
    actions.getExtensionFollowMeSettings.success,
    (state, action) => ({
        ...state,
        accountFollowMeSettings: action.payload,
    }),
);

export default callForwadingReducer;
