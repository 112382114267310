import React, { useEffect, useState } from 'react';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import AsyncSelectField from '../AsyncSelectField/AsyncSelectField';
import JSONFormData from '../../utils/JSONFormData';
import { AxiosResponse } from 'axios';
import { api } from '../../store/services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';

import {
    ExtensionsListItem,
    SelectExtensionListItem,
} from '../../store/reducers/extensions/extensions/reducer';

import * as actions from '../../store/actions';
import Loader from '../Loader/Loader';
import { Customer } from '../../services/endpoints';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: 18,
        '& .MuiInputBase-root': {
            height: 59,
            marginTop: 0,
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 10px) scale(0.75)',
        },
        '& .MuiInputLabel-root': {
            marginLeft: 10,
        },
    },

    select: {
        width: '100%',
    },

    boxRow: {
        display: 'flex',
    },

    disabledButton: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
}));

const mapToName = (item: ExtensionsListItem): string => {
    const label = item.id;
    return item.name ? label + ' - ' + item.name : label;
};

const addLabel = (item: ExtensionsListItem): SelectExtensionListItem => {
    return { ...item, label: mapToName(item), value: item.id };
};

type EditDialogProps = {
    cancelClick?: () => void;
    i_ua?: number;
    extension_id?: string;
    account_id?: string;
    isOpen: boolean;
    dataQa?: string;
    header?: string;
    className?: string;
    dataTestId?: string;
    line?: number;
    toggleModal?: () => void;
};

const EditDeviceDialog: React.FC<EditDialogProps> = ({
    isOpen,
    cancelClick,
    i_ua,
    account_id,
    line,
    className,
    extension_id,
    toggleModal,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inputValue, onInputChangeRaw] = useState<string>('');
    const [value, setValue] = useState<SelectExtensionListItem | undefined>();

    const { session_id, csrf_token } = useSelector((state: ReduxState) => state.auth);
    const { extension, isExtensionLoading, isEditing } = useSelector(
        (state: ReduxState) => state.devices,
    );

    const onInputChange = (newInputValue: string) => {
        onInputChangeRaw(newInputValue);
    };

    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 20;

        const params = {
            extension: search ? `%${search}%` : undefined,
            detailed_info: '1',
            get_total: 1,
            limit,
            offset: prevOptions.length,
        };

        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
            total: number;
        }> = await api.post(Customer.GetExtensionsList, body);

        const items = res.data.extensions_list
            .map(addLabel)
            .filter((o) => !o.ip_phone_name);
        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };

    useEffect(() => {
        setValue(undefined);
        if (isOpen) {
            extension_id &&
                dispatch(
                    actions.getExtensionById.request({ id: extension_id }),
                );
        }
    }, [isOpen]);

    useEffect(() => {
        if (account_id && !extension_id) {
            setValue({
                label: t('common:reservedForId', { id: account_id }),
                value: account_id.toString(),
                id: account_id.toString(),
                account_id,
                i_account: 0,
            });
        }
    }, [account_id, isOpen]);

    useEffect(() => {
        if (extension) {
            setValue(addLabel(extension));
        }
    }, [extension]);

    const disabledSave =
        extension?.i_c_ext == value?.i_c_ext || !value?.i_account;

    return (
        <DialogContainer
            className={className}
            isOpen={isOpen}
            dataTestId={'edit-dialog'}
            header={t('screens:devices.editLine', {
                line: line,
            })}
            dialogActionsButtons={[
                <DialogButton
                    key="no"
                    label={t('common:cancel')}
                    onClick={() => {
                        cancelClick?.();
                        setValue(undefined);
                    }}
                />,
                <DialogButton
                    key="yes"
                    label={t('common:save')}
                    disabled={disabledSave}
                    primary           
                    onClick={() => {
                        if (line && i_ua && value?.i_c_ext) {
                            dispatch(
                                actions.assignDevice.request({
                                    port: line,
                                    i_cpe: i_ua,
                                    i_c_ext: value?.i_c_ext,
                                    onSuccess: toggleModal,
                                    withRelease: !!account_id,
                                    extension_id: extension_id,
                                }),
                            );
                        }
                    }}
                />,
            ]}
        >
            {isExtensionLoading || isEditing ? (
                <Loader dataQa={'wait-for-assigned-extension'} />
            ) : (
                <Grid className={classes.itemsContainer}>
                    <Box className={classes.boxRow}>
                        <AsyncSelectField
                            title={t('screens:extensions.extension')}
                            value={value}
                            loadOptions={loadOptions}
                            onChange={(value) => setValue(value)}
                            onInputChange={onInputChange}
                            cacheUniqs={[inputValue]}
                        />
                    </Box>
                </Grid>
            )}
        </DialogContainer>
    );
};

export default EditDeviceDialog;
