import { ActionType, createReducer } from 'typesafe-actions';

export type DidNProviderActionsType = ActionType<typeof actions>;
import * as actions from '../../actions/didProvider';

import {
    DIDAreaInfo,
    DIDCountryInfo,
    DIDNumberListInfo,
    DIDProviderInfo,
} from '../../types/DIDProvider';

export type DidProvidersActionsType = ActionType<typeof actions>;

export interface DidInventoryProvidersStateType {
    numbers: DIDNumberListInfo[] | null;
    areNumbersLoading?: boolean;
    country: DIDCountryInfo[] | null;
    isAreaLoading?: boolean;
    area: DIDAreaInfo[] | null;
    provider: DIDProviderInfo | null;
    isLoading: boolean;
    assigned: boolean;
}

export const initState: DidInventoryProvidersStateType = {
    numbers: null,
    country: null,
    area: null,
    provider: null,
    isLoading: false,
    assigned: false,
};

const didInventoryProvidersReducer = createReducer<
    DidInventoryProvidersStateType,
    DidProvidersActionsType
>(initState)
    .handleAction(actions.getDIDInventoryDetails.request, (state) => ({
        ...state,
        isLoading: true,
        assigned: false,
    }))
    .handleAction(actions.getDIDInventoryDetails.success, (state, action) => {
        return {
            ...state,
            isLoading: false,
            country: action.payload.countries,
            numbers: action.payload.numbers,
            provider: action.payload.provider.provider_info,
        };
    })
    .handleAction(actions.getDIDInventoryDetails.failure, (state, action) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.postProvisionNumber.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.postProvisionNumber.success, (state) => ({
        ...state,
        assigned: true,
    }))
    .handleAction(actions.postProvisionNumber.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.getDIDAreas.request, (state) => ({
        ...state,
        isAreaLoading: true,
    }))
    .handleAction(actions.getDIDAreas.success, (state, action) => ({
        ...state,
        area: action.payload,
        isAreaLoading: false,
    }))
    .handleAction(actions.getDidProviderNumbers.request, (state) => ({
        ...state,
        areNumbersLoading: true,
    }))
    .handleAction(actions.getDidProviderNumbers.success, (state, action) => ({
        ...state,
        numbers: action.payload,
        areNumbersLoading: false,
    }));

export default didInventoryProvidersReducer;
