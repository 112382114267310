import React, { useEffect, useState } from 'react';
import { Colors } from '../../styles/Colors';
import { Avatar } from './Avatar';
import * as crypto from 'crypto';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../history';

import { Routes } from '../../routes/routes';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import { useTranslation } from 'react-i18next';
import CustomizedIconButton from '../IconButton/IconButton';
import classNames from 'classnames';

type LogOutMenuItemProps = {
    onLogoutIconClick: () => void;
    sectionIcon: JSX.Element;
    showShadow?: boolean;
};

const LogoutMenuItem: React.VFC<LogOutMenuItemProps> = ({
    onLogoutIconClick,
    sectionIcon,
    showShadow,
}) => {
    const { t } = useTranslation();

    const [emailState, setEmailState] = useState<string | undefined>(undefined);
    const user = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.user || localStorage.getItem('user_login'),
    );
    const email = useSelector(
        (state: ReduxState) => state.generic.sessionData?.email,
    );

    const customerEmail = useSelector(
        (state: ReduxState) => state.myProfile.customer?.email,
    );

    const initialLoaded = useSelector(
        (state: ReduxState) => state.myProfile.isInitialLoaded,
    );

    useEffect(() => {
        if (customerEmail?.length) {
            setEmailState(customerEmail);
            localStorage.setItem('user_email', customerEmail);
        } else if (email?.length) {
            setEmailState(email);
            localStorage.setItem('user_email', email);
        } else if (localStorage.getItem('user_email')) {
            setEmailState(localStorage.getItem('user_email') || '');
        }
    }, [email, customerEmail]);

    const classes = useStyles();
    const emailMd5 =
        emailState === undefined
            ? undefined
            : crypto.createHash('md5').update(emailState).digest('hex');
    const handleAvatarOnClick = () => {
        history.push(Routes.MyProfile);
    };

    useEffect(() => {
        user &&
            !email &&
            (!customerEmail || !customerEmail.length) &&
            initialLoaded &&
            setEmailState('');
    }, [user, email, customerEmail, initialLoaded]);

    return (
        <div className={classNames(classes.root, showShadow && classes.shadow)}>
            <div className={classes.internal} onClick={handleAvatarOnClick}>
                <Avatar
                    name={user || ''}
                    emailMd5={emailMd5}
                    classes={classes.avatar}
                />
                <OverflowTooltip
                    classes={{
                        text: classes.login,
                    }}
                    tooltip={user}
                    text={user}
                    copy={false}
                    above={true}
                />
            </div>

            <CustomizedIconButton
                tooltipText={t('common:logOut')}
                dataQa="logout-button"
                dataTestId="logout-button"
                onClick={onLogoutIconClick}
                above
                backgroundWithOpacity
            >
                {sectionIcon}
            </CustomizedIconButton>
        </div>
    );
};

export default LogoutMenuItem;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 16px 2px 16px',
        color: Colors.White,
        fontSize: 16,
        fontWeight: 500,
        paddingTop: 8,
        paddingBottom: 8,
        cursor: 'pointer',
    },
    shadow: {
        boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.12)',
    },
    internal: {
        display: 'flex',
        alignItems: 'center',
    },
    login: { width: 100, maxWidth: 100, marginRight: 8 },
    avatar: {
        marginRight: 8,
        textTransform: 'uppercase',
        '& div': {
            fontSize: '28px !important',
            fontWeight: 600,
            lineHeight: '16px',
            color: Colors.Gray6,
        },
        '& img': {
            zIndex: 1,
        },
    },
}));
