import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../store';
import { ReduxState } from '../../../../store/types';
import {
    convertFormatFromBackendToDayJs,
    convertToUserFormat,
    convertUserLocalTimeToUtc,
} from '../../../../utils/dateWithTimezoneConversion';
import PaymentReceipt from '../../../Payments/PaymentReceipt/PaymentReceipt';
import PaymentStatusDialog from '../../../Payments/PaymentStatusDialog/PaymentStatusDialog';
import ProcessingDialog from '../../../Payments/ProcessingDialog/ProcessingDialog';
import MakePaymentDialog from '../MakePaymentDialog/MakePaymentDialog';

type PaymentHOCProps = {
    children: (
        setIsPaymentDialogVisible: (valu: boolean) => void,
    ) => React.ReactNode;
};

const PaymentHOC: React.FC<PaymentHOCProps> = ({ children }) => {
    const dispatch = useDispatch();

    const paymentIdParam = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).tr_id;

    const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);
    const [
        isPaymentStatusDialogVisible,
        setIsPaymentStatusDialogVisible,
    ] = useState(false);

    const { paymentDetails, isPaymentHasError } = useSelector(
        (state: ReduxState) => state.billing,
    );

    useEffect(() => {
        if (paymentDetails) {
            setIsPaymentDialogVisible(false);
            setIsPaymentStatusDialogVisible(true);
        }
    }, [paymentDetails]);

    useEffect(() => {
        if (paymentIdParam && typeof paymentIdParam === 'string') {
            dispatch(
                actions.getPaymentDetails.request({
                    payment_id: paymentIdParam,
                    withFinalization: true,
                }),
            );
        }
    }, [paymentIdParam]);

    useEffect(() => {
        if (isPaymentHasError) {
            setIsPaymentDialogVisible(false);
        }
    }, [isPaymentHasError]);

    return (
        <>
            {children(setIsPaymentDialogVisible)}

            <MakePaymentDialog
                isOpen={isPaymentDialogVisible}
                toggleVisibility={() =>
                    setIsPaymentDialogVisible(!isPaymentDialogVisible)
                }
            />

            <ProcessingDialog
                isOpen={isPaymentHasError || false}
                isError={isPaymentHasError}
                toggleVisibility={() => {
                    dispatch(actions.setPaymentError(false));
                    dispatch(actions.setPaymentProcessing(false));
                }}
            />

            {paymentDetails && (
                <>
                    <PaymentReceipt />

                    <PaymentStatusDialog
                        isOpen={isPaymentStatusDialogVisible}
                        toggleVisibility={() => {
                            setIsPaymentStatusDialogVisible(
                                !isPaymentStatusDialogVisible,
                            );
                            dispatch(
                                actions.getPaymentDetails.success(undefined),
                            );
                        }}
                    />
                </>
            )}
        </>
    );
};

export default PaymentHOC;
