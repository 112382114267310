import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import classNames from 'classnames';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../store';
import { ReduxState } from '../../../../store/types';
import { ExtensionsListItem } from '../../../../store/reducers/extensions/extensions/reducer';
import CustomizedChip from '../../../Chip/Chip';
import {
    ExtensionFormProps,
    ExtensionFormType,
    generateDisplayNumberList,
    useStyles,
} from './ExtensionForm.utils';
import RingGroupsGrid from '../../../Extensions/RingGroupsInExtension/RingGroupsGrid';
import { RingGroupType } from '../../../../store/types/RingGroup';
import PasswordTextField from '../../../PasswordTextField/PasswordTextField';
import { getConfigFromPasswordRulesOrDefault } from '../../../../utils/passwordGenerator';
import OnHoldMusic from '../../../OnHoldMusic/OnHoldMusic';

const ExtensionForm: React.FC<ExtensionFormProps> = ({
    didNumbers,
    accountId,
    timeZones,
    countries,
    webConfig,
    apiErrors,
    subdivisionsList,
    isBranchOffice,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    prefixEnabled,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const extension = useSelector<ReduxState, ExtensionsListItem | undefined>(
        (state) => state.extensions.extensionDetails,
    );

    const allRingGroups = useSelector<ReduxState, RingGroupType[] | undefined>(
        (state) => state.extensions.allRingGroups,
    );

    const countriesOptionsList = useMemo(
        () => [
            {
                iso_3166_1_a2: '',
                name: '',
            },
            ...(countries || []),
        ],
        [countries],
    );

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        handleSubmit,
        dirty,
        isValid,
    } = useFormikContext<ExtensionFormType>();

    useEffect(() => {
        handleDirtyChange?.('details', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('details', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('details', () => isValid);
    }, [isValid]);

    useEffect(() => {
        if (apiErrors?.faultstring === 'Invalid web password') {
            setFieldError('portalPassword', apiErrors.faultstring);
        }
    }, [apiErrors]);

    const renderDidTags = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    handleDelete={
                        v !== extension?.account_info?.did_number
                            ? () => {
                                  setFieldValue(
                                      'didNumber',
                                      values.didNumber.filter((w) => w !== v),
                                  );
                              }
                            : undefined
                    }
                />
            ));
        },
        [extension, values.didNumber],
    );

    const getSubdivisions = useCallback((iso_3166_1_a2?: string) => {
        if (iso_3166_1_a2) {
            dispatch(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2,
                }),
            );
        }
    }, []);

    const items = useMemo(() => {
        return (
            didNumbers
                ?.filter(
                    (v) =>
                        !v.i_account ||
                        v.i_account === accountId ||
                        v.i_master_account === accountId,
                )
                .map((v) => v.did_number) || []
        );
    }, [didNumbers]);

    const displayItems = useMemo(() => {
        return (
            didNumbers?.map((item) =>
                generateDisplayNumberList(
                    item.did_number,
                    values.displayNumber,
                    prefixEnabled || false,
                    '+',
                ),
            ) || []
        );
    }, [didNumbers]);

    useEffect(() => {
        if (
            apiErrors?.faultcode ===
                'Server.Customer.Ext_HG_number_already_in_use' ||
            apiErrors?.faultcode ===
                'Client.Customer.Ext_HG_number_already_in_use'
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:faxMailboxes.numberInUse'),
            );
        } else if (
            apiErrors?.faultcode === 'Server.Customer.duplicate_id' ||
            apiErrors?.faultcode === 'Client.Customer.duplicate_id'
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:faxMailboxes.alreadyExists'),
            );
        }
    }, [apiErrors]);

    return (
        <div className={classes.inputs}>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.topContainer,
                )}
            >
                <div className={classes.rowContainer}>
                    <TextField
                        id="extensionNumber"
                        label={t('screens:extensions.extensionNumber')}
                        onChange={handleChange}
                        value={values.extensionNumber}
                        icon={
                            <IconWithTooltip
                                dataQa="extension-number-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.extensionNumber',
                                )}
                            />
                        }
                        iconPosition="end"
                        dataQa="extension-number-input"
                        helperText={errors.extensionNumber}
                        setFieldError={setFieldError}
                        readOnly={isBranchOffice}
                        required
                        maxLength={5}
                    />

                    <TextField
                        id="extensionName"
                        label={t('common:name')}
                        onChange={handleChange}
                        value={values.extensionName}
                        icon={
                            <IconWithTooltip
                                dataQa="extension-name-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.extensionName',
                                )}
                            />
                        }
                        iconPosition="end"
                        dataQa="extension-name-input"
                        helperText={errors.extensionName}
                        setFieldError={setFieldError}
                        readOnly={isBranchOffice}
                        maxLength={32}
                    />
                </div>

                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.didNumber')}
                        onChange={(e, value) =>
                            setFieldValue('didNumber', value)
                        }
                        items={items}
                        value={values.didNumber}
                        multiple
                        icon={
                            <IconWithTooltip
                                dataQa="extension-did-numbers-tooltip"
                                tooltipText={t('tooltips:extensions.didNumber')}
                            />
                        }
                        dataQa="extension-didnumbers-input"
                        getOptionDisabled={(v) =>
                            v === extension?.account_info?.did_number
                        }
                        renderTags={renderDidTags}
                        disableClearable
                        disableAutoSettingValue
                    />

                    <SelectField
                        onChange={(e, value) =>
                            setFieldValue('displayNumber', value)
                        }
                        label={t('screens:extensions.displayNumber')}
                        items={displayItems}
                        value={values.displayNumber || null}
                        icon={
                            <IconWithTooltip
                                dataQa="extension-display-number-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.displayNumber',
                                )}
                            />
                        }
                        dataQa="extension-displaynumber-input"
                        disableAutoSettingValue
                    />
                </div>
            </Grid>

            <div className={classes.separator} />
            <Grid item className={classes.groupsContainer}>
                <RingGroupsGrid
                    ringGroups={values.extensionRingGroups}
                    allRingGroups={allRingGroups}
                    accountId={extension?.account_id}
                    extensionId={extension?.i_c_ext}
                    setFieldValue={setFieldValue}
                    extensionName={
                        extension?.name ||
                        t('screens:extensions.unnamedWithId', {
                            id: extension?.account_info?.extension_id,
                        })
                    }
                />
            </Grid>

            {(!isBranchOffice || values.office) && (
                <>
                    <div className={classes.separator} />

                    <Grid item className={classes.itemsContainer}>
                        {!isBranchOffice && (
                            <>
                                <div className={classes.rowContainer}>
                                    <TextField
                                        id="emailAddress"
                                        label={t(
                                            'screens:extensions.emailAddress',
                                        )}
                                        onChange={handleChange}
                                        value={values.emailAddress}
                                        icon={
                                            <IconWithTooltip
                                                dataQa="extension-email-tooltip"
                                                tooltipText={t(
                                                    'tooltips:extensions.emailAddress',
                                                )}
                                            />
                                        }
                                        iconPosition="end"
                                        dataQa="extension-email-input"
                                        helperText={
                                            !!errors.emailAddress
                                                ? t(
                                                      `errors:extensions.emailAddress`,
                                                  )
                                                : ''
                                        }
                                        setFieldError={setFieldError}
                                        maxLength={128}
                                    />

                                    <SelectField
                                        label={t('screens:extensions.timezone')}
                                        items={
                                            timeZones?.map(
                                                (v) => v.time_zone_name,
                                            ) || []
                                        }
                                        onChange={(_, value) =>
                                            setFieldValue('timezone', value)
                                        }
                                        value={values.timezone || null}
                                        dataQa="extension-timezone-input"
                                        helperText={errors.timezone || ''}
                                        setFieldError={setFieldError}
                                        disableClearable
                                    />
                                </div>

                                <div className={classes.rowContainer}>
                                    <TextField
                                        id="portalLogin"
                                        label={t(
                                            'screens:extensions.portalLogin',
                                        )}
                                        onChange={handleChange}
                                        value={values.portalLogin}
                                        icon={
                                            <IconWithTooltip
                                                dataQa="extension-portal-login-tooltip"
                                                tooltipText={t(
                                                    'tooltips:extensions.portalLogin',
                                                )}
                                            />
                                        }
                                        iconPosition="end"
                                        dataQa="extension-login-input"
                                        helperText={errors.portalLogin || ''}
                                        setFieldError={setFieldError}
                                        maxLength={128}
                                        required={
                                            !!values.portalPassword &&
                                            !values.portalLogin
                                        }
                                    />
                                    <PasswordTextField
                                        id="portalPassword"
                                        label={t('screens:extensions.password')}
                                        onChange={handleChange}
                                        value={values.portalPassword}
                                        dataQa="extension-password-input"
                                        setFieldError={setFieldError}
                                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                                            webConfig,
                                        )}
                                        iconDataQa="regenerate-password-button"
                                        error={errors.portalPassword || ''}
                                        setFieldValue={setFieldValue}
                                        maxLength={32}
                                        isRequired={!!values.portalLogin}
                                    />
                                </div>
                            </>
                        )}
                        {values.office && (
                            <div className={classes.rowContainer}>
                                <TextField
                                    label={t('screens:extensions.office')}
                                    value={values.office}
                                    readOnly
                                    dataQa="extension-office-input"
                                />
                                <div style={{ flex: 1 }} />
                            </div>
                        )}
                    </Grid>
                </>
            )}

            {!isBranchOffice && (
                <>
                    <Grid item className={classes.itemsContainer}>
                        <Box className={classes.emergencyHeaderContainer}>
                            <span className={classes.emergencyHeader}>
                                {t('screens:extensions.emergencyLocation')}
                            </span>

                            <IconWithTooltip
                                dataQa="extension-emergency-location-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.emergencyLocation',
                                )}
                            />
                        </Box>
                        <div className={classes.rowContainer}>
                            <SelectField
                                label={t('screens:extensions.country')}
                                items={
                                    countriesOptionsList?.map((v) => v.name) ||
                                    []
                                }
                                value={
                                    countriesOptionsList?.find(
                                        (v) =>
                                            v.iso_3166_1_a2 === values.country,
                                    )?.name || null
                                }
                                onChange={(_, value) => {
                                    const country = countriesOptionsList?.find(
                                        (v) => v.name === value,
                                    )?.iso_3166_1_a2;

                                    setFieldValue(
                                        'country',
                                        country || '',
                                        false,
                                    );
                                    setFieldValue('state', '');
                                    getSubdivisions(country);
                                }}
                                dataQa="extension-country-input"
                            />

                            <TextField
                                id="city"
                                label={t('screens:extensions.city')}
                                onChange={handleChange}
                                value={values.city}
                                dataQa="extension-city-input"
                                maxLength={31}
                            />
                        </div>
                        <div className={classes.rowContainer}>
                            <TextField
                                id="address"
                                label={t('screens:extensions.address')}
                                onChange={handleChange}
                                value={values.address}
                                dataQa="extension-address-input"
                                maxLength={41}
                            />

                            <TextField
                                id="postalCode"
                                label={t('screens:extensions.postalCode')}
                                onChange={handleChange}
                                value={values.postalCode}
                                dataQa="extension-postal-code-input"
                                maxLength={10}
                                helperText={errors.postalCode}
                                setFieldError={setFieldError}
                            />
                        </div>
                        <div className={classes.rowContainer}>
                            <SelectField
                                label={t('screens:extensions.provinceState')}
                                dataQa="extension-state-input"
                                items={
                                    subdivisionsList?.map((v) => v.name) || []
                                }
                                value={values.state || null}
                                onChange={(_, value) => {
                                    setFieldValue('state', value || null);
                                }}
                                disabled={!values.country}
                            />

                            <div style={{ flex: 1 }} />
                        </div>
                    </Grid>

                    <div className={classes.separator} />

                    <Grid
                        item
                        className={classNames(
                            classes.itemsContainer,
                            classes.includeContainer,
                        )}
                    >
                        <div
                            className={classNames(
                                classes.rowContainer,
                                classes.includeRowContainer,
                            )}
                        >
                            <OnHoldMusic
                                id="includeCorpDirectory"
                                fileSelectId="includeFileName"
                                setValue={setFieldValue}
                                value={values.includeCorpDirectory}
                                withTooltip={false}
                                mohItems={
                                    values.recordName
                                        ? [{ id: 1, name: values.recordName }]
                                        : undefined
                                }
                                selectedMohFile={values.recordName || ''}
                                onSave={(file, name, callback) => {
                                    setFieldValue('recordFile', file);
                                    setFieldValue('recordName', name);
                                    callback?.();
                                }}
                                customTooltip={t(
                                    'tooltips:extensions.includeInCorporateDirectory',
                                )}
                                disableSelectHide
                                customLabel={t(
                                    'screens:extensions.includeInCorpDirectory',
                                )}
                                customSelectLabel={t('common:name')}
                                classes={{
                                    switcher: classes.includeSwitcher,
                                    outsideContainer: classes.outsideContainer,
                                }}
                            />

                            <div style={{ flex: 1 }} />
                        </div>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default ExtensionForm;
