import React from 'react';
import { TimeWindowIntervalsDetails } from '../../store/types/CallScreening';
import TextField from '../TextField/TextField';
import { IconButton, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

type CallScreeningRowProps = {
    interval: TimeWindowIntervalsDetails;
    onDelete: () => void;
};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0 10px 0',

        '& .MuiFormControl-root': {
            marginRight: 10,
        },
    },
    removeIcon: {
        marginLeft: 3,
    },
}));

const ExistingIntervalRow: React.FC<CallScreeningRowProps> = ({
    interval,
    onDelete,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    return (
        <div className={classes.container}>
            <TextField
                id="interval-description"
                readOnly
                label={t('common:description')}
                value={
                    interval.description[0]?.toUpperCase() +
                    interval.description?.substring(1)
                }
            />

            {permission === PermissionType.Visible ? (
                <IconButton
                    onClick={onDelete}
                    data-testid="remove-interval-button"
                    data-qa="remove-interval-button"
                    className={classes.removeIcon}
                >
                    <Delete htmlColor={Colors.Gray5} />
                </IconButton>
            ) : (
                <CustomizedTooltip
                            copy={false}
                            title={t('common:noPermissionToResource')}
                            data-qa={`chip-disabled-tooltip`}
                            className={classes.removeIcon}
                        >
                    <Delete htmlColor={'#D7D9DA'} />
                </CustomizedTooltip>
            )}

        </div>
    );
};

export default ExistingIntervalRow;
