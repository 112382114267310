import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { CallRecordingFormType, useStyles } from './CallRecordingForm.utils';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { DialingRuleInfo } from '../../../../store/types/DialingRule';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import classNames from 'classnames';
import { ExtensionType } from '../../../../store/types/Extension';

type CallRecordingFormProps = {
    customClass?: { container?: string };
};

const CallRecordingForm: React.VFC<CallRecordingFormProps> = ({
    customClass,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const accountInfo = useSelector<ReduxState, ExtensionType | undefined>(
        (state) => state.extensions.extensionDetails?.account_info,
    );

    const callRecordingEmail = accountInfo?.email;

    const dialingRule = useSelector<ReduxState, DialingRuleInfo | undefined>(
        (state) => state.extensions.dialingRule,
    );

    const { values, setFieldValue } = useFormikContext<CallRecordingFormType>();

    return (
        <div className={classNames(classes.inputs, customClass?.container)}>
            <SwitchWithLabel
                className={classes.switchContainer}
                id="callRecordingStatus"
                field="callRecordingStatus"
                label={t('screens:extensions.callRecording')}
                value={values.callRecordingStatus}
                disabled={values.callRecordingBlocked}
                setValue={setFieldValue}
                icon={
                    <ServiceIcon
                        type={Service.CallRecording}
                        dataQa="call-recording-service"
                        dataTestId="call-recording-service"
                    />
                }
            />
            {values.callRecordingStatus && (
                <Grid item className={classes.itemsContainer}>
                    <CustomizedCheckbox
                        checked={values.outgoingStatus}
                        className={classes.fitContent}
                        label={t('screens:extensions.outgoing')}
                        dataQa="outgoingStatus"
                        onChange={() =>
                            setFieldValue(
                                'outgoingStatus',
                                !values.outgoingStatus,
                            )
                        }
                        disabled={values.callRecordingBlocked}
                    />
                    <CustomizedCheckbox
                        checked={values.incomingStatus}
                        className={classes.fitContent}
                        label={t('screens:extensions.incoming')}
                        dataQa="incomingStatus"
                        onChange={() =>
                            setFieldValue(
                                'incomingStatus',
                                !values.incomingStatus,
                            )
                        }
                        disabled={values.callRecordingBlocked}
                    />
                    <CustomizedCheckbox
                        checked={values.redirectedStatus}
                        className={classes.fitContent}
                        label={t('screens:extensions.redirected')}
                        dataQa="redirectedStatus"
                        onChange={() =>
                            setFieldValue(
                                'redirectedStatus',
                                !values.redirectedStatus,
                            )
                        }
                        disabled={values.callRecordingBlocked}
                    />
                    <div className={classes.separator} />

                    <CustomizedCheckbox
                        className={classes.fitContent}
                        checked={
                            values.playCallRecordingAnnouncementToAllStatus
                        }
                        label={t(
                            'screens:extensions.playTheCallRecordingAnnouncementToAll',
                        )}
                        dataQa="playCallRecordingAnnouncementToAllStatus"
                        onChange={() =>
                            setFieldValue(
                                'playCallRecordingAnnouncementToAllStatus',
                                !values.playCallRecordingAnnouncementToAllStatus,
                            )
                        }
                        disabled={values.callRecordingBlocked}
                    />

                    <CustomizedTooltip
                        title={t('screens:calls.toEnableOptionSetEmail')}
                        copy={false}
                        disableHoverListener={!!callRecordingEmail}
                    >
                        <div>
                            <CustomizedCheckbox
                                className={classes.fitContent}
                                checked={
                                    values.sendTheCallRecordingViaEmailStatus
                                }
                                label={
                                    <span>
                                        {t(
                                            'screens:extensions.sendTheCallRecordingViaEmail',
                                        )}{' '}
                                        <b>{callRecordingEmail}</b>
                                    </span>
                                }
                                dataQa="sendTheCallRecordingViaEmailStatus"
                                onChange={() =>
                                    setFieldValue(
                                        'sendTheCallRecordingViaEmailStatus',
                                        !values.sendTheCallRecordingViaEmailStatus,
                                    )
                                }
                                disabled={
                                    !callRecordingEmail ||
                                    values.callRecordingBlocked
                                }
                            />
                        </div>
                    </CustomizedTooltip>

                    <CustomizedCheckbox
                        checked={values.allowToStartStopRecordingManually}
                        className={classes.fitContent}
                        label={t(
                            'screens:extensions.allowToStartStopRecordingManually',
                            {
                                codes: dialingRule?.dial_codes
                                    ? `(${dialingRule?.dial_codes?.recording_on} / ${dialingRule?.dial_codes?.recording_off})`
                                    : undefined,
                            },
                        )}
                        dataQa="allowToStartStopRecordingManually"
                        onChange={() =>
                            setFieldValue(
                                'allowToStartStopRecordingManually',
                                !values.allowToStartStopRecordingManually,
                            )
                        }
                        disabled={
                            !dialingRule?.dial_codes ||
                            values.callRecordingBlocked
                        }
                    />
                </Grid>
            )}
        </div>
    );
};

export default CallRecordingForm;
