import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import DevicesFilters from './DevicesFilters';
import { ReduxState } from '../../store/types';
import * as actions from '../../store/actions';
import { DeviceFilters, UAInfo, UAUsage } from '../../store/types/Devices';
import { PaginationMode } from '../../components/DataGrid/types';
import DataGrid from '../../components/DataGrid/DataGrid';
import { addId, generateColumns, useStyles } from './DevicesList.utils';
import { macPayloadFormatter } from '../../utils/macAdressFormater';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission} from "../../store/types/Permission";

const DevicesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState<DeviceFilters>({});
    usePageTitle();

    const handleFiltersChange = (
        extension_id?: string,
        name?: string,
        mac?: string,
        inventory_id?: string,
        usage?: UAUsage | string,
    ) => {
        setFilters({
            extension_id: extension_id?.length
                ? `%${extension_id}%`
                : undefined,
            name: name?.length ? `%${name}%` : undefined,
            mac: mac?.length ? macPayloadFormatter(mac) : undefined,
            inventory_id: inventory_id?.length
                ? `%${inventory_id}%`
                : undefined,
            usage: usage ? usage : undefined,
        });
    };

    const handleSubmit = () => {
        fetchData({ pageSize: pageSize, page: 0 });
    };

    const {
        items = [],
        isLoading = false,
        total = 0,
        active = 0,
        allTotal = 0,
    } = useSelector((state: ReduxState) => state.devices);

    const fetchData = (params?: { pageSize: number; page: number }) => {
        dispatch(
            actions.devicesList.request({
                ...filters,
                limit: params?.pageSize || pageSize,
                offset: params ? params.page * params.pageSize : 0,
                with_available_ports: 1,
                with_busy_ports: 1,
                get_total: 1,
            }),
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(() => generateColumns(t), []);

    return (
        <PermissionPlaceholder permission={Permission.Inventory.Devices.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.content}>
                        <Header
                            title={t('screens:devices.listTitle')}
                            totalNumber={allTotal || 0}
                            ofNumber={active}
                            dataQa={'device-list-view-header'}
                            customClass={classes.header}
                        />

                        <DevicesFilters
                            handleChange={handleFiltersChange}
                            onSubmit={handleSubmit}
                        />

                        <DataGrid<UAInfo>
                            columns={columns}
                            data={items.map(addId)}
                            className={classes.table}
                            rowCount={total}
                            loading={isLoading}
                            onPageChange={(params) => {
                                fetchData({
                                    pageSize: params.pageSize,
                                    page: params.page,
                                });
                                setPage(params.page);
                            }}
                            onPageSizeChange={(params) => {
                                fetchData({ pageSize: params.pageSize, page: 0 });
                                setPageSize(params.pageSize);
                            }}
                            paginationMode={PaginationMode.Server}
                            centeredRows
                            narrowRows
                            initialPageSize={10}
                        />
                    </div>
                </div>
            </div>
        </PermissionPlaceholder>

    );
};

export default DevicesList;
