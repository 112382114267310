import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { useSelector } from 'react-redux';

import PasswordTextField from '../../../PasswordTextField/PasswordTextField';
import { ReduxState } from '../../../../store/types';
import { useStyles } from './utils';
import { PasswordComplexityRules } from '../../../../store/types/ConfigData';
import PasswordRulesList from '../../../PasswordRulesList/PasswordRulesList';
import { getConfigFromPasswordRulesOrDefault } from '../../../../utils/passwordGenerator';

export type ChangePasswordFormType = {
    oldPassword: string;
    newPassword: string;
    confirmPassword?: string;
};

export type ChangePasswordFormProps = {
    rules?: {
        PasswordComplexityRules: PasswordComplexityRules[];
        PasswordMinLength: number;
    };
    handleAddValidation?: (newValue: boolean) => void;
};

export const changePasswordFormDefaultValues: ChangePasswordFormType = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const ChangePasswordForm: React.VFC<ChangePasswordFormProps> = ({
    rules,
    handleAddValidation,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [passwordRuleFollowed, setPasswordRuleFollowed] = useState({
        letters: false,
        numbers: false,
        symbols: false,
        length: false,
        uppercaseLetters: false,
        lowercaseLetters: false,
    });

    const changePasswordErrors = useSelector<
        ReduxState,
        { [key in string]: string } | undefined
    >((state) => state.company.changePasswordErrors);

    const minPasswordLength = useSelector<ReduxState, number | undefined>(
        (state) => state.generic.configData?.Web.PasswordMinLength,
    );

    const {
        values,
        handleChange,
        errors,
        setFieldError,
        setFieldValue,
    } = useFormikContext<ChangePasswordFormType>();

    const { oldPassword, newPassword } = values;

    useEffect(() => {
        handleAddValidation?.(!(!newPassword.length && !oldPassword.length));
    }, [newPassword, oldPassword]);

    useEffect(() => {
        if (changePasswordErrors?.old_password) {
            setFieldError('oldPassword', changePasswordErrors.old_password);
        }
    }, [changePasswordErrors]);

    const setNewPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        refreshPasswordRules(e.target.value);
        setFieldValue('newPassword', e.target.value);
    };

    const refreshPasswordRules = (value: string) => {
        const newPasswordRuleState = {
            letters: !!value.length && /[a-zA-Z]/g.test(value),
            uppercaseLetters: !!value.length && /[A-Z]/g.test(value),
            lowercaseLetters: !!value.length && /[a-z]/g.test(value),
            numbers: !!value.length && /\d/.test(value),
            symbols:
                !!value.length &&
                /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value),
            length: value.length >= (minPasswordLength ?? 6),
        };

        setPasswordRuleFollowed(newPasswordRuleState);
    };

    return (
        <div className={classes.inputs} data-qa="change-password-container">
            <Grid item className={classes.itemsContainer}>
                <Grid item xs={6} className={classes.inputsContainer}>
                    <PasswordTextField
                        id="oldPassword"
                        label={t('screens:myCompany.oldPassword')}
                        value={values.oldPassword}
                        onChange={handleChange}
                        dataQa="old-password-input"
                        setFieldError={setFieldError}
                        error={errors.oldPassword}
                        isRequired={true}
                        maxLength={32}
                    />
                    <PasswordTextField
                        id="newPassword"
                        label={t('screens:myCompany.newPassword')}
                        value={values.newPassword}
                        onChange={setNewPassword}
                        dataQa="new-password-input"
                        setFieldError={setFieldError}
                        error={errors.newPassword}
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            rules,
                        )}
                        refreshPasswordRules={refreshPasswordRules}
                        maxLength={32}
                    />
                </Grid>
                <Grid item xs={6} className={classes.descriptionContainer}>
                    <PasswordRulesList
                        passwordRuleFollowed={passwordRuleFollowed}
                        rules={rules}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangePasswordForm;
