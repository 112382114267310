import React, { useMemo, useCallback, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import DialogContainer, {
    DialogButton,
} from '../../components/AlertDialog/DialogContainer';
import { useFormik } from 'formik';
import IconWithTooltip from '../../components/Tooltip/IconWithTooltip';
import TextField from '../../components/TextField/TextField';
import { useTranslation } from 'react-i18next';
import SelectField from '../../components/SelectField/SelectField';
import { ReduxState, APIErrorInterface } from '../../store/types';
import {
    useStyles,
    CreateNewRingGroupFormData,
    ringGroupFormValidationSchema,
} from './createUtils';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { actions } from '../../store';
import Loader from '../../components/Loader/Loader';
import { CallerID, RingStrategy } from '../../store/types/RingGroup';
import SwitchWithLabel from '../../components/SwitchWithLabel/SwitchWithLabel';
import Tooltip from '@material-ui/core/Tooltip';

interface CreateNewPortalUserDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
}

const CreateNewRingGroupDialog: React.VFC<CreateNewPortalUserDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups.errors,
    );

    const isFormSending = useSelector<ReduxState, boolean>(
        (state) => !!state.ringgroups.isFormSending,
    );

    const initialValues = useMemo(
        () => ({
            name: '',
            number: '',
            callerId: CallerID.KeepOriginal,
            ringStrategy: RingStrategy.Order,
            callRecording: false,
            callPickupAllowed: true,
        }),
        [],
    );
    const callerIds = Object.values(CallerID).map((v) => ({
        name: t(`enums:callerId.${v}`),
        value: v,
    }));

    const ringStrategies = Object.values(RingStrategy).map((v) => ({
        name: t(`enums:ringStrategy.${v}`),
        value: v,
    }));

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
    } = useFormik<CreateNewRingGroupFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(
                actions.createNewRingGroup.request({
                    form,
                    callback: toggleVisibility,
                }),
            );
        },
        enableReinitialize: true,
        validationSchema: ringGroupFormValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleCancel = () => {
        toggleVisibility?.();
        resetForm();
    };

    const generateSwitcherWithProperState = useCallback(
        (switcher: JSX.Element, withTooltip: boolean) => {
            if (withTooltip) {
                const title = t('tooltips:incomings.locked');
                return (
                    <Tooltip placement="top-start" title={title}>
                        <div>{switcher}</div>
                    </Tooltip>
                );
            }
            return switcher;
        },
        [values],
    );

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    useEffect(() => {
        if (apiErrors?.faultcode === 'Server.Customer.HG_name_already_in_use') {
            setFieldError('name', t('errors:ringGroups.nameInUse'));
        }
        if (
            apiErrors?.faultcode ===
            'Server.Customer.Ext_HG_number_already_in_use'
        ) {
            setFieldError('number', t('errors:ringGroups.extHGNumberInUse'));
        }
    }, [apiErrors]);

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="sip-dialog"
            header={t('screens:ringGroups.addNewRingGroup')}
            headerClass={classes.header}
            className={classes.itemsContainer}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={handleCancel}
                />,
                <DialogButton
                    disabled={!dirty || !values.name || !values.number}
                    key="save"
                    label={t('common:save')}
                    onClick={handleSubmit}
                    primary
                />,
            ]}
        >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="create-extension-form"
            >
                <Grid item className={classes.itemsContainer}>
                    <Box className={classes.rowBox}>
                        <TextField
                            id="name"
                            label={t('common:name')}
                            onChange={handleChange}
                            value={values.name}
                            setFieldError={setFieldError}
                            dataQa="create-portal-user-name"
                            helperText={errors?.name}
                            required
                            warningIcon={false}
                            iconPosition={'end'}
                            icon={
                                <IconWithTooltip
                                    dataQa="ring-group-name-tooltip"
                                    tooltipText={t('tooltips:ringGroups.name')}
                                />
                            }
                            maxLength={32}
                        />
                    </Box>
                    <Box className={classes.rowBox}>
                        <TextField
                            id="number"
                            label={t('common:number')}
                            onChange={handleChange}
                            value={values.number}
                            setFieldError={setFieldError}
                            dataQa="create-portal-user-number"
                            helperText={errors?.number}
                            required
                            warningIcon={false}
                            iconPosition={'end'}
                            icon={
                                <IconWithTooltip
                                    dataQa="ring-group-number-tooltip"
                                    tooltipText={t(
                                        'tooltips:ringGroups.number',
                                    )}
                                />
                            }
                            maxLength={5}
                        />
                    </Box>

                    <Box className={classes.rowBox}>
                        <SelectField
                            label={t('screens:ringGroups.callerID')}
                            onChange={(e, value) =>
                                value?.value &&
                                setFieldValue('callerId', value.value)
                            }
                            items={callerIds}
                            value={callerIds.find(
                                (v) => v.value === values.callerId,
                            )}
                            required
                            dataQa="ring-group-callerid-select"
                            getOptionLabel={(v: {
                                name: string;
                                value: string;
                            }) => v.name}
                            getOptionSelected={(v: {
                                name: string;
                                value: string;
                            }) => v.value === values.callerId}
                            disableClearable
                        />
                    </Box>
                    <Box className={classes.rowBox}>
                        <SelectField
                            label={t('screens:ringGroups.ringStrategy')}
                            onChange={(e, value) =>
                                value?.value &&
                                setFieldValue('ringStrategy', value.value)
                            }
                            items={ringStrategies}
                            value={ringStrategies.find(
                                (v) => v.value === values.ringStrategy,
                            )}
                            required
                            dataQa="ring-group-callerid-select"
                            getOptionLabel={(v: {
                                name: string;
                                value: string;
                            }) => v.name}
                            getOptionSelected={(v: {
                                name: string;
                                value: string;
                            }) => v.value === values.ringStrategy}
                            disableClearable
                        />
                    </Box>
                    <Box
                        className={classNames(
                            classes.rowBox,
                            classes.callRecording,
                        )}
                    >
                        {generateSwitcherWithProperState(
                            <SwitchWithLabel
                                label={t(
                                    'screens:ringGroups.callPickupAllowed',
                                )}
                                setValue={setValue}
                                value={values.callRecording}
                                field={'callRecording'}
                                id={'callRecording'}
                                icon={
                                    <IconWithTooltip
                                        dataQa="ring-group-callerid-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.callPickupAllowed',
                                        )}
                                    />
                                }
                            />,
                            false,
                        )}
                    </Box>
                </Grid>
            </form>
            {isFormSending && (
                <Loader
                    dataQa="create-auto-attendant-data-fetching-loader"
                    absolutePosition
                />
            )}
        </DialogContainer>
    );
};

export default CreateNewRingGroupDialog;
