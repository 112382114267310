import React, {useEffect, useState} from 'react';
import DeviceTitle from '../../components/Devices/DeviceTitle';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import DeviceInfo from '../../components/Devices/DeviceInfo';
import history from '../../history';
import PortsConfigList from '../../components/Devices/PortsConfigList';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {UAInfo} from '../../store/types/Devices';
import Loader from '../../components/Loader/Loader';
import {actions} from '../../store';
import {Routes} from '../../routes/routes';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission} from "../../store/types/Permission";

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
    },

    scrollable: {
        flex: 1,
        overflowY: 'auto',
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: 10,
    },

    deviceHeader: {
        padding: `50px 0px 56px 96px`,
        marginBottom: 32,
        backgroundColor: Colors.White,
    },

    contentDevice: {
        '& .description': {
            width: '100%',
        },
    },
    deviceInfo: {
        maxWidth: 1040,
        padding: 0,
        marginBottom: 32,
    },

    devicePorts: {
        maxWidth: 1040,
        display: 'flex',
        marginBottom: 40,
    },

    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '@media (max-width: 1276px)': {
        deviceHeader: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        contentDevice: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        deviceHeader: {
            paddingLeft: 90,
            paddingRight: 90,
        },
        contentDevice: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },

    '@media (min-width: 1441px)': {
        deviceHeader: {
            paddingLeft: 96,
            paddingRight: 96,
        },
        contentDevice: {
            paddingLeft: 96,
            paddingRight: 96,
        },
    },
}));

type DeviceDetailsProps = {
    id: number;
};

const DeviceDetails: React.FC<DeviceDetailsProps> = ({id}) => {
    const {items, isLoading} = useSelector(
        (state: ReduxState) => state.devices,
    );
    const [device, setDevice] = useState<UAInfo | undefined>();
    const dispatch = useDispatch();
    usePageTitle();

    useEffect(() => {
        if (id) {
            const selected = items?.find((o) => o.i_ua == id);
            selected && setDevice(selected);

            if (selected == undefined) {
                dispatch(
                    actions.devicesList.request({
                        i_ua: id,
                        with_available_ports: 1,
                        with_busy_ports: 1,
                        get_total: 1,
                        offset: 0,
                    }),
                );
            }
        }
    }, [id, items]);

    const classes = useStyles();

    const onChevronClick = () => {
        history.push(Routes.Devices);
    };

    return device == undefined ? (
        <PermissionPlaceholder permission={Permission.Inventory.Devices.DeviceDetails.value}>
            <div className={classes.loader}>
                <Loader dataQa={'device-details-loader'}/>
            </div>
        </PermissionPlaceholder>

    ) : (
        <PermissionPlaceholder permission={Permission.Inventory.Devices.DeviceDetails.value}>

            <div className={classes.root}>
                <div className={classes.scrollable}>
                    <DeviceTitle
                        device={device}
                        className={classes.deviceHeader}
                        onChevronClick={onChevronClick}
                    />
                    <div className={classes.contentDevice}>
                        <DeviceInfo
                            className={classes.deviceInfo}
                            device={device}
                        />
                        <PortsConfigList
                            device={device}
                            className={classes.devicePorts}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </PermissionPlaceholder>

    );
};

export default DeviceDetails;
