import React, { useEffect, useRef, useState } from 'react';
import TransactionFilters, {
    TransactionFilterDateModes,
    TransactionFiltersFormType,
} from '../../../components/TransactionFilters/TransactionFilters';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from '../../../services/customDayJs';
import {
    generateColumns,
    getFilterPayload,
    TransactionTableRow,
    useStyles,
} from './TransactionList.utils';
import { actions } from '../../../store';
import { tansactionEntityTransformer } from '../../../utils/transformers';
import { useTransactionTabdata } from '../../../hooks/useTransactionTabdata';
import toast from 'react-hot-toast';
import TransactionDialog from '../../../components/TransactionDialog/TransactionDialog';
import { getDefaultDatePreferences } from './utils/DateUtils';
import { generateTabs } from '../../../utils/generateTabs';
import { Permission } from '../../../store/types/Permission';
import DetailsWrapper from '../../../components/DetailsWraper/DetailsWrapper';
import { CloudDownload } from '@material-ui/icons';
import { Colors } from '../../../styles/Colors';
import TransactionChart from './TransactionChart';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import { useRawPermissions } from '../../../hooks/usePermissions';

const TransactionsList = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        userDateTimeFormat,
        timezoneOffset,
        tansactionsList,
        currency,
        isLoading,
    } = useTransactionTabdata();

    const permissions = useRawPermissions();

    const [items, setItems] = useState<{
        data: TransactionTableRow[];
        total: number;
    }>({ data: [], total: 0 });

    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );

    const [filters, setFilters] = useState<TransactionFiltersFormType>({
        fromDate: getDefaultDatePreferences(new Date()).currentMonthStartDate,
        toDate: getDefaultDatePreferences(new Date()).currentMonthEndDate,
        dateMode: TransactionFilterDateModes.ThisMonth
    });

    const [details, setDetails] = useState<{
        serviceName: string;
        isOpen: boolean;
        index?: number;
        i_service?: number;
        i_service_type?: number;
        ratio?: number;
        unit?: string;
    }>({
        isOpen: false,
        serviceName: 'Unknown',
    });

    const handleDetails = (
        serviceName: string,
        i_service: number,
        i_service_type: number,
        index: number,
        ratio?: number,
        unit?: string,
    ) => {
        setDetails((prevState) => ({
            serviceName: serviceName,
            isOpen: !prevState.isOpen,
            index,
            i_service,
            i_service_type,
            ratio,
            unit: unit,
            initData: undefined,
            filters: undefined
        }));
    };

    const toogleDetailsVisibility = () => {
        setDetails((prevState) => ({
            ...prevState,
            isOpen: !prevState.isOpen,
        }));

        setTimeout(() => {
            setDetails((prevState) => ({
                ...prevState,
                index: undefined,
                i_service: undefined,
                i_service_type: undefined,
                ratio: undefined,
                unit: undefined,
                initData: undefined,
                filters: undefined
            }));
        }, 300);
    };

    const fetchData = () => {
        dispatch(
            actions.getTransactionList.request({
                ...getFilterPayload(filters, timezoneOffset),
            }),
        );
    };

    const toogleTooltipVisibility = (index: number) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = false;
        setIsDownloadingGoingOn(newDownloadings);
    };

    const downloadFile = (
        filters: TransactionFiltersFormType,
        timezoneOffset: number,
        index = 0,
    ) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = true;
        setIsDownloadingGoingOn(newDownloadings);
        toast(t<string>('common:downloadWillStartShortly'));
        dispatch(
            actions.getTransactionCsvFile.request({
                ...getFilterPayload(filters, timezoneOffset),
                i_service: filters?.i_service,
                fileName: 'transactions-' + dayjs().unix(),
                callback: () => toogleTooltipVisibility(index),
            }),
        );
    };

    const prepareTotalObj = (data: TransactionTableRow[], isSpendings: boolean): TransactionTableRow => {
        const totals = data.filter((e) => e.isSpending === isSpendings).reduce((a, b) => a + (b.total || 0), 0);
        const amount = data.filter((e) => e.isSpending === isSpendings).reduce((a, b) => a + (Math.abs(b.amount) || 0), 0);
        const totalObj: TransactionTableRow = {
            isSummary: true,
            total: totals,
            type: t('screens:myCompany.total'),
            charged: 0,
            amount: amount,
            id: 'total' + (isSpendings ? '1' : '2'),
            unit: '',
            unitShort: '',
            isSpending: isSpendings
        };
        totalObj.amount = Number(totalObj.amount.toFixed(2));
        return totalObj;
    }

    useEffect(() => {
        fetchData();
    }, [filters.fromDate, filters.toDate]);

    useEffect(() => {
        if (tansactionsList) {
            const { items } = tansactionsList;

            const data = items.map(
                tansactionEntityTransformer,
            ) as TransactionTableRow[];

            const summarySpending = prepareTotalObj(data, true);
            const summaryIncomings = prepareTotalObj(data, false);

            data.push(summarySpending);
            data.push(summaryIncomings);
            
            setItems({
                total: data.length || 0,
                data: data,
            });
        }
    }, [tansactionsList]);

    const columnsSpendings = generateColumns(t, true, currency, classes, handleDetails);
    const columnsIncomings = generateColumns(t, false, currency, classes, handleDetails);

    const spendingsData = items.data.filter(e => e.isSpending);
    const incomingsData = items.data.filter(e => !e.isSpending);
    
    const {tabNames, tabs} = generateTabs(
        [
            {
                title: t('screens:billing.charges'),
                permission:
                Permission.MyCompany.Billing.Transactions.value,
                tab: (
                    <TransactionChart columns={columnsSpendings}
                        inputdata={spendingsData}
                        currency={currency}
                        dataQa={'transactions-table-1'}
                        isLoading={isLoading || false}>
                    </TransactionChart>
                ),
                skipReadOnly: true
            },
            {
                title: t('screens:billing.paymentsAndCredits'),
                permission:
                Permission.MyCompany.Billing.Transactions.value,
                tab: (
                    <TransactionChart columns={columnsIncomings}
                        inputdata={incomingsData}
                        currency={currency}
                        dataQa={'transactions-table-2'}
                        isLoading={isLoading || false}>
                    </TransactionChart>
                ),
                skipReadOnly: true
            }
        ],
        permissions
    );
    
    const DownloadIcon = (
        <CloudDownload
            htmlColor={Colors.Secondary2}
            style={{ height: 22, width: 22, marginRight: 6, marginLeft: -3 }}
        />
    );

    const e = document?.forms[0]?.parentNode as HTMLElement;
    if(e) {
        e.setAttribute('style', 'width: 0; height: 0;');
    }
    const d = document.getElementsByClassName('MuiBox-root');
    if(d?.length) {
        for(const d1 of d) {
            d1.setAttribute('style', 'padding-bottom: 0');
        }
    }

    return (
        <div style={{ minWidth: 1002 }} id="transactionList">
            <TransactionFilters
                userDateTimeFormat={userDateTimeFormat}
                initData={filters}
                onChange={(values: TransactionFiltersFormType) => {
                    setFilters(values);
                }}
                onClick={fetchData}
            />
                <DetailsWrapper
                        tabs={tabs}
                        tabsNames={tabNames}
                        hideCancel
                        callFlowOnFirstTab
                        showActionButtons={false}
                        saveEnable={false}
                        preventIsOpen={false}
                        defaultTabIndex={0}
                        subTabMode={true}
                        doNotControlForms
                        customRightSideComponent={(
                            <div>
                                <CustomizedTooltip
                                    title={t('tooltips:billing:downloadCsvButtonTooltip')}
                                    copy={false}
                                    disableHoverListener={false}
                                >
                                    <Button
                                        data-qa={'download-csv-button'}
                                        data-testid={'download-csv-button'}
                                        key='download-key'
                                        type="button"
                                        onClick={() => {
                                            downloadFile(filters, timezoneOffset || 0);
                                        }}
                                        className={classes.downloadButton}
                                    >
                                        {DownloadIcon}
                                        {t('screens:billing:downloadCsvButtonLabel')}
                                    </Button>
                                </CustomizedTooltip>
                            </div>
                        )}
                    >
                </DetailsWrapper>
            <TransactionDialog
                serviceName={details.serviceName}
                isOpen={details.isOpen}
                initData={filters}
                toggleVisibility={toogleDetailsVisibility}
                userDateTimeFormat={userDateTimeFormat}
                index={details.index}
                i_service={details.i_service}
                i_service_type={details.i_service_type}
                onClickCsvButton={downloadFile}
                currency={currency}
                initialPageSize={10}
                ratio={details.ratio}
                unit={details.unit}
                timezoneOffset={timezoneOffset}
            />
        </div>
    );
};

export default TransactionsList;
