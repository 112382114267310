import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import { TFunction } from 'react-i18next';
import { CloudDownload, Assignment } from '@material-ui/icons';
import * as Yup from 'yup';
import dayjs from '../../../services/customDayJs';
import { CallHistory } from '../../../store/types/CallHistory';
import { Column } from 'react-table';
import { prepareCallRecordName } from '../../../utils/calls/prepareCallRecordName';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import CallerInfo from '../../../components/Calls/CallerInfo';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import i18n from '../../../services/i18n';
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";
import {Permission} from "../../../store/types/Permission";
import {roundUp} from "../../../utils/roundUp";

export type CallHistoryFormProps = {
    from: string;
    till: string;
    cli: string;
    cld: string;
    unsuccessfulCalls: boolean;
};
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const dateTest = Yup.string()
    .required(i18n.t('errors:ringGroups.emptyInput'))
    .test('is-valid-date', i18n.t('errors:ringGroups.wrongFormat'), (v) =>
        dayjs(new Date(v || '')).isValid(),
    );

export const callHistoryFiltersValidationSchema = Yup.object().shape({
    from: dateTest,
    till: dateTest,
});

export const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    tableContainer: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4) span': {
            marginLeft: -7,
        },
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 18,
        },

        '& .date-time-picker': {
            '& .MuiInputBase-input': {
                width: 208,
            },
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8,
    },
    table: {
        marginTop: 30,
    },
    pagination: {
        color: Colors.Gray,
        marginTop: 30,
    },
    centeredChargeValue: {
        paddingLeft: '0!important',
        marginLeft: 30,
    },
    centeredRecordingValue: {
        paddingLeft: '0!important',
        marginLeft: 20,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 700,
        color: Colors.Gray7,
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    checkbox: {
        padding: '5px 0 23px 0',
        marginTop: 15,
        display: 'flex',
        alignItems: 'center',
    },
    recordingColumn: {
        display: 'flex',
        marginLeft: -20,
    },
    cloud: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.LightGraySecondary3,
        marginLeft: 20,
        minWidth: 30,
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    chargeUSD: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: 130,
    },
    download: {
        marginLeft: 20,
        color: `${Colors.PlaceHolder} !important`,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    customerCurrency: string,
    decimalDigits: number,
    downloadFile: (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => void,
    setCallRecordInformation: React.Dispatch<
        React.SetStateAction<CallHistory | undefined>
    >,
    userDateTimeFormat?: string,
    isDownloadingGoingOn?: boolean[],
    isDialogOpened?: boolean,
): Column<CallHistory>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 1.3,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },
        {
            accessor: 'CLI',
            Header: t<string>('screens:calls.caller'),
            width: 1,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLI!} />;
            },
        },
        {
            accessor: 'CLD',
            Header: t<string>('screens:calls.destination'),
            width: 1.75,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLD!} />;
            },
        },
        {
            accessor: 'charged_quantity',
            Header: t<string>('screens:calls.duration'),
            width: 0.8,
            Cell: function Cell(params) {
                return (
                    <span>
                        {new Date(params.row.original.charged_quantity! * 1000)
                            .toISOString()
                            .substr(11, 8)}
                    </span>
                );
            },
        },
        {
            accessor: 'charged_amount',
            Header:
                t('screens:calls.charge') +
                (customerCurrency ? `, ${customerCurrency}` : ''),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <span className={classes.chargeUSD}>
                        {' '}
                        {roundUp(
                            params.row.original.charged_amount || 0,
                            2,
                        ).toFixed(decimalDigits)}
                    </span>
                );
            },
        },
        {
            accessor: 'cr_download_ids',
            Header: t<string>('common:actions'),
            maxWidth: 100,
            minWidth: 100,
            width: 1,
            Cell: function Cell(params) {
                const number = params.row.original.i_xdr as number;
                const downloadId = params.row.original.cr_download_ids?.[0];
                const index = params.row.index;
                const row = params.row.original;

                return (
                    <div className={classes.recordingColumn}>
                        <PermissionProvider permission={Permission.Calls.History.RecentCalls.CallDetailRecord.value} skipReadOnly>
                            <CustomizedIconButton
                                tooltipText={t(
                                    'tooltips:callSettings.viewCallDetailRecord',
                                )}
                                dataQa="details-icon-button"
                                dataTestId="details-icon-button"
                                onClick={() =>
                                    setCallRecordInformation(params.row.original)
                                }
                            >
                                <Assignment />
                            </CustomizedIconButton>
                        </PermissionProvider>


                        {downloadId && (
                            <PermissionProvider permission={Permission.Calls.History.RecentCalls.DownloadCallRecording.value} skipReadOnly>
                                <CustomizedIconButton
                                    tooltipText={t(
                                        'tooltips:ringGroups.downloadRecording',
                                    )}
                                    dataQa="detail-download-icon"
                                    dataTestId="download-icon"
                                    onClick={() =>
                                        downloadFile(
                                            number,
                                            downloadId,
                                            prepareCallRecordName(
                                                row,
                                                timezoneOffset,
                                            ),
                                            index,
                                        )
                                    }
                                    disabled={
                                        !(
                                            downloadId &&
                                            !isDownloadingGoingOn?.[
                                                params.row.index
                                                ]
                                        )
                                    }
                                >
                                    <CloudDownload />
                                </CustomizedIconButton>
                            </PermissionProvider>

                        )}
                    </div>
                );
            },
        },
    ];
};
