import React, { useEffect } from 'react';
import { useTabStyles } from '../../Extensions/ExtensionDetails/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import MenuDragForm from '../../../components/Forms/AutoAttendants/MenuDragForm';
import Loader from '../../../components/Loader/Loader';
import { Formik } from 'formik';
import { IvrFlowFormType } from '../../../components/Forms/AutoAttendants/IvrFlowDiagram/IvrFlowDiagram';
import { useAutoAttendantMenuFormTab } from '../../../hooks/useAutoAttendantMenuFormTab';
import { TabInterface } from '../../../components/Tabs/Tabs.utils';

const CallFlowTab: React.VFC<TabInterface> = ({
    handleSubmitForm,
    handleInitDataChange,
    handleDirtyChange,
    handleSetSubmitFunc,
}) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.callFlowTab?.isLoading ||
            false,
    );

    const { initValues } = useAutoAttendantMenuFormTab();

    useEffect(() => {
        if (!isDataLoading && initValues) {
            handleInitDataChange?.(initValues);
            handleSubmitForm?.(initValues);
        }
    }, [isDataLoading, initValues]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-flow-details-loader" />
        </div>
    ) : (
        <Formik<IvrFlowFormType>
            initialValues={initValues}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={handleSubmitForm!}
        >
            <MenuDragForm
                handleDirtyChange={handleDirtyChange}
                handleSetSubmitFunc={handleSetSubmitFunc}
            />
        </Formik>
    );
};

export default CallFlowTab;
