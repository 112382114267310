import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    errors: {
        '& .MuiFormLabel-root .MuiFormLabel-filled': {
            color: Colors.Error,
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        marginBottom: 44,
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },

    disabled: {
        backgroundColor: Colors.Gray11 + " !important",

        '& span': {
            color: Colors.Gray10 + '!important',
        },
    },

    action: {
        display: 'flex',
        justifyContent: 'space-between',

        '& button:first-child': {
            fontSize: 16,
            fontWeight: 700,
            width: '100%',
            height: 56,
            padding: 0,
            '& span': {
                color: Colors.White,
            },
        },
    },
}));
