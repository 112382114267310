import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import { actions } from '../../../store';
import { useTranslation } from 'react-i18next';
import { ActiveCall } from '../../../store/types/CallHistory';
import { PaginationMode } from '../../../components/DataGrid/types';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { useStyles, generateColumns } from './ActiveCalls.utils';
import ActiveCallsInfo from '../../../components/Calls/ActiveCallsInfo';

export const ActiveCalls: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { items, total, isLoading } = useSelector<
        ReduxState,
        { items: ActiveCall[]; total: number; isLoading: boolean }
    >((state) => state.calls.activeCalls);

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    useEffect(() => {
        dispatch(actions.getActiveCalls.request());
    }, []);

    const columns = generateColumns(
        t,
        classes,
        timezoneOffset,
        userDateTimeFormat,
    );

    return (
        <>
            <ActiveCallsInfo />
            <DataGrid<ActiveCall>
                columns={columns}
                data={items?.map((v, index) => ({
                    ...v,
                    id: index.toString(),
                }))}
                rowCount={total || 0}
                loading={isLoading}
                paginationMode={PaginationMode.Client}
                centeredRows
                narrowRows
                customRowHeight={63}
                initialPageSize={10}
                classes={{
                    tableContainer: classes.tableContainer,
                }}
            />
        </>
    );
};
