import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { CustomerAgreementCondition } from '../../store/types/CustomerAgreementCondition';
import { useStyles } from './utils';
import DoughnutSection from '../../components/Dashboard/DoughnutSection';
import history from '../../history';
import CallsSection from '../../components/Dashboard/CallsSection';
import { useMemo, useState } from 'react';
import dayjs from '../../services/customDayJs';
import {
    convertFormatFromBackendToDayJs,
    convertToUserFormat,
} from '../../utils/dateWithTimezoneConversion';
import ProductsSection from '../../components/Dashboard/ProductsSection';
import { ReactComponent as ExtensionIcon } from './../../assets/entities/extension.svg';
import { ReactComponent as RingGroupIcon } from './../../assets/entities/ringGroup.svg';
import { ReactComponent as FaxMailboxIcon } from './../../assets/entities/faxMailbox.svg';
import { ReactComponent as AutoAttendantIcon } from './../../assets/entities/autoAttendant.svg';
import { ReactComponent as PortalUserIcon } from './../../assets/entities/portalUser.svg';
import PanelButton from '../../components/Dashboard/PanelButton';
import CreateNewExtensionDialog from '../../components/Extensions/CreateNewExtensionDialog';
import CreateNewRingGroupDialog from '../RingGroups/CreateNewRingGroupDialog';
import AddNewFaxMailboxesDialog from '../FaxMailboxes/CreateNewDialog/AddNewFaxMailboxesDialog';
import CreateNewAutoAttendantDialog from '../../components/AutoAttendants/CreateNewAutoAttendant/Dialog';
import CreateNewPortalUserDialog from '../PortalUsers/CreateNewPortalUserDialog';
import { Routes } from '../../routes/routes';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import { Permission, PermissionType, PortalPermission } from '../../store/types/Permission';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';
import classNames from 'classnames';

export const Dashboard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const [addExtensionDialogVisible, setAddExtensionDialogVisible] = useState(
        false,
    );
    const [addRingGroupDialogVisible, setAddRingGroupDialogVisible] = useState(
        false,
    );
    const [
        addFaxMailboxDialogVisible,
        setAddFaxMailboxDialogVisible,
    ] = useState(false);
    const [
        addAutoAttendantDialogVisible,
        setAddAutoAttendantDialogVisible,
    ] = useState(false);
    const [
        addPortalUserDialogVisible,
        setAddPortalUserDialogVisible,
    ] = useState(false);

    React.useEffect(() => {
        dispatch(actions.getDashboardData());
    }, []);

    const registeredExtensions = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.registeredExtensions,
    );
    const unregisteredExtensions = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.unRegisteredExtensions,
    );
    const activeCalls = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.activeCalls,
    );
    const callsFromLastDays = useSelector<
        ReduxState,
        { date: string; value: number }[] | undefined
    >((state) => state.dashboard.callsFromLastDays);

    const inUseDidNumbers = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.inUseDidNumbers,
    );
    const notInUseDidNumbers = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.notInUseDidNumbers,
    );
    const inUseDevices = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.inUseDevices,
    );
    const notInUseDevices = useSelector<ReduxState, number | undefined>(
        (state) => state.dashboard.notInUseDevices,
    );
    const permissions = useSelector<ReduxState, PortalPermission | undefined>(
        (state) => state.permissions?.permissions,
    );

    const mainProducts = useSelector<
        ReduxState,
        CustomerAgreementCondition[] | undefined
    >((state) => state.dashboard.mainProducts);

    const addonProducts = useSelector<
        ReduxState,
        CustomerAgreementCondition[] | undefined
    >((state) => state.dashboard.addonProducts);

    const outDateFormat = useSelector<ReduxState, string | undefined>(
        (state) => state.generic.sessionData?.out_date_format,
    );

    const callsChartData = useMemo(
        () =>
            (callsFromLastDays || Array(8).fill(0)).map((v, index) => ({
                label: callsFromLastDays
                    ? dayjs(v.date).format('MMM DD')
                    : dayjs(v.date)
                          .subtract(7 - index, 'day')
                          .format('MMM DD'),
                value: v.value || 0,
                color: index === 7 ? Colors.Primary : Colors.Secondary13,
                tooltip: `${v.value || 0} ${t(
                    'screens:dashboard.callsOn',
                )} ${convertToUserFormat(
                    v.date,
                    convertFormatFromBackendToDayJs(outDateFormat || ''),
                )}`,
            })),
        [callsFromLastDays, outDateFormat],
    );
    
    const isAllTheKeysHidden = useMemo(
        () => {
            const dashboardKey = Permission?.Dashboard?.value?.[0];
            if(!dashboardKey) {
                return false;
            }
            const dashboardSubComponents = permissions?.components[dashboardKey]?.components;
            if(!dashboardSubComponents) {
                return false;
            }
            for(const key of Object.keys(dashboardSubComponents)) {
                if(dashboardSubComponents[key]?.permission !== PermissionType.Hidden) {
                    return false;
                }
            }
            return true;
        },
        [permissions]
    );

    const getTodayCalls = () => callsChartData[7].value;

    const getWeekCalls = () =>
        callsChartData
            .slice(1)
            .map((v) => v.value)
            .reduce((v, w) => v + w, 0);

    return (
        <PermissionPlaceholder permission={Permission.Dashboard.value}>
            <div className={classes.container}>
                <div className={classes.scrollableContainer}>
                    <div className={classes.containerPadding}>
                        <span className={classes.mainHeader}>
                            {t('screens:dashboard.dashboard')}
                        </span>

                        <div className={classes.doughnutsContainer}>
                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.Extensions.value
                                }
                            >
                                <DoughnutSection
                                    label={t('screens:dashboard.extensions')}
                                    onPressLabel={() =>
                                        history.push(Routes.Extensions)
                                    }
                                    data={[
                                        {
                                            label: t(
                                                'screens:dashboard.registered',
                                            ),
                                            value: registeredExtensions || 0,
                                            color: Colors.Support,
                                        },
                                        {
                                            label: t(
                                                'screens:dashboard.unregistered',
                                            ),
                                            value: unregisteredExtensions || 0,
                                            color: Colors.Primary,
                                        },
                                    ]}
                                    isLoading={
                                        registeredExtensions === undefined ||
                                        unregisteredExtensions === undefined
                                    }
                                />
                            </PermissionProvider>
                            <PermissionProvider
                                permission={
                                    Permission.Inventory.DIDNumbers.value
                                }
                            >
                                <DoughnutSection
                                    label={t('screens:dashboard.didNumbers')}
                                    onPressLabel={() =>
                                        history.push(Routes.DidNumbers)
                                    }
                                    data={[
                                        {
                                            label: t(
                                                'screens:dashboard.assigned',
                                            ),
                                            value: inUseDidNumbers || 0,
                                            color: Colors.Support,
                                        },
                                        {
                                            label: t(
                                                'screens:dashboard.notUsed',
                                            ),
                                            value: notInUseDidNumbers || 0,
                                            color: Colors.BorderDark,
                                        },
                                    ]}
                                    isLoading={
                                        inUseDidNumbers === undefined ||
                                        notInUseDidNumbers === undefined
                                    }
                                />
                            </PermissionProvider>
                            <PermissionProvider
                                permission={Permission.Inventory.Devices.value}
                            >
                                <DoughnutSection
                                    label={t('screens:dashboard.devices')}
                                    onPressLabel={() =>
                                        history.push(Routes.Devices)
                                    }
                                    data={[
                                        {
                                            label: t(
                                                'screens:dashboard.withUsedLines',
                                            ),
                                            value: inUseDevices || 0,
                                            color: Colors.Support,
                                        },
                                        {
                                            label: t(
                                                'screens:dashboard.notUsed',
                                            ),
                                            value: notInUseDevices || 0,
                                            color: Colors.BorderDark,
                                        },
                                    ]}
                                    isLoading={
                                        inUseDevices === undefined ||
                                        notInUseDevices === undefined
                                    }
                                />
                            </PermissionProvider>
                        </div>

                        <PermissionProvider permission={Permission.Calls.value}>
                            <CallsSection
                                data={callsChartData}
                                isLoading={callsFromLastDays === undefined}
                                callsNow={activeCalls}
                                callsToday={getTodayCalls()}
                                callsWeek={getWeekCalls()}
                            />
                        </PermissionProvider>

                        <ProductsSection
                            products={(mainProducts || [])?.concat(
                                addonProducts || [],
                            )}
                            isLoading={
                                mainProducts === undefined ||
                                addonProducts === undefined
                            }
                        />
                    </div>

                    <div className={classNames(classes.rightSidePanel, isAllTheKeysHidden && classes.rightSidePanelWithEmptyContext)}>
                        <div data-testid="right-side-panel" className={classNames(isAllTheKeysHidden ? classes.hiddenRightSidePanelContent : classes.rightSidePanelContent)}>
                            <span className={classes.panelHeader}>
                                {t('screens:dashboard.addNew')}
                            </span>

                            <PermissionProvider
                                permission={
                                    Permission.Dashboard.AddNewExtension.value
                                }
                            >
                                <PanelButton
                                    title={t('screens:dashboard.extension')}
                                    icon={<ExtensionIcon />}
                                    onPress={() =>
                                        setAddExtensionDialogVisible(true)
                                    }
                                />
                            </PermissionProvider>

                            <PermissionProvider
                                permission={
                                    Permission.Dashboard.AddNewRingGroup.value
                                }
                            >
                                <PanelButton
                                    title={t('screens:dashboard.ringGroup')}
                                    icon={<RingGroupIcon />}
                                    onPress={() =>
                                        setAddRingGroupDialogVisible(true)
                                    }
                                />
                            </PermissionProvider>
                            <PermissionProvider
                                permission={
                                    Permission.Dashboard.AddNewFaxMailbox.value
                                }
                            >
                                <PanelButton
                                    title={t('screens:dashboard.faxMailbox')}
                                    icon={<FaxMailboxIcon />}
                                    onPress={() =>
                                        setAddFaxMailboxDialogVisible(true)
                                    }
                                />
                            </PermissionProvider>
                            <PermissionProvider
                                permission={
                                    Permission.Dashboard.AddNewAutoAttendant
                                        .value
                                }
                            >
                                <PanelButton
                                    title={t('screens:dashboard.autoAttendant')}
                                    icon={<AutoAttendantIcon />}
                                    onPress={() =>
                                        setAddAutoAttendantDialogVisible(true)
                                    }
                                />
                            </PermissionProvider>
                            <PermissionProvider
                                permission={
                                    Permission.Dashboard.AddNewPortalUser.value
                                }
                            >
                                <PanelButton
                                    title={t('screens:dashboard.portalUser')}
                                    icon={<PortalUserIcon />}
                                    onPress={() =>
                                        setAddPortalUserDialogVisible(true)
                                    }
                                />{' '}
                            </PermissionProvider>
                        </div>
                    </div>

                    <CreateNewExtensionDialog
                        isOpen={addExtensionDialogVisible}
                        toggleVisibility={() =>
                            setAddExtensionDialogVisible(
                                !addExtensionDialogVisible,
                            )
                        }
                    />

                    <CreateNewRingGroupDialog
                        isOpen={addRingGroupDialogVisible}
                        toggleVisibility={() =>
                            setAddRingGroupDialogVisible(
                                !addRingGroupDialogVisible,
                            )
                        }
                    />

                    <AddNewFaxMailboxesDialog
                        isOpen={addFaxMailboxDialogVisible}
                        toggleVisibility={() =>
                            setAddFaxMailboxDialogVisible(
                                !addFaxMailboxDialogVisible,
                            )
                        }
                    />

                    <CreateNewAutoAttendantDialog
                        isOpen={addAutoAttendantDialogVisible}
                        toggleVisibility={() =>
                            setAddAutoAttendantDialogVisible(
                                !addAutoAttendantDialogVisible,
                            )
                        }
                    />

                    <CreateNewPortalUserDialog
                        isOpen={addPortalUserDialogVisible}
                        toggleVisibility={() =>
                            setAddPortalUserDialogVisible(
                                !addPortalUserDialogVisible,
                            )
                        }
                    />
                </div>
            </div>
        </PermissionPlaceholder>
    );
};
