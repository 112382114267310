import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CustomButton from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    filtersContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0px',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    filterSeparation: {
        marginRight: 16,
        flex: 1,
        maxWidth: '390px',
        background: 'white',
    },

    mainFilterContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    filters: {
        display: 'flex',
    },
    search: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    hidden: {
        display: 'none'
    }
}));

interface FiltersWrapperInterface {
    dataQa?: string;
    fileds: (JSX.Element | undefined | boolean)[];
    onSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    className?: string;
    hideSearchButton?: boolean;
}

const FiltersWrapper: React.FC<FiltersWrapperInterface> = ({
    fileds,
    onSubmit,
    dataQa,
    children,
    className,
    hideSearchButton
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderFields = fileds.map((item) => ({
        // @ts-ignore
        ...item,
        props: (item as JSX.Element) ?
            {
                ...(item as JSX.Element).props,
                className: classNames(
                    classes.filterSeparation,
                    (item as JSX.Element).props.className,
                )
            } : {
                className: classNames(
                    classes.filterSeparation
                )
            },
    }));

    return (
        <div
            className={classNames(classes.filtersContainer, className)}
            data-qa={dataQa}
            data-testid={dataQa}
        >
            <div className={classes.mainFilterContainer}>
                <div className={classes.filters}>{renderFields}</div>
                <CustomButton
                    dataQa="search-button"
                    primary
                    accent
                    type="button"
                    onClick={() => {
                        onSubmit?.();
                    }}
                    className={classNames(classes.search,
                        hideSearchButton ? classes.hidden : ''
                    )}
                    skipPermission
                >
                    {t('common:search')}
                </CustomButton>
            </div>

            {children}
        </div>
    );
};

export default FiltersWrapper;
