import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { UAInfo } from '../../../types/Devices';

export interface DeviceReducerState {
    device?: {
        item: UAInfo | null | undefined;
        isLoading: boolean;
    };
}

export const initialState: DeviceReducerState = {};

export type DeviceReducerActions = ActionType<typeof actions>;

export const deviceReducer = createReducer<
    DeviceReducerState,
    DeviceReducerActions
>(initialState)
    .handleAction(actions.fetchDeviceByIAccount.request, (state) => ({
        ...state,
        device: { item: undefined, isLoading: true },
    }))
    .handleAction(actions.fetchDeviceByIAccount.success, (state, action) => ({
        ...state,
        device: { item: action.payload.device, isLoading: false },
    }))
    .handleAction(actions.fetchDeviceByIAccount.failure, (state) => ({
        ...state,
        device: { item: undefined, isLoading: false },
    }));

export default deviceReducer;
