import React from 'react';
import { Delete, Edit } from '@material-ui/icons';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import { FollowMeSequence } from '../../../../store/types/AccountFollowMeSettings';
import CustomizedIconButton from '../../../IconButton/IconButton';
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip';
import Switch from '../../../Switch/Switch';
import TextField from '../../../TextField/TextField';
import { CallForwardingRule, useStyles } from './CallForwardingForm.utils';
import IntervalCell from './IntervalCell';
import { ForwardModeFlag } from '../../../../store/types/ServiceFeature';

export const generateColumns = (
    followMeMode: ForwardModeFlag,
    followMeSequence: FollowMeSequence,
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    setFieldValue: (fieldName: string, value: any) => void,
    onEditRule: (rule: CallForwardingRule, index: number) => void,
    onRemoveRule: (rule: CallForwardingRule, index: number) => void,
): Column<CallForwardingRule>[] => {
    const columns: Column<CallForwardingRule>[] = [];

    columns.push({
        accessor: 'enabled',
        width: 1,
        maxWidth: 70,
        minWidth: 70,
        Cell: function Cell(params) {
            return (
                <Switch
                    dataTestId="followme-rule-status"
                    dataQa="followme-rule-status"
                    checked={params.row.original.enabled}
                    onChange={(e) => {
                        setFieldValue(
                            `callForwardingRules.[${params.row.index}].enabled`,
                            e.target.checked,
                        );

                        const rules = params.rows.map((v) => v.original);
                        rules[params.row.index].enabled = e.target.checked;
                        const weights = countRulesWeights(
                            rules,
                            params.row.index,
                            '0',
                        );

                        weights.forEach((v, index) => {
                            setFieldValue(
                                `callForwardingRules.[${index}].weight`,
                                v || '0',
                            );
                        });
                    }}
                    className={classes.ruleStatus}
                />
            );
        },
    });

    if (followMeSequence === FollowMeSequence.PercentageList) {
        columns.push({
            accessor: 'weight',
            width: 1,
            maxWidth: 112,
            minWidth: 112,
            Cell: function Cell(params) {
                return (
                    <TextField
                        id={`callForwardingRules.[${params.row.index}].weight`}
                        dataQa="followme-rule-weight"
                        label={t('screens:extensions.percent')}
                        shrink
                        value={params.row.original.weight}
                        onChange={(e) => {
                            const rules = params.rows.map((v) => v.original);

                            const weights = countRulesWeights(
                                rules,
                                params.row.index,
                                e.target.value,
                            );

                            weights.forEach((v, index) => {
                                setFieldValue(
                                    `callForwardingRules.[${index}].weight`,
                                    v || '0',
                                );
                            });
                        }}
                        type="number"
                        icon={<span className={classes.percentLabel}>%</span>}
                        iconPosition="end"
                        inputProps={{
                            inputProps: {
                                min: 0,
                                max: 100,
                            },
                            pattern: '[0-9]*',
                        }}
                        className={classes.weightInput}
                        disabled={!params.row.original.enabled}
                    />
                );
            },
        });
    }

    return [
        ...columns,

        {
            accessor: 'description',
            width: 1,
            maxWidth: 180,
            minWidth: 180,
            Cell: function Cell(params) {
                return (
                    <OverflowTooltip
                        text={params.row.original.description}
                        tooltip={params.row.original.description}
                    />
                );
            },
        },
        {
            accessor: 'number',
            width: 1,
            maxWidth: 180,
            minWidth: 180,
            Cell: function Cell(params) {
                const advancedInfo =
                    followMeMode === ForwardModeFlag.AdvancedForwarding
                        ? `${
                              params.row.original.sipProxy
                                  ? `@${params.row.original.sipProxy} `
                                  : ''
                          }(${params.row.original.transportProtocol})`
                        : null;

                return (
                    <div style={{ width: 160 }}>
                        <OverflowTooltip
                            text={params.row.original.number}
                            tooltip={params.row.original.number}
                        />
                        {!!advancedInfo && (
                            <OverflowTooltip
                                text={advancedInfo}
                                tooltip={advancedInfo}
                                classes={{ text: classes.sipText }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            accessor: 'intervals',
            width: 1.4,
            Cell: function Cell(params) {
                return (
                    <IntervalCell intervals={params.row.original.intervals} />
                );
            },
        },
        {
            accessor: 'callingPartyDisplay',
            width: 1,
            maxWidth: 180,
            minWidth: 180,
            Cell: function Cell(params) {
                const callerParty = t(
                    `enums:callerPartyDisplay.${params.row.original.callingPartyDisplay}`,
                );

                const keepOriginalCld = t(
                    'screens:advancedSettings.keepOriginalCld',
                );

                return (
                    <div className={classes.otherInfoContainer}>
                        {params.row.original.keepOriginalCld && (
                            <OverflowTooltip
                                text={keepOriginalCld}
                                tooltip={keepOriginalCld}
                                classes={{ text: classes.otherInfoText }}
                                copy={false}
                            />
                        )}

                        <OverflowTooltip
                            text={callerParty}
                            tooltip={callerParty}
                            classes={{ text: classes.otherInfoText }}
                            copy={false}
                        />
                    </div>
                );
            },
        },

        {
            accessor: 'id',
            width: 1,
            maxWidth: 130,
            minWidth: 130,
            Cell: function Cell(params) {
                return (
                    <>
                        <CustomizedIconButton
                            data-testid="followme-rule-edit"
                            onClick={() =>
                                onEditRule(
                                    params.row.original,
                                    params.row.index,
                                )
                            }
                            tooltipText={t('common:edit')}
                        >
                            <Edit />
                        </CustomizedIconButton>
                        <CustomizedIconButton
                            data-testid="followme-rule-remove"
                            onClick={() =>
                                onRemoveRule(
                                    params.row.original,
                                    params.row.index,
                                )
                            }
                            tooltipText={t('common:delete')}
                        >
                            <Delete />
                        </CustomizedIconButton>
                    </>
                );
            },
        },
    ];
};

export const countRulesWeights = (
    rules: CallForwardingRule[],
    changedRuleIndex: number,
    newChangedValue: string,
) => {
    rules.forEach((v) => {
        if (!v.enabled) {
            v.weight = '0';
        }
    });

    const changedValue = parseInt(newChangedValue);
    let weights = rules.map((v) => (v.weight ? parseInt(v.weight) : 0));

    const difference = changedValue - weights[changedRuleIndex];

    const sumWithoutChangedValue = weights.reduce(
        (v, w, index) => v + (index === changedRuleIndex ? 0 : w),
        0,
    );

    const enabledRulesLength = rules.filter((v) => v.enabled).length;

    if (sumWithoutChangedValue === 0 && enabledRulesLength <= 1) {
        const enabledId = rules.findIndex((v) => v.enabled);
        const result = Array(rules.length).fill(0);
        if (enabledId !== -1) {
            result[enabledId] = '100';
        } else {
            result[changedRuleIndex] = '100';
        }
        return result;
    }

    let changedFirstValue = false;

    const differencesArray = weights.map((v, index) => {
        if (index === changedRuleIndex) {
            return 0;
        }
        let value =
            sumWithoutChangedValue === 0
                ? 0
                : (v / sumWithoutChangedValue) * 100;
        if (!changedFirstValue) {
            value = Math.floor(value);
            changedFirstValue = true;
        } else {
            value = Math.ceil(value);
        }

        return value;
    });

    changedFirstValue = false;

    weights = weights.map((v, index) => {
        if (index !== changedRuleIndex) {
            let value = v - (difference * differencesArray[index]) / 100;
            if (!changedFirstValue) {
                value = Math.floor(value);
                changedFirstValue = true;
            } else {
                value = Math.ceil(value);
            }

            return value < 0 ? 0 : value;
        }

        return changedValue;
    });

    const missingValue = 100 - weights.reduce((v, w) => v + w, 0);

    if (missingValue) {
        const index = rules.findIndex(
            (v, id) =>
                v.enabled && id !== changedRuleIndex && v.weight !== undefined,
        );

        weights[index ?? changedRuleIndex] += missingValue;
    }

    return weights.map((v) => v.toString());
};

export const redistributeFullValuesToRulesWigth = (
    rules: CallForwardingRule[],
) => {
    const enabledRulesLength = rules.filter((v) => v.enabled).length;

    const newRules = rules.map((v) => ({
        ...v,
        weight: v.enabled ? Math.floor(100 / enabledRulesLength) : 0,
    }));

    const missingValue = 100 - newRules.reduce((v, w) => v + w.weight, 0);
    if (missingValue) {
        const index = rules.findIndex((v) => v.enabled);

        if (index !== -1) {
            newRules[index].weight += missingValue;
        }
    }

    return newRules;
};
