import FiltersWrapper from '../FiltersWrapper/FiltersWrapper';
import CustomKeyboardDateTimePicker from '../KeyboardDateTimePicker/KeyboardDateTimePicker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../services/i18n';
import * as Yup from 'yup';
import dayjs from '../../services/customDayJs';
import CustomizedButtonGroup from '../ButtonGroup/ButtonGroup';
import { getDefaultDatePreferences } from '../../views/Billing/Transactions/utils/DateUtils';

export interface TransactionFiltersProps {
    initData: TransactionFiltersFormType;
    userDateTimeFormat?: string;
    onClick?: () => void;
    onChange?: (values: TransactionFiltersFormType) => void;
    className?: string;
}

export enum TransactionFilterDateModes {
    ThisMonth = 0,
    PreviousMonth = 1,
    Custom = 2
}

export interface TransactionFiltersFormType {
    fromDate: string;
    toDate: string;
    dateMode: TransactionFilterDateModes;
    i_service?: number; 
}

const useStyles = makeStyles(() => ({
    option: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    statusContainer: {
        minWidth: 208,
        maxWidth: 208,
    },

    filter: {
        maxWidth: 208,
    },
}));

const emptyInputText = i18n.t<string>('common:emptyInput');

const validationTransactionFiltersSchema = Yup.object().shape({
    fromDate: Yup.string()
        .required(emptyInputText)
        .test('is-valid-date', i18n.t('errors:ringGroups.wrongFormat'), (v) =>
            dayjs(new Date(v || '')).isValid(),
        ),
    toDate: Yup.string()
        .required(emptyInputText)
        .test('is-valid-date', i18n.t('errors:ringGroups.wrongFormat'), (v) =>
            dayjs(new Date(v || '')).isValid(),
        ),
});

const TransactionFilters: React.VFC<TransactionFiltersProps> = ({
    initData,
    userDateTimeFormat,
    onClick,
    onChange,
    className
}) => {
    const { t } = useTranslation();
    const clasess = useStyles();

    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        setFieldError,
    } = useFormik<TransactionFiltersFormType>({
        initialValues: initData,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: validationTransactionFiltersSchema,
        onSubmit: () => {
            onClick?.();
        },
    });

    useEffect(() => {
        onChange?.(values);
    }, [values]);

    const [fromDate, setFromDate] = useState<string>(initData.fromDate);
    const [toDate, setToDate] = useState<string>(initData.toDate);

    const fields = [
        <CustomKeyboardDateTimePicker
            label={t('common:fromDate')}
            id={'fromDate'}
            value={fromDate}
            onChange={(v) => setFromDate(v || '')}
            dataQa={'filter-by-from-date'}
            dataTestId={'filter-by-from-Date'}
            userDateTimeFormat={userDateTimeFormat}
            setFieldError={setFieldError}
            key="filter-1"
            helperText={errors.fromDate}
            className={clasess.filter}
            disabled={values.dateMode !== TransactionFilterDateModes.Custom}
            skipPermission
        />,
        <CustomKeyboardDateTimePicker
            label={t('common:toDate')}
            id={'toDate'}
            value={toDate}
            onChange={(v) => setToDate(v || '')}
            dataQa={'filter-by-to-date'}
            dataTestId={'filter-by-to-date'}
            userDateTimeFormat={userDateTimeFormat}
            key="filter-2"
            setFieldError={setFieldError}
            helperText={errors.toDate}
            className={clasess.filter}
            disabled={values.dateMode !== TransactionFilterDateModes.Custom}
            skipPermission
        />,
    ];

    const dateModeChangedEvent = (val: TransactionFilterDateModes) => {
        if(initData.dateMode !== val) {
            const calc = getDefaultDatePreferences(new Date());
            if(val === TransactionFilterDateModes.ThisMonth) {
                setFromDate(calc.currentMonthStartDate);
                setToDate(calc.currentMonthEndDate);
                setFieldValue('fromDate', calc.currentMonthStartDate);
                setFieldValue('toDate', calc.currentMonthEndDate);
            } else if (val === TransactionFilterDateModes.PreviousMonth) {
                setFromDate(calc.previousMonthStartDate);
                setToDate(calc.previousMonthEndDate);
                setFieldValue('fromDate', calc.previousMonthStartDate);
                setFieldValue('toDate', calc.previousMonthEndDate);
            }
            setFieldValue('dateMode', val);
        }
    };

    const dateModeElem = (
        <div style={{ height: '36px', marginTop: '6px', padding: '4px', borderRadius: '44px' }} key="date-mode">
            <CustomizedButtonGroup 
                items={['screens:billing:thisMonth', 
                'screens:billing:previousMonth', 
                'screens:billing:customDate']}
                onChange={dateModeChangedEvent}
                filterValue={initData.dateMode}
                key="date-mode-internal"
                skipPermission
                >
            </CustomizedButtonGroup>
        </div>
    );
    
    const searchClicked = function (e?: React.FormEvent<HTMLFormElement> | undefined) {
        if(values.fromDate !== fromDate) {
            setFieldValue('fromDate', fromDate);
        }
        if(values.toDate !== toDate) {
            setFieldValue('toDate', toDate);
        }
        handleSubmit(e);
    }

    return (<div>
        <FiltersWrapper
            fileds={[dateModeElem, ...fields]}
            onSubmit={searchClicked}
            dataQa={'transaction-filters'}
            className={className}
            hideSearchButton={initData.dateMode !== TransactionFilterDateModes.Custom}
        >
        </FiltersWrapper>
        </div>
    );
};

export default TransactionFilters;
