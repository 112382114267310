export default class JSONFormData extends FormData {
    constructor(session_id: string, csrf_token: string) {
        super();
        this.append('auth_info', JSON.stringify({ session_id: session_id, csrf_token: csrf_token }));
    }

    public setParams(params: Object) {
        this.delete('params');
        this.append('params', JSON.stringify(params));
    }
}
