import React, { useEffect, useState } from 'react';
import Autocomplete, {
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { FilterOptionsState } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { createSyntheticEvent } from '../../utils/createSyntheticEvent';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

const checkedIcon = <CheckBoxIcon fontSize="default" />;

type SelectFieldProps = {
    items: any[];
    disabled?: boolean;
    readOnly?: boolean;
    label?: string;
    placeholder?: string;
    multiple?: boolean;
    dataQa?: string;
    icon?: JSX.Element;
    value: any;
    className?: string;
    required?: boolean;
    dataTestId?: string;
    helperText?: string;
    classes?: {
        helperText?: string;
        container?: string;
        iconContainer?: string;
        option?: string;
        popper?: string;
    };
    limitTags?: number;
    disableClearable?: boolean;
    disableCloseOnSelect?: boolean;
    id?: string;
    freeSolo?: boolean;
    renderOption?: (
        option: any,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    renderTags?: (values: string[]) => React.ReactNode;
    onChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
    getOptionLabel?: (item: any) => string;
    getOptionSelected?: (item: any, value: any) => any;
    getOptionDisabled?: (option: any) => boolean;
    setFieldError?: (field: string, value: string | undefined) => void;
    onTextChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    customTextField?: (
        params: AutocompleteRenderInputParams,
        selectProps?: Partial<SelectFieldProps>,
    ) => React.ReactNode;
    filterOptions?: (
        options: string[],
        state: FilterOptionsState<string>,
    ) => string[];
    setFieldValue?: (fieldName: string, value: any) => void;
    disableAutoSettingValue?: boolean;
    defaultValue?: any;
    skipPermission?:boolean;
};

const useStyles = makeStyles(() => ({
    selectContainer: {
        display: 'inline-flex',
        flex: 1,
        minWidth: 200,
        alignItems: 'flex-end',
    },
    selectField: {
        flex: 1,
        '& .MuiInputBase-root': {
            height: 56,
            backgroundColor: Colors.White,
            borderRadius: '4px 4px 0px 0px',
            '&:before': {
                borderBottom: `1px solid ${Colors.Border}`,
            },
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
        '& .MuiAutocomplete-inputRoot': {
            '& .MuiInputBase-input:first-child': {
                padding: '16px 16px 0 16px',
            },
        },
        '& .MuiInputBase-input': {
            height: 42,
            color: Colors.Text,
        },
        '& .Mui-disabled:hover:before': {
            borderBottomColor: 'inherit',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: Colors.Primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.Primary,
        },

        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 0.8,
        },

        '& .MuiInputLabel-asterisk': {
            color: Colors.Error,
        },

        '& .MuiFormHelperText-root': {
            marginTop: 0,
            width: 'inherit',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: Colors.Error,
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: Colors.PlaceHolder,
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: `${Colors.Error}!important`,
        },
        '& .MuiInputLabel-root': {
            marginLeft: '16px !important',
            top: '-2px !important',
            zIndex: 4,
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 13px) scale(0.75)',
        },
        '& .MuiAutocomplete-endAdornment': {
            marginRight: 10,
        },
    },
    endIconContainer: {
        '& .MuiAutocomplete-endAdornment': {
            paddingRight: 30,
        },
    },
    absoluteIconContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 9,
        marginLeft: -33,
        zIndex: 1,
        height: 56,
    },
    multiValue: {
        '& .MuiInputBase-input': {
            height: 'unset !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 56,
            height: 'unset !important',
            padding: '24px 70px 4px 5px ',
        },
        '& .MuiChip-root': {
            marginTop: 3,
            marginBottom: 3,
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'unset',
            bottom: 15,
        },
        '& .MuiInputBase-input:first-child': {
            padding: '4px !important',
            marginLeft: 7,
        },
    },
    helperText: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: -25,
        marginTop: 3,
    },
    option: {
        minHeight: 47,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        '-ms-word-break': 'break-all',
        '-ms-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        '-webkit-hyphens': 'auto',
        hyphens: 'auto',
    },
    disabledContainer: {
        '& .MuiSvgIcon-root': {
            opacity: 0.8,
        },

        '& .Mui-disabled:before': {
            borderBottom: `1px dashed ${Colors.LightGraySecondary}`,
        },
    },
    readOnlyContainer: {
        '& .MuiFormControl-root': {
            backgroundColor: 'transparent !important',
        },

        '& .MuiInputBase-root': {
            backgroundColor: 'transparent !important',
        },

        '& .Mui-disabled:before': {
            borderBottom: `1px solid ${Colors.BorderDark}`,
        },
        '& > .MuiFormControl-root': {},
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: Colors.PlaceHolder,
        },
        '& .MuiSvgIcon-root': {
            opacity: 1,
            fill: Colors.Gray9,
        },
    },
}));

const SelectField: React.FC<SelectFieldProps> = ({
    defaultValue = undefined,
    items,
    onChange,
    disabled,
    readOnly,
    label,
    placeholder,
    multiple,
    dataQa,
    icon,
    value = defaultValue,
    className,
    getOptionLabel,
    getOptionSelected,
    required,
    dataTestId,
    helperText,
    classes: customClasses = {},
    renderTags,
    limitTags,
    disableClearable = false,
    renderOption,
    getOptionDisabled,
    setFieldError,
    id,
    freeSolo,
    onTextChange,
    customTextField,
    filterOptions,
    disableCloseOnSelect,
    disableAutoSettingValue,
    skipPermission
}) => {
    const classes = useStyles();
    const [isCleared, setIsCleared] = useState(false);

    let permission = usePermissionContext();

    permission = skipPermission ? PermissionType.Visible : permission;
    readOnly = readOnly || permission === PermissionType.ReadOnly;

    //@ts-ignore option is used like object with param name but in Material UI it's string
    const createOption = (option, selected: boolean) => (
        <React.Fragment>
            <Checkbox
                checkedIcon={checkedIcon}
                style={{
                    color: selected ? Colors.Secondary1 : Colors.PlaceHolder,
                    paddingLeft: '0',
                }}
                checked={selected}
            />
            {option?.name || option}
        </React.Fragment>
    );

    const calculateDisable = () =>
        disableCloseOnSelect !== undefined ? disableCloseOnSelect : multiple;

    const checkValueEquality = (id?: string) =>
        id === 'sipProxy'
            ? `@ ${items[0]}` !== value
            : typeof value === 'object'
            ? items[0]?.name !== value?.name
            : items[0] !== value;

    useEffect(() => {
        const event = new Event('change', { bubbles: true });
        const syntheticEvent = createSyntheticEvent(
            event,
        ) as React.ChangeEvent<any>;
        if (
            items?.length === 1 &&
            onChange &&
            !disableAutoSettingValue &&
            !isCleared
        ) {
            if (multiple && items[0] !== value[0]) {
                onChange(syntheticEvent, items);
            }
            if (!multiple && checkValueEquality(id)) {
                onChange(syntheticEvent, items[0]);
            }
        }
    }, [items]);

    const onInputChange = (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason,
    ) => {
        if (reason === 'reset') {
            setIsCleared(true);
        }
    };

    return (
        <div
            className={classNames(
                classes.selectContainer,
                disabled && classes.disabledContainer,
                readOnly && classes.readOnlyContainer,
                customClasses.container,
            )}
        >
            <Autocomplete
                freeSolo={freeSolo}
                multiple={multiple}
                className={classNames(
                    classes.selectField,
                    !!icon && classes.endIconContainer,
                    Array.isArray(value) && classes.multiValue,
                    className,
                )}
                classes={{
                    popper: customClasses.popper,
                    option: classNames(classes.option, customClasses.option),
                }}
                onInputChange={onInputChange}
                limitTags={limitTags}
                value={value}
                getOptionLabel={getOptionLabel || ((option) => option)}
                onChange={(e, ev) => {
                    onChange?.(e, ev);
                    !!id && setFieldError?.(id, undefined);
                }}
                options={items}
                renderTags={renderTags}
                disableClearable={disableClearable}
                filterOptions={filterOptions}
                renderInput={
                    customTextField
                        ? (params) =>
                              customTextField(params, {
                                  label,
                                  placeholder,
                                  required,
                                  helperText,
                                  onTextChange,
                              })
                        : (params) => (
                              <TextField
                                  {...params}
                                  label={label}
                                  variant="standard"
                                  placeholder={placeholder}
                                  required={required}
                                  error={!!helperText}
                                  onChange={onTextChange}
                                  helperText={
                                      <>
                                          {helperText && (
                                              <span
                                                  className={classes.helperText}
                                                  data-qa={`helper-${dataQa}`}
                                                  data-testid="textfield-helper"
                                              >
                                                  {helperText}
                                              </span>
                                          )}
                                      </>
                                  }
                              />
                          )
                }
                disabled={disabled || readOnly}
                data-qa={dataQa}
                getOptionSelected={getOptionSelected}
                data-testid={dataTestId}
                renderOption={
                    multiple
                        ? (option, { selected }) =>
                              createOption(option, selected)
                        : renderOption
                }
                getOptionDisabled={getOptionDisabled}
                disableCloseOnSelect={calculateDisable()}
            />

            {!!icon && (
                <div
                    className={classNames(
                        classes.absoluteIconContainer,
                        customClasses.iconContainer,
                    )}
                >
                    {icon}
                </div>
            )}
        </div>
    );
};

export default SelectField;
