import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions';
import { CustomerAgreementCondition } from '../../types/CustomerAgreementCondition';

export interface DashboardStateType {
    registeredExtensions?: number;
    unRegisteredExtensions?: number;
    activeCalls?: number;
    callsConnected?: number;
    callsFromLastDays?: { date: string; value: number }[];
    allDidNumbers?: number;
    inUseDidNumbers?: number;
    notInUseDidNumbers?: number;
    allDevices?: number;
    inUseDevices?: number;
    notInUseDevices?: number;
    mainProducts?: CustomerAgreementCondition[];
    addonProducts?: CustomerAgreementCondition[];
}

export const initialState: DashboardStateType = {};

export type DashboardActionsType = ActionType<typeof actions>;

const dashboardReducer = createReducer<
    DashboardStateType,
    DashboardActionsType
>(initialState)
    .handleAction(actions.getDashboardData, () => initialState)
    .handleAction(actions.getDashboardActiveCalls.request, (state) => ({
        ...state,
        activeCalls: undefined,
    }))
    .handleAction(actions.setRegisteredExtensions, (state, action) => ({
        ...state,
        registeredExtensions: action.payload,
    }))
    .handleAction(actions.setUnregisteredExtensions, (state, action) => ({
        ...state,
        unRegisteredExtensions: action.payload,
    }))
    .handleAction(actions.setDisabledExtensions, (state, action) => ({
        ...state,
        disabledExtensions: action.payload,
    }))
    .handleAction(actions.getDashboardActiveCalls.success, (state, action) => ({
        ...state,
        activeCalls: action.payload,
    }))
    .handleAction(actions.setCallsConnected, (state, action) => ({
        ...state,
        callsConnected: action.payload,
    }))
    .handleAction(actions.setCallsFromLastDays, (state, action) => ({
        ...state,
        callsFromLastDays: action.payload,
    }))
    .handleAction(actions.setAllDidNumbers, (state, action) => ({
        ...state,
        allDidNumbers: action.payload,
    }))
    .handleAction(actions.setInUseDidNumbers, (state, action) => ({
        ...state,
        inUseDidNumbers: action.payload,
    }))
    .handleAction(actions.setNotInUseDidNumbers, (state, action) => ({
        ...state,
        notInUseDidNumbers: action.payload,
    }))
    .handleAction(actions.setAllDevices, (state, action) => ({
        ...state,
        allDevices: action.payload,
    }))
    .handleAction(actions.setInUseDevices, (state, action) => ({
        ...state,
        inUseDevices: action.payload,
    }))
    .handleAction(actions.setNotInUseDevices, (state, action) => ({
        ...state,
        notInUseDevices: action.payload,
    }))
    .handleAction(actions.setMainProducts, (state, action) => ({
        ...state,
        mainProducts: action.payload,
    }))
    .handleAction(actions.setAddonProducts, (state, action) => ({
        ...state,
        addonProducts: action.payload,
    }));

export default dashboardReducer;
