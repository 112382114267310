import React from "react";
import OverflowTooltip from "../OverflowTooltip/OverflowTooltip";
import {makeStyles} from '@material-ui/core';
import {Colors} from "../../styles/Colors";
import {usePermissionContext, usePermissions} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

const useStyles = makeStyles(() => ({
    url: {
        color: Colors.Secondary1,
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 400,
    },

    disabled: {
        color: Colors.Text,
        fontSize: 16,
        fontWeight: 400,
    }
}));

interface PermissionLinkCellInterface {
    text: string,
    onClick: () => void,
    permissions?: string[],
    className?:string
}

const PermissionLinkCell: React.VFC<PermissionLinkCellInterface> = ({text, onClick, permissions,className}) => {
    const classes = useStyles();

    let permission;
    if(!!permissions){
        permission = usePermissions(...permissions);
    }else{
        permission = usePermissionContext();
    }

    const linkActive = permission !== PermissionType.Hidden;

    return <OverflowTooltip
        classes={{
            text: linkActive ? classes.url : classes.disabled
        }}
        tooltip={text}
        text={
            <span className={className} onClick={linkActive ? onClick : () => null}>
                {text}
            </span>
        }
        copy={false}
    />
}

export default PermissionLinkCell;