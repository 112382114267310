import * as actions from '../../actions';
import { put, takeLatest, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { showErrorToast } from '../../../utils/showErrorToast';
import { AxiosResponse } from 'axios';
import JSONFormData from '../../../utils/JSONFormData';
import { api } from '../../services/axios';
import {
    GetUAInfoResponse,
    GetUAListResponse,
    ReleaseDevicePayload,
    UAUsage,
} from '../../types/Devices';
import { ExtensionsListItem } from '../../reducers/extensions/extensions/reducer';
import { Customer, UA } from '../../../services/endpoints';

export function* getDeviceList(
    action: ActionType<typeof actions.devicesList.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        const res: AxiosResponse<GetUAListResponse> = yield api.post(
            UA.GetUaList,
            body,
        );

        body.setParams({
            usage: UAUsage.Used,
            get_total: 1,
            limit: 1,
        });
        const active: AxiosResponse<GetUAListResponse> = yield api.post(
            UA.GetUaList,
            body,
        );

        yield put(
            actions.devicesList.success({
                items: res.data.ua_list,
                total: res.data.total,
                active: active.data.total,
            }),
        );
    } catch (e) {
        showErrorToast(e);
        yield put(actions.devicesList.failure());
    }
}

export function* releaseDevice(
    action: ActionType<typeof actions.releaseDevice.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({ extension: action.payload.extension_id });
        const extResponse: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
            total: number;
        }> = yield api.post(Customer.GetExtensionsList, body);

        const { i_c_ext } = extResponse.data.extensions_list[0];
        const payload: ReleaseDevicePayload = {
            i_c_ext: i_c_ext || 0,
            i_cpe: null,
        };

        body.setParams(payload);
        yield api.post(Customer.UpdateCustomerExtension, body);
        yield put(actions.releaseDevice.success());
        action.payload.onSuccess?.();
    } catch (e) {
        showErrorToast(e);
        yield put(actions.releaseDevice.failure());
    }
}

export function* fetchExtensionById(
    action: ActionType<typeof actions.getExtensionById.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({ extension: action.payload.id, detailed_info: 1 });
    try {
        const res: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
            total: number;
        }> = yield api.post(Customer.GetExtensionsList, body);

        const payload = {
            extension: res.data.extensions_list.length
                ? res.data.extensions_list[0]
                : undefined,
        };

        yield put(actions.getExtensionById.success(payload));
    } catch (e) {
        showErrorToast(e);
        yield put(actions.getExtensionById.failure());
    }
}

export function* assignDeviceToExtension(
    action: ActionType<typeof actions.assignDevice.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    if (action.payload.withRelease && action.payload.extension_id) {
        body.setParams({ extension: action.payload.extension_id });

        const extResponse: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
        }> = yield api.post(Customer.GetExtensionsList, body);

        body.setParams({
            i_c_ext: extResponse.data.extensions_list[0].i_c_ext,
            i_cpe: null,
        });

        yield api.post(Customer.UpdateCustomerExtension, body);
    }

    body.setParams({
        port: action.payload.port,
        i_cpe: action.payload.i_cpe,
        i_c_ext: action.payload.i_c_ext,
    });

    try {
        yield api.post('Customer/update_customer_extension', body);
        yield put(actions.assignDevice.success());
        action.payload.onSuccess?.();
    } catch (e) {
        showErrorToast(e);
        yield put(actions.assignDevice.failure());
    }
}

export function* fetchDeviceByIAccount(
    action: ActionType<typeof actions.fetchDeviceByIAccount.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        const res: AxiosResponse<GetUAInfoResponse> = yield api.post(
            UA.GetUaInfo,
            body,
        );

        yield put(
            actions.fetchDeviceByIAccount.success({ device: res.data.ua_info }),
        );
    } catch (e) {
        showErrorToast(e);
        yield put(actions.fetchDeviceByIAccount.failure());
    }
}

export const devicesSaga = [
    takeLatest(actions.devicesList.request, getDeviceList),
    takeLatest(actions.releaseDevice.request, releaseDevice),
    takeLatest(actions.getExtensionById.request, fetchExtensionById),
    takeLatest(actions.assignDevice.request, assignDeviceToExtension),
    takeLatest(actions.fetchDeviceByIAccount.request, fetchDeviceByIAccount),
];
