import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Account } from '../../../../services/endpoints';
import { prepareCallForwardingRule } from '../../../../utils/extensions/prepareCallForwardingRule';
import JSONFormData from '../../../../utils/JSONFormData';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import { api } from '../../../services/axios';
import { AccountFollowMeSettings } from '../../../types/AccountFollowMeSettings';
import {
    ForwardModeFlag,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';
import { editServiceFeatures } from '../serviceFeatures/saga';

export function* getExtensionCallForwardingSettings(
    action: ActionType<typeof actions.getExtensionFollowMeSettings.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        i_account: action.payload.accountId,
        detailed_info: '1',
        with_period_definition: 1,
    });

    const res: AxiosResponse<AccountFollowMeSettings> = yield api.post(
        Account.GetAccountFollowMe,
        body,
    );

    yield put(actions.getExtensionFollowMeSettings.success(res.data));
}

export function* editExtensionCallForwardingSettings(
    data: Partial<EditExtensionForm>,
    action:
        | ActionType<typeof actions.editExtension.request>
        | ActionType<typeof actions.editAutoAttendant.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        i_account: action.payload.accountId,
    });

    if (data.callForwardingStatus !== undefined || data.callForwardingMethod) {
        const callStatus =
            data.callForwardingStatus ??
            action.payload.initialValues.callForwardingStatus;
        const callForwardingMethod =
            data.callForwardingMethod ||
            action.payload.initialValues.callForwardingMethod;

        yield editServiceFeatures(action.payload.accountId, [
            {
                name: ServiceFeatureName.ForwardMode,
                effective_flag_value: callStatus
                    ? callForwardingMethod
                    : ForwardModeFlag.NoForwarding,
                flag_value: callStatus
                    ? callForwardingMethod
                    : ForwardModeFlag.NoForwarding,
                attributes: [],
            },
        ]);

        if (!callStatus) {
            return;
        }
    }

    if (data.callForwardingRuleOrder) {
        body.setParams({
            i_account: action.payload.accountId,
            followme_info: {
                sequence: data.callForwardingRuleOrder,
            },
        });

        yield api.post(Account.UpdateAccountFollowMe, body);
    }

    if (data.callForwardingRules) {
        const callForwardingMethod =
            data.callForwardingMethod ||
            action.payload.initialValues.callForwardingMethod;

        const singleRulesMethods = [
            ForwardModeFlag.SimpleForwarding,
            ForwardModeFlag.ForwardToSIPUri,
        ];
        const multipleRulesMethods = [
            ForwardModeFlag.FollowMe,
            ForwardModeFlag.AdvancedForwarding,
        ];

        const method =
            data.callForwardingMethod ??
            action.payload.initialValues.callForwardingMethod;

        if (
            data.callForwardingRules.length === 0 &&
            !(
                singleRulesMethods.includes(
                    action.payload.initialValues.callForwardingMethod,
                ) && multipleRulesMethods.includes(method)
            ) &&
            !(
                multipleRulesMethods.includes(
                    action.payload.initialValues.callForwardingMethod,
                ) && singleRulesMethods.includes(method)
            )
        ) {
            const rule = action.payload.initialValues.callForwardingRules[0];
            body.setParams({
                followme_numbers: [
                    prepareCallForwardingRule(
                        { ...rule, weight: '100' },
                        callForwardingMethod,
                        action.payload.accountId,
                    ),
                ],
            });

            yield api.post(Account.UpdateFollowMeNumberList, body);

            body.setParams({
                i_follow_me_number:
                    action.payload.initialValues.callForwardingRules[0].id,
            });

            yield api.post(Account.DeleteFollowMeNumber, body);
            return;
        }

        const numbers = data.callForwardingRules.map((rule) =>
            prepareCallForwardingRule(
                rule,
                callForwardingMethod,
                action.payload.accountId,
            ),
        );

        if (numbers.length) {
            body.setParams({
                followme_numbers: numbers,
            });

            yield api.post(Account.UpdateFollowMeNumberList, body);
        }
    }
}
