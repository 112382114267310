import { makeStyles } from '@material-ui/core';
import {
    Colors,
    lightGray,
    secondaryColor1,
    white,
} from '../../../styles/Colors';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../../store/types/ServiceFeature';
import {
    convertServiceFeaturesToIconsFeatures,
    getServiceValue,
} from '../../../utils/extensions/convertServiceFeaturesToIconsFeatures';
import { TFunction } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { ExtensionsListItem } from '../../../store/reducers/extensions/extensions/reducer';
import {
    Service,
    ServiceIcon,
} from '../../../components/Extensions/ServiceIcon';
import { Link } from 'react-router-dom';
import EmptyRowContent from '../../../components/DataTable/EmptyRowContent';
import ExtensionDIDNumber from '../../../components/Extensions/ExtensionDIDNumber';
import React from 'react';
import { ExtensionServices } from '../../../components/Extensions/ExtensionServices';
import StatusItem from '../../../components/Extensions/StatusItem';
import { ReactComponent as CallHistoryIcon } from '../../../assets/call_history.svg';
import history from '../../../history';
import { Delete, Edit } from '@material-ui/icons';
import { getRoute, Routes } from '../../../routes/routes';
import { FetchDataStatus } from '../../../store/reducers/autoAttendants/reducer';
import BarLoader from '../../../components/BarLoader/BarLoader';
import NameEmailCell from '../../../components/Calls/NameEmailCell';
import ModeCellComponent, {
    CallProcessingModeName,
    CallProcessingModeNameMapping,
} from '../../../components/Calls/ModeCell';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import { Permission } from '../../../store/types/Permission';
import TextButton from '../../../components/TextButton/TextButton';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        flexDirection: 'column',
        height: '100vh',
        display: 'flex',

        '& .MuiButton-label': {
            color: white,
        },
        flex: 1,
        position: 'relative',
        background: Colors.SmokeBackground,
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            width: 'unset !important'
        }
    },

    content: {
        maxWidth: 1040,
    },

    header: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },

    switchMode: {
        display: 'flex',
        marginTop: 10,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.6,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -65,
    },

    processableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },

    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },

    headerCell: {
        fontWeight: 700,
        fontSize: 12,
        backgroundColor: lightGray,
        height: '60px',
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        flex: 1,
        maxWidth: '390px',
        background: 'white',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    extensionIdLink: {
        color: secondaryColor1,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
    email: {
        fontSize: 14,
        opacity: 0.6,
    },
    actionsButtonsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },

    actionHeader: { textAlign: 'center' },
}));

export const getLink = (
    id: string,
    serviceFeatures?: ServiceFeature[],
): string => {
    const value = getServiceValue(
        ServiceFeatureName.AutoAttendant,
        serviceFeatures || [],
    );

    return value
        ? getRoute(Routes.AutoAttendantsDetails, { id: id })
        : getRoute(Routes.ExtensionsDetails, { id: id });
};

export const getLinkFromExtensionItem = (o: ExtensionsListItem) => {
    let path: string;

    switch (o.mainService) {
        case Service.AutoAttendant:
            path = Routes.AutoAttendants;
            break;
        case Service.UnifiedMessagingFaxOnly:
            path = Routes.FaxMailboxes;
            break;
        default:
            path = Routes.Extensions;
    }

    return `${path}/:id`;
};

export const generateColumns = (
    t: TFunction<string>,
    refreshStatus: (i_account: number) => void,
    showCallHistory: (item: ExtensionsListItem) => void,
    deleteExtension: (id: string, i_customer: number, i_c_ext?: number) => void,
    classes: ReturnType<typeof useStyles>,
): Column<ExtensionsListItem>[] => {
    return [
        {
            Header: '',
            accessor: 'i_c_ext',
            width: 1,
            maxWidth: 50,
            minWidth: 50,
            Cell: function Cell(params) {
                const { mainService } = params.row.original;

                return mainService !== undefined ? (
                    <ServiceIcon
                        type={mainService}
                        dataQa="extenion-type-icon"
                    />
                ) : null;
            },
        },
        {
            Header: t<string>('screens:extensions.extension'),
            accessor: 'id',
            width: 1,
            maxWidth: 95,
            minWidth: 95,
            Cell: function Cell(params) {
                return (
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .value
                        }
                        skipReadOnly
                    >
                        <TextButton
                            className={classes.extensionIdLink}
                            onClick={() => 
                                history.push(
                                    getRoute(getLinkFromExtensionItem(params.row.original), {
                                        id: params.row.original.id,
                                    }),
                                )
                            }
                        >
                            {params.row.original.id}
                        </TextButton>
                    </PermissionProvider>
                );
            },
        },
        {
            Header: t<string>('screens:extensions.nameAndEmail'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name;
                const email = params.row.original.account_info?.email;

                return <NameEmailCell name={name} email={email} />;
            },
        },
        {
            Header: t<string>('screens:extensions.didNumber'),
            accessor: 'numbers',
            width: 2,

            Cell: function Cell(params) {
                const numbers = (
                    params.row.original.account_info?.alias_did_number_list ||
                    []
                ).map((v) => v.did_number);

                if (numbers.length > 0) {
                    return (
                        <ExtensionDIDNumber
                            showFooter={false}
                            extNumbers={numbers}
                        />
                    );
                }
                return <EmptyRowContent />;
            },
        },
        {
            Header: t<string>('screens:extensions.servicesAndMode'),
            accessor: (row) => row.account_info!.email,
            width: 1.5,
            id: '3',
            Cell: function Cell(
                params: React.PropsWithChildren<
                    CellProps<ExtensionsListItem, string[] | undefined>
                >,
            ) {
                const mode = params.row.original.callProcessingModeInfo?.name;
                const services = convertServiceFeaturesToIconsFeatures(
                    params.row.original.serviceFeatures || [],
                    params.row.original.mainService,
                    mode,
                );

                const modeToDisplay =
                    mode == CallProcessingModeName.Normal
                        ? CallProcessingModeNameMapping.Normal
                        : mode;

                return (
                    <div>
                        <ExtensionServices
                            services={services}
                            mode={modeToDisplay}
                        />
                        {mode && <ModeCellComponent mode={mode} />}
                    </div>
                );
            },
        },
        {
            Header: t<string>('screens:extensions.statusAndDevice'),
            //@ts-ignore
            accessor: 'status',
            width: 1.5,
            id: '4',
            Cell: function Cell(
                params: React.PropsWithChildren<
                    CellProps<ExtensionsListItem, string[] | undefined>
                >,
            ) {
                const {
                    sip_status,
                    blocked,
                    sip_agent,
                } = params.row.original.account_info!;

                const { fetchSipStatus, i_account } = params.row.original;
                return fetchSipStatus == FetchDataStatus.InProgress ? (
                    <BarLoader
                        dataQa={'sip-status-refresh-bar'}
                        dataTestId={'sip-status-refresh-bar'}
                    />
                ) : (
                    <StatusItem
                        blocked={blocked}
                        sip_status={sip_status!}
                        fetchData={() => refreshStatus(i_account)}
                        device={sip_agent}
                    />
                );
            },
        },

        {
            Header: (
                <div className={classes.actionHeader}>
                    {t<string>('common:actions')}
                </div>
            ),
            accessor: 'i_account',
            width: 1,
            maxWidth: 200,
            minWidth: 200,
            Cell: function Cell(params) {
                return (
                    <div className={classes.actionsButtonsContainer}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.Extensions.ViewCallHistory
                                    .value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    showCallHistory(params.row.original)
                                }
                                tooltipText={t<string>(
                                    'screens:extensions.viewCallHistory',
                                )}
                                dataTestId="call-list-item-button"
                                dataQa="call-list-item-button"
                            >
                                <CallHistoryIcon />
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.Extensions.ExtensionDetails
                                    .value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    history.push(
                                        getRoute(getLinkFromExtensionItem(params.row.original), {
                                            id: params.row.original.id,
                                        }),
                                    )
                                }
                                tooltipText={t<string>('common:edit')}
                                dataTestId="edit-list-item-button"
                                dataQa="edit-list-item-button"
                            >
                                <Edit htmlColor={Colors.ListIconColor} />
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.Extensions.DeleteExtension
                                    .value
                            }
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    deleteExtension(
                                        params.row.original.id.toString(),
                                        params.row.original.i_customer!,
                                    )
                                }
                                tooltipText={t<string>('common:delete')}
                                dataTestId="remove-list-item-button"
                                dataQa="remove-list-item-button"
                            >
                                <Delete htmlColor={Colors.ListIconColor} />
                            </CustomizedIconButton>
                        </PermissionProvider>
                    </div>
                );
            },
        },
    ];
};
