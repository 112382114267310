import { createAsyncAction, createAction } from 'typesafe-actions';
import { MailMePayload, SessionIdPayload, SignInFromAdminPayload } from './payloads';
import {
    ApiChangePasswordPayload,
    ApiLoginPayload,
} from '../../services/auth-api';
import { APIError } from '../../types';

export const passwordChange = createAsyncAction(
    'USER_PASSWORD_CHANGE',
    'USER_PASSWORD_CHANGE_SUCCESSES',
    'USER_PASSWORD_CHANGE_FAILED',
)<ApiChangePasswordPayload, { success: number }, APIError>();

export const logIn = createAsyncAction(
    'USER_SIGN_IN',
    'USER_SIGN_IN_SUCCESSES',
    'USER_SIGN_IN_FAILED',
)<ApiLoginPayload, SessionIdPayload, APIError>();

export const passwordRecovery = createAsyncAction(
    'PASSWORD_RECOVERY',
    'PASSWORD_RECOVERY_SUCCESSES',
    'PASSWORD_RECOVERY_FAILED',
)<MailMePayload, { success: number }, APIError>();

export const sendRecoveryPasswordEmail = createAsyncAction(
    'SEND_RECOVERY_EMAIL',
    'SEND_RECOVERY_EMAIL_SUCCESS',
    'SEND_RECOVERY_EMAIL_FAILED',
)<MailMePayload, { success: number }, APIError>();

export const logInPasswordExpired = createAction('SIGN_IN_PASSWORD_EXPIRED')<{
    login: string;
    password: string;
}>();

export const loginClearErrors = createAction('SIGN_IN_CLEAR_ERRORS')();

export const setEnteredLogin = createAction('SIGN_IN_SET_ENTERED_LOGIN')<{
    login: string;
}>();

export const signInFromAdmin = createAsyncAction(
    'USER_SIGN_IN_FROM_ADMIN',
    'USER_SIGN_IN_FROM_ADMIN_SUCCESSES',
    'USER_SIGN_IN_FROM_ADMIN_FAILED',
)<SignInFromAdminPayload, SessionIdPayload, APIError>();