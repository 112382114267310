import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import {
    Colors,
    greyColor,
    primaryColor,
    textColor,
} from '../../styles/Colors';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    textField: {
        display: 'flex',
        '& .MuiInputBase-root': {
            minHeight: 56,
            backgroundColor: Colors.White,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            minHeight: 56,
            backgroundColor: Colors.SmokeBackground,
        },
        '& .MuiInput-underline::before': {
            borderColor: Colors.Border,
        },
        '& .MuiFormControl-root': {
            flex: 1,
        },
        '& .MuiInputBase-input': {
            color: textColor,
            borderColor: primaryColor,
            padding: '25px 15px 6px 15px',
            fontSize: 16,
            '&::placeholder': {
                color: Colors.Gray3,
            },
        },
        '& .Mui-focused': {
            color: primaryColor,
            marginLeft: 0,
        },
        '& .MuiFormLabel-root': {
            marginLeft: 15,
            zIndex: 2,
        },

        '& .MuiInputLabel-root': {
            top: '-3px !important',
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 13px) scale(0.75)',
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: primaryColor,
            },
        },
        '& .Mui-disabled:hover:before': {
            borderBottomColor: Colors.Link,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor,
        },
        '& .Mui-disabled:before': {
            borderBottom: `1px solid ${greyColor}`,
        },
        '& .Mui-error:after': {
            borderBottomColor: Colors.Error,
        },
    },
    asterisk: {
        color: Colors.Error,
    },
    helperText: {
        color: Colors.Error,
        fontSize: 12,
        marginTop: -2,
        marginBottom: -25,
        width: 'unset',
        margin: '0 15px',
        letterSpacing: '0.03333em',
    },
}));

type TextFieldWithMaskProps = {
    mask?: React.ElementType<InputBaseComponentProps>;
    name?: string;
    label?: string;
    maskPlaceholder?: string;
    id?: string;
    value?: any;
    onChange?:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | undefined;
    onFocus?: any | undefined;
    onBlur?: any | undefined;
    className?: string;
    placeholder?: string;
    InputProps?: any;
    inputProps?: any;
    InputLabelProps?: any;
    required?: boolean;
    setFieldError?: (field: string, value: any) => void;
    helperText?: string;
};

const TextFieldWithMask: React.FC<TextFieldWithMaskProps> = ({
    mask,
    name,
    label,
    id,
    value,
    onChange,
    className,
    placeholder,
    InputProps,
    inputProps,
    InputLabelProps,
    required,
    setFieldError,
    helperText,
    onFocus,
    onBlur,
}) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.textField, className)}>
            <FormControl>
                {label && (
                    <InputLabel htmlFor={id} {...InputLabelProps}>
                        {label}
                        {required ? (
                            <span className={classes.asterisk}> *</span>
                        ) : (
                            ''
                        )}
                    </InputLabel>
                )}
                <Input
                    value={value}
                    onChange={(e) => {
                        onChange?.(e);
                        !!id && setFieldError?.(id, undefined);
                    }}
                    name={name}
                    id={id}
                    inputComponent={mask}
                    placeholder={placeholder}
                    {...InputProps}
                    error={!!helperText}
                    inputProps={inputProps}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {!!helperText && (
                    <span className={classes.helperText}>{helperText}</span>
                )}
            </FormControl>
        </div>
    );
};

export default TextFieldWithMask;
