import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import i18n from 'i18next';
import { ApiFile } from '../../../store/types/ApiFile';
import { CallerID, RingStrategy } from '../../../store/types/RingGroup';

export type RingGroupGeneralFormType = {
    name: string;
    number: string;
    callerId: CallerID;
    ringbackTone: number | null;
    callPickupAllowed: boolean;
    ringStrategy: RingStrategy;
};

const emptyInputText = i18n.t<string>('errors:common.emptyInput');

export const ringGroupGeneralFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(emptyInputText),
    number: Yup.string()
        .matches(/[0-9]+/, i18n.t<string>('errors:ringGroups.numberOnlyDigits'))
        .max(5, i18n.t<string>('errors:ringGroups.numberMaxLength'))
        .required(emptyInputText),
    callerId: Yup.string().required(),
    ringbackTone: Yup.number().nullable().notRequired(),
    callPickupAllowed: Yup.boolean().required(),
    ringStrategy: Yup.string().required(),
});

export type RingGroupGeneralFormProps = {
    ringbackTonesList?: ApiFile[];
};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        margin: '22px 0px 0 0px',
        paddingBottom: 28,
        display: 'flex',
        padding: '0px 16px',
    },
    columnItemsContainer: {
        flexDirection: 'column',

        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    spaceB: {
        '& > :first-child': {
            marginRight: 26,
        },
    },
    inputs: {
        display: 'flex',
        maxWidth: 1044,
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 26,
        },
        '& .MuiBox-root': {
            padding: 18,
        },
        '& h3': {
            marginBlockEnd: 0,
            marginTop: 30,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
    },
    rowBoxHeader: {
        marginRight: 20,
        marginBottom: 18,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },
    checkbox: {
        flexDirection: 'row-reverse',
        marginLeft: 0,
        marginTop: 5,
    },
    optionWithMargin: {
        marginLeft: 20,
    },
    fileSelect: {
        marginTop: 15,
    },
    border: {
        borderBottom: `1px solid ${Colors.Border}`,
    },
    radioContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.White,
        border: `1px solid ${Colors.Border}`,
        margin: '0 16px 0 0',
        borderRadius: 4,
        padding: '10px 24px 20px 24px !important',
        '&:last-of-type': {
            marginRight: 0,
        },
    },
    radioDescription: {
        fontSize: 14,
        marginTop: 3,
        marginBottom: 0,
        lineHeight: '24px',
    },
    selectedRadio: {
        background: Colors.Secondary10,
        border: `2px solid ${Colors.Secondary1}`,
    },
    column: {
        flexDirection: 'column',
    },
    strategyContainer: {
        marginTop: 18,
    },
    callPickupContainer: {
        marginTop: 18,
        marginLeft: -3,
        paddingBottom: 0,
    },
    radioButton: {
        '& .MuiTypography-body1': {
            fontWeight: 700,
        },
    },
}));
