import React from 'react';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CallBarringIcon } from '../../assets/services/call_barring.svg';
import { ReactComponent as AutoAttendantIcon } from '../../assets/services/auto_attendant.svg';
import { ReactComponent as CallProcessingIcon } from '../../assets/services/call_processing.svg';
import { ReactComponent as CallProcessingRedDotIcon } from '../../assets/services/call_processing_red_dot.svg';
import { ReactComponent as CallProcessingPbxEnabledIcon } from '../../assets/services/call_processing_pbx_enabled.svg';
import { ReactComponent as CallProcessingPbxEnabledOtherIcon } from '../../assets/services/call_processing_pbx_enabled_other.svg';
import { ReactComponent as CallRecordingIcon } from '../../assets/services/call_recording.svg';
import { ReactComponent as ConfEnabledIcon } from '../../assets/services/conf_enabled.svg';
import { ReactComponent as UnifiedMessagingFaxOnlyIcon } from '../../assets/services/unified_messaging_faxonly.svg';
import { ReactComponent as UnifiedMessagingIcon } from '../../assets/services/unified_messaging.svg';
import { ReactComponent as CallForwardingIcon } from '../../assets/services/call_forwarding.svg';

export enum Service {
    AutoAttendant,
    CallBarring,
    CallProcessing,
    CallRecording,
    ConfEnabled,
    UnifiedMessagingFaxOnly,
    UnifiedMessaging,
    CallProcessingOther,
    CallProcessingPbxEnabled,
    CallProcessingPbxEnabledOther,
    CallForwarding,
}

type ServiceIconProps = {
    type: Service;
    dataQa?: string;
    dataTestId?: string;
    mode?: string;
    title?: string;
    forceTooltip?: string;
};

const ServicesIconBase = {
    [Service.AutoAttendant]: <AutoAttendantIcon />,
    [Service.CallBarring]: <CallBarringIcon />,
    [Service.CallProcessing]: <CallProcessingIcon />,
    [Service.CallRecording]: <CallRecordingIcon />,
    [Service.ConfEnabled]: <ConfEnabledIcon />,
    [Service.UnifiedMessagingFaxOnly]: <UnifiedMessagingFaxOnlyIcon />,
    [Service.UnifiedMessaging]: <UnifiedMessagingIcon />,
    [Service.CallProcessingOther]: <CallProcessingRedDotIcon />,
    [Service.CallProcessingPbxEnabled]: <CallProcessingPbxEnabledIcon />,
    [Service.CallProcessingPbxEnabledOther]: (
        <CallProcessingPbxEnabledOtherIcon />
    ),
    [Service.CallForwarding]: <CallForwardingIcon />,
};

export const ServiceIcon: React.VFC<ServiceIconProps> = ({
    type,
    dataQa,
    dataTestId,
    mode,
    title,
    forceTooltip,
}) => {
    const { t } = useTranslation();

    return (
        <CustomizedTooltip
            title={
                forceTooltip
                    ? forceTooltip
                    : title
                    ? title
                    : t(`enums:service.${type}`, { mode: mode })
            }
            dataQa={dataQa}
            dataTestId={dataTestId}
            copy={false}
        >
            <div style={{ marginTop: 5 }} data-testid={`${dataTestId}-icon`}>
                {ServicesIconBase[type]}
            </div>
        </CustomizedTooltip>
    );
};
