import { put, takeLatest, select, call, delay } from 'redux-saga/effects';

//Actions
import * as actions from '../../actions';

//Types
import { ActionType } from 'typesafe-actions';
import { api } from '../../services/axios';
import JSONFormData from '../../../utils/JSONFormData';
import { ReduxState } from '../../types';
import toast from 'react-hot-toast';
import i18n from '../../../services/i18n';
import { showErrorToast } from '../../../utils/showErrorToast';
import { AxiosResponse } from 'axios';
import { Customer } from '../../../services/endpoints';
import {
    PortalUsers,
    PortalUserItem,
    RolesPortal,
    GetPortalUserDetailsResponse,
} from '../../actions/company/payloads';
import { CustomerInfo } from '../../types/CustomerInfo';
import { GetPortalUsersListRequestPayload } from '../../actions/company/payloads';
import { calculateStatus } from '../../../views/PortalUsers/utils';
import {
    getCountriesList,
    getTimeZonesList,
    getLocalLanguagesList,
    getConfigData,
    getGlobalCustomerInfo,
    getSessionData,
    getSubdivisionsData,
} from '../generic/saga';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import {
    mappedValues,
    MappedValuesType,
    CompanyInfoFormType,
} from '../../../components/Forms/MyProfile/CompanyInfo/utils';
import {
    CreateNewPortalUserFormData,
    MappedValuesPortalUserType,
    mappedValuesPortalUser,
    ipAddress,
} from '../../../components/PortalUsers/utils';
import { LocaleLanguages, TimeZone } from '../../types/TimeZone';
import history from '../../../history';
import { getRoute, Routes } from '../../../routes/routes';
import { RoleUserItem } from '../../actions/company/payloads';
import qs from 'qs';
import { PortalUsersErrors } from '../../../services/apiErrors';
import { AdditionalElement, getCompanyInfoTransformer } from '../../../utils/transformers';

interface ExtendedCustomerInfo extends CustomerInfo {
    i_individual: number;
}

export function* getCompanyData() {
    try {
        yield call(getGlobalCustomerInfo);
        yield call(getCountriesList);
        yield getCompanyDetails();

        const country: string | undefined = yield select(
            (state: ReduxState) => state.company.companyInfoDetails?.country,
        );

        if (country) {
            yield call(
                getSubdivisionsData,
                actions.getSubdivisionData.request({
                    iso_3166_1_a2: country,
                }),
            );
        }

        yield call(getSessionData);
        yield call(getTimeZonesList);
        yield call(getConfigData);
        yield put(actions.getCompanyData.success());
    } catch (err) {
        yield put(actions.getCompanyData.failure());
    }
}

export function* getPortalDetails(
    action: ActionType<typeof actions.getPortalDetails.request>,
) {
    try {
        yield call(getCustomerInfo);
        yield call(getConfigData);
        yield call(getRoles);
        yield call(
            getPortalUsersList,
            actions.getPortalUsersList.request({
                params: action.payload.params,
            }),
        );
        yield put(actions.getPortalDetails.success());
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getPortalDetails.failure());
    }
}

export function* getAddPortalUserData() {
    try {
        yield call(getCustomerInfo);
        yield call(getGlobalCustomerInfo);
        yield call(getTimeZonesList);
        yield call(getLocalLanguagesList);
        yield call(getConfigData);
        yield call(getRoles);

        yield put(actions.getAddPortalUserData.success());
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getAddPortalUserData.failure());
    }
}

export function* changePassword(
    action: ActionType<typeof actions.changePassword.request>,
) {
    const {
        oldPassword,
        newPassword,
        confirmPassword,
        callback,
    } = action.payload;

    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
        });

        const response: AxiosResponse<{
            success: number;
            errors?: { [key: string]: string };
        }> = yield api.post(Customer.ChangePassword, body);

        if (response.data.success === 1) {
            callback?.();
            toast(i18n.t<string>('screens:myCompany.passwordUpdated'));
            yield put(actions.changePassword.success());
        } else {
            throw response.data;
        }
    } catch (err) {
        if (err.errors) {
            yield put(actions.changePassword.failure(err.errors));
        } else {
            showErrorToast(err.response?.data?.faultstring);
        }
    }
}

function* getCompanyDetails() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        const response: AxiosResponse = yield api.post(
            Customer.GetCustomFieldsSchema,
            body,
        );

        const details = res.data.customer_info;

        const obj = getCompanyInfoTransformer(details, response.data.custom_fields);

        yield put(
            actions.getCompanyInfoData.success(obj),
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getCustomerInfo() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const response: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        yield put(
            actions.getCustomerInfoPortalUser.success({
                i_customer: response.data.customer_info.i_customer,
            }),
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getPortalUsersList(
    action: ActionType<typeof actions.getPortalUsersList.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const { iCustomer } = yield select(
            (state: ReduxState) => state.company,
        );
        const body = new JSONFormData(session_id, csrf_token);
        const getUserListPayload: GetPortalUsersListRequestPayload = {
            params: {
                i_customer: iCustomer,
            },
        };

        if (action.payload.params?.get_total) {
            getUserListPayload.params.get_total =
                action.payload.params.get_total;
        }
        if (action.payload.params?.offset) {
            getUserListPayload.params.offset = action.payload.params.offset;
        }
        if (action.payload.params?.limit) {
            getUserListPayload.params.limit = action.payload.params.limit;
        }
        if (action.payload.params?.i_role) {
            getUserListPayload.params.i_role = action.payload.params.i_role;
        }

        body.setParams(getUserListPayload.params);

        const response: AxiosResponse<PortalUsers> = yield api.post(
            Customer.GetCustomerIndividualList,
            body,
        );
        yield getGlobalCustomerInfo();

        const { out_date_format } = yield select(
            (state: ReduxState) =>
                state.generic.globalCustomerInfo?.customer_info,
        );

        const mappedValues = response.data.customer_individual_list.map(
            (element: PortalUserItem) => {
                const status = calculateStatus(element, out_date_format);

                return {
                    id: element.i_individual,
                    login: element.login,
                    status: status,
                    role: element.i_role,
                    email: element.email,
                };
            },
        );
        yield put(
            actions.getPortalUsersList.success({
                items: mappedValues,
                total: response.data.total,
            }),
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* updateCompanyInfoForm(
    action: ActionType<typeof actions.updateCompanyInfoForm.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        const bodyAdditional = new JSONFormData(session_id, csrf_token);

        const i_customer: number | undefined = yield select(
            (state: ReduxState) => state.company.iCustomer,
        );

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );
        const keys = Object.keys(dataToSave) as (keyof CompanyInfoFormType)[];
        let shouldUpdateIncoming = action.payload.date.toString().length > 0;

        keys.forEach((dataKey) => {
            if (mappedValues[dataKey] !== undefined) {
                shouldUpdateIncoming = true;
            }
        });

        if (shouldUpdateIncoming) {
            const valuesToSave: Partial<MappedValuesType> = action.payload.date
                ? { out_date_time_format: action.payload.date }
                : {};

            if (!keys.length && action.payload.date)
                body.setParams({
                    customer_info: valuesToSave,
                });

            keys.forEach((dataKey) => {
                const key = mappedValues[dataKey] as keyof MappedValuesType;
                if (key) {
                    if (key === 'additionalDetails') {
                        const additionalDetails = dataToSave[
                            dataKey
                        ] as AdditionalElement[];
                        const preparedAdditionalValues = additionalDetails?.map(
                            (e: AdditionalElement) => {
                                return {
                                    name: e.name,
                                    db_value: e.value || '',
                                };
                            },
                        );
                        bodyAdditional.setParams({
                            i_customer,
                            custom_fields_values: preparedAdditionalValues,
                        });
                    } else {
                        //@ts-ignore
                        valuesToSave[key] = dataToSave[dataKey];
                        body.setParams({
                            customer_info: valuesToSave,
                        });
                    }
                }
            });
        }
        if (dataToSave.additionalDetails)
            yield api.post(Customer.UpdateCustomFieldsValues, bodyAdditional);
        yield api.post(Customer.UpdateCustomer, body);
        yield call(getCompanyDetails);
        yield put(actions.updateCompanyInfoForm.success());
        toast(i18n.t<string>('screens:myCompany.updatedSuccessfully'));
        yield delay(1000);
        location?.replace('');
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getRoles() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const { iCustomer } = yield select(
            (state: ReduxState) => state.company,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_customer: iCustomer,
        });

        const response: AxiosResponse<RolesPortal> = yield api.post(
            Customer.GetCustomerIndividualAllowedRoleList,
            body,
        );

        const roleList: RoleUserItem[] = [
            {
                i_role: 0,
                i_role_type: 1,
                is_system: 1,
                name: i18n.t<string>('screens:extensions.any'),
                description: '',
            },
            ...response.data.customer_individual_allowed_role_list,
        ];

        yield put(
            actions.getRoles.success({
                customer_individual_allowed_role_list: roleList,
            }),
        );
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* saveNewPortalUser(
    action: ActionType<typeof actions.saveNewPortalUser.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        const { iCustomer } = yield select(
            (state: ReduxState) => state.company,
        );

        body.setParams({
            customer_individual_info: {
                login: action.payload.login,
                password: action.payload.password,
                email: action.payload.email,
                i_role: action.payload.i_role,
                activation_date: action.payload.activation_date,
                expiration_date: action.payload.expiration_date,
                i_time_zone: action.payload.i_time_zone,
                lang: action.payload.lang,
                i_customer: iCustomer,
            },
        });
        const response: AxiosResponse<ExtendedCustomerInfo> = yield api.post(
            Customer.AddCustomerIndividual,
            body,
        );
        if (response) {
            yield call(
                getPortalUsersList,
                actions.getPortalUsersList.request({ params: {} }),
            );
            yield put(actions.saveNewPortalUser.success());
            toast(i18n.t<string>('screens:portalUsers.addedSuccessfully'));
            history.push(
                getRoute(Routes.EditPortalUser, {
                    id: response.data?.i_individual,
                }),
            );
        }

        action.payload.callback && action.payload.callback();
    } catch (err: any) {
        yield put(actions.saveNewPortalUser.failure(err.response?.data));
        showErrorToast(
            err.response?.data?.faultstring,
            err.response?.data?.faultcode,
            ['Server.Customer.error_during_add_customer_individual'],
        );
    }
}

export function* getPortalUserInfo(
    action: ActionType<typeof actions.getPortalUserInfo.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const { user } = yield select((state) => state.generic.sessionData);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ login: user });

        yield call(getCustomerInfo);
        yield call(getTimeZonesList);
        yield call(getLocalLanguagesList);
        yield call(getRoles);
        yield call(getConfigData);
        yield call(
            getPortalUserDetails,
            actions.getPortalUserDetails.request(action.payload),
        );
        yield api.post(Customer.GetCustomerIndividualInfo, body);
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getPortalUserDetails(
    action: ActionType<typeof actions.getPortalUserDetails.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_individual: action.payload.i_individual,
        });

        const response: AxiosResponse<GetPortalUserDetailsResponse> = yield api.post(
            Customer.GetCustomerIndividualInfo,
            body,
        );

        yield put(actions.getPortalUserDetails.success(response.data));
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* editPortalUser(
    action: ActionType<typeof actions.editPortalUser.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const { timeZonesList, languagesList } = yield select(
            (state: ReduxState) => state.generic,
        );
        const { roleList } = yield select((state: ReduxState) => state.company);

        const body = new JSONFormData(session_id, csrf_token);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        const keys = Object.keys(
            dataToSave,
        ) as (keyof CreateNewPortalUserFormData)[];
        let shouldUpdateIncoming = false;

        keys.forEach((dataKey) => {
            if (mappedValuesPortalUser[dataKey] !== undefined) {
                shouldUpdateIncoming = true;
            }
        });

        if (dataToSave.language)
            dataToSave.language = languagesList.find(
                (e: LocaleLanguages) => e.name === dataToSave.language,
            ).iso_639_1;

        if (dataToSave.timezone)
            dataToSave.timezone = timeZonesList.find(
                (e: TimeZone) => e.time_zone_name === dataToSave.timezone,
            ).i_time_zone;

        if (dataToSave.role)
            dataToSave.role = roleList.find(
                (e: { name: string; i_role: number }) =>
                    e.name === dataToSave.role,
            ).i_role;

        if (shouldUpdateIncoming) {
            const valuesToSave: Partial<MappedValuesPortalUserType> = {};
            keys.forEach((dataKey) => {
                const key = mappedValuesPortalUser[
                    dataKey
                ] as keyof MappedValuesPortalUserType;
                if (key) {
                    //@ts-ignore
                    valuesToSave[key] =
                        key === 'login_allowed_ip_list'
                            ? prepareAllowedIpList(
                                  dataToSave[dataKey] as ipAddress[],
                              )
                            : dataToSave[dataKey];
                    body.setParams({
                        customer_individual_info: {
                            ...valuesToSave,
                            i_individual: action.payload.i_individual,
                        },
                    });
                }
            });
            yield api.post(Customer.UpdateCustomerIndividual, body);
        }

        toast(i18n.t<string>('screens:portalUsers.updatedSuccessfully'));
        yield put(actions.editPortalUser.success());
        yield delay(1000);

        location?.replace(
            `${action.payload.i_individual}?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (err: any) {
        yield put(actions.editPortalUser.failure(err.response?.data));
        showErrorToast(
            err.response?.data?.faultstring,
            err.response?.data?.faultstring,
            [PortalUsersErrors.PortalUserExist],
        );
    }
}

export function* deletePortalUser(
    action: ActionType<typeof actions.deletePortalUser.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_individual: action.payload.i_individual,
        });

        const response: AxiosResponse<{
            success: number;
            errors?: { [key: string]: string };
        }> = yield api.post(Customer.DeleteCustomerIndividual, body);

        if (response) {
            yield call(
                getPortalUsersList,
                actions.getPortalUsersList.request({ params: {} }),
            );
            toast(i18n.t<string>('screens:portalUsers.deletedSuccessfully'));
            action.payload?.callback && action.payload.callback();
            yield put(actions.deletePortalUser.success());
        }
    } catch (err) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.deletePortalUser.failure());
    }
}

const prepareAllowedIpList = (list: ipAddress[]) => {
    const listToSave = list.map((e) => {
        return {
            ip: e.value,
        };
    });
    return listToSave.length > 1 ||
        (listToSave.length === 1 && listToSave[0].ip !== '')
        ? listToSave
        : [];
};

export const companySaga = [
    takeLatest(actions.changePassword.request, changePassword),
    takeLatest(actions.getCompanyData.request, getCompanyData),
    takeLatest(actions.updateCompanyInfoForm.request, updateCompanyInfoForm),
    takeLatest(actions.getPortalDetails.request, getPortalDetails),
    takeLatest(actions.getPortalUsersList.request, getPortalUsersList),
    takeLatest(actions.saveNewPortalUser.request, saveNewPortalUser),
    takeLatest(actions.getPortalUserInfo.request, getPortalUserInfo),
    takeLatest(actions.getPortalUserDetails.request, getPortalUserDetails),
    takeLatest(actions.editPortalUser.request, editPortalUser),
    takeLatest(actions.deletePortalUser.request, deletePortalUser),
    takeLatest(actions.getAddPortalUserData.request, getAddPortalUserData),
];
