import {
    CPConditionTimeWindowInfo,
    TimeWindowIntervalsDetails,
} from '../../store/types/CallScreening';
import {
    convertArrayOfNumbersToRangesString,
    daysApi,
    getRingScheduleSummary,
    monthApi,
} from './RingScheduleSummary';
import { Interval } from '../../store/types/RingGroup';
import {
    IntervalFormProps,
    IntervalStatus,
} from '../../components/IntervalSelect/IntervalSelect';

function clean(value: string): string {
    return value.replace(/(\r\n|\n|\r)/gm, '').trimStart();
}

export const periodStringToArray = (
    periods?: CPConditionTimeWindowInfo,
): Array<TimeWindowIntervalsDetails> => {
    if (periods?.description?.slice(0, 4) === ' or ') {
        periods.description = periods.description.slice(4);
    }

    const desc = periods?.description?.split('or').map(clean) || [];
    const intervals = periods?.period?.split(', ').map(clean) || [];

    return desc.map((o, index) => ({
        description: o,
        intervals: intervals[index],
    }));
};

export const timeWindowFromTimeWindowIntervals = (
    form: IntervalFormProps,
): CPConditionTimeWindowInfo => {
    if (form.activity === IntervalStatus.Always) {
        return {
            period: 'Always',
            description: 'Always',
        };
    }

    const timeWindow: CPConditionTimeWindowInfo = {
        period: '',
        description: '',
    };

    form.oldIntervals.forEach((o) => {
        timeWindow.period += o.intervals + ', ';
        timeWindow.description += o.description + ' or ';
    });

    timeWindow.period = timeWindow.period?.slice(
        0,
        timeWindow.period?.length - 2,
    )?.trimEnd() || '';

    timeWindow.description = timeWindow.description?.slice(
        0,
        timeWindow.description?.length - 3,
    )?.trimEnd();

    let newPeriod = '';
    form.newIntervals.forEach((o) => {
        newPeriod += makePeriod(o) + ', ';
    });

    if (newPeriod.length) {
        if(timeWindow.period.length > 0) {
            timeWindow.period += ', ' + newPeriod.slice(0, newPeriod.length - 2);
        } else {
            timeWindow.period += newPeriod.slice(0, newPeriod.length - 2);
        }
        timeWindow.description +=
            ' or ' +
            getRingScheduleSummary({
                activity: form.activity,
                newIntervals: form.newIntervals,
                oldIntervals: form.oldIntervals,
            });
    }

    return timeWindow;
};

const makePeriodDetails = (interval: Interval): string => {
    let result = '';

    if (interval.days.length) {
        result += `wd{${convertArrayOfNumbersToRangesString(
            interval.days.map((day) => day.value),
            daysApi,
        ).replace(/,/g, '')}} `;
    }

    if (interval.daysOfMonth.length) {
        result += `md{${interval.daysOfMonth.replace(/,/g, ' ')}} `;
    }

    if (interval.months.length) {
        result += `mo{${convertArrayOfNumbersToRangesString(
            interval.months.map((month) => month.value),
            monthApi,
        ).replace(/,/g, '')}} `;
    }

    if (interval.years?.length) {
        result += `yr{${convertArrayOfNumbersToRangesString(
            interval.years.map((year) => parseInt(year.name)),
        )}} `;
    }

    return result.slice(0, result.length - 1) || '';
};

const makePeriodTime = (h1: number, m1: number, h2: number, m2: number) => {
    if (h1 === h2 && m1 === 0 && m2 === 0) {
        return [`hr{${h1}}min{0} `];
    } else if (h1 === h2 && (m1 !== 0 || m2 !== 0)) {
        return [`hr{${h1}} min{${m1}-${m2 - 1}} `];
    } else if (h1 !== h2 && m1 === 0 && m2 === 0 && h2 - h1 === 1) {
        return [`hr{${h1}} `];
    } else if (h1 !== h2 && m1 === 0 && m2 === 0 && h2 - h1 !== 1) {
        return [`hr{${h1}-${h2 - 1}} `];
    } else {
        const middleHours =
            h1 + 1 === h2 - 1 ? `${h1 + 1}` : `${h1 + 1}-${h2 - 1}`;
        return [
            `hr{${h1}}min{${m1}-59} `,
            `hr{${middleHours}} `,
            `hr{${h2}}min{0-${m2 - 1}} `,
        ];
    }
};

export const makePeriod = (interval: Interval): string => {
    const details = makePeriodDetails(interval);

    if (interval.wholeDay) {
        return 'hr{0-22} ' + details + ',hr{23}min{0-58} ' + details;
    }

    const from = interval.startTime.split(':').map((o) => parseInt(o));
    const to = interval.endTime.split(':').map((o) => parseInt(o));
    const time = makePeriodTime(from[0], from[1], to[0], to[1]);

    return `${time.map((v) => v + details)}`.trimEnd();
};
