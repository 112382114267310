import React from 'react';
import { useStyles } from './SignInHeaderComponent.utils';
import Logo from '../Logo/Logo';

interface SignInHeader {
    welcomeText: string;
}

const SignInHeaderComponent: React.VFC<SignInHeader> = ({ welcomeText }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.logo}>
                <Logo />
            </div>
            <span className={classes.welcome}>{welcomeText}</span>
        </div>
    );
};

export default SignInHeaderComponent;
