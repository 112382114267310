import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from '../../actions/auth';
import { APIErrorInterface } from '../../types';
import { SessionData } from '../../types/Session';

export interface AuthReducerStateType {
    session_id?: string | null;
    access_token?: string | null;
    csrf_token?: string | null;
    LogInActionsType?: string | null;
    login?: string | null;
    changePasswordActive: boolean;
    passwordRecovered: boolean;
    passwordChanged: boolean;
    email?: string | null;
    oldPassword?: string | null;
    errors?: APIErrorInterface | null;
    msg?: string | null;
    inProgress?: boolean;
}

export const initialState: AuthReducerStateType = {
    session_id: window.localStorage.getItem('session_id'),
    access_token: window.localStorage.getItem('access_token'),
    csrf_token: window.localStorage.getItem('csrf_token'),
    login: (JSON.parse(localStorage.getItem('user_info') || '{}') as SessionData)?.user,
    changePasswordActive: false,
    passwordRecovered: false,
    passwordChanged: false,
    email: null,
    oldPassword: null,
    errors: null,
    msg: null,
    inProgress: false,
};

export type LogInActionsType = ActionType<typeof actions>;

const signInReducer = createReducer<AuthReducerStateType, LogInActionsType>(
    initialState,
)
    .handleAction(actions.logIn.request, (state, action) => ({
        ...state,
        login: action.payload.login,
        inProgress: true,
    }))
    .handleAction(actions.logIn.success, (state, action) => {
        const { session_id, access_token, csrf_token } = action.payload;
        return {
            ...state,
            session_id,
            access_token,
            csrf_token,
            errors: null,
            msg: null,
            inProgress: false,
        };
    })
    .handleAction(actions.logIn.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload.response?.data,
            inProgress: false,
        };
    })
    .handleAction(
        actions.sendRecoveryPasswordEmail.success,
        (state, action) => ({
            ...state,
            passwordChanged: !!action.payload.success,
            changePasswordActive: false,
            passwordRecovered: false,
            oldPassword: null,
            msg: 'mailMeSuccess',
        }),
    )
    .handleAction(
        actions.sendRecoveryPasswordEmail.failure,
        (state, action) => ({
            ...state,
            errors: action.payload.response?.data,
        }),
    )
    .handleAction(actions.passwordRecovery.success, (state, action) => ({
        ...state,
        passwordChanged: !!action.payload.success,
        changePasswordActive: false,
        passwordRecovered: true,
        oldPassword: null,
        msg: 'passwordRecoverySuccess',
    }))
    .handleAction(actions.passwordRecovery.failure, (state, action) => ({
        ...state,
        errors: action.payload.response?.data,
    }))
    .handleAction(actions.passwordChange.success, (state, action) => ({
        ...state,
        passwordChanged: !!action.payload.success,
        changePasswordActive: false,
        oldPassword: null,
        msg: 'passwordRecoverySuccess',
    }))
    .handleAction(actions.passwordChange.failure, (state, action) => ({
        ...state,
        errors: action.payload.response?.data,
    }))
    .handleAction(actions.logInPasswordExpired, (state, action) => ({
        ...state,
        login: action.payload.login,
        oldPassword: action.payload.password,
        changePasswordActive: true,
        errors: null,
    }))
    .handleAction(actions.loginClearErrors, (state) => ({
        ...state,
        errors: null,
        msg: null,
    }))
    .handleAction(actions.setEnteredLogin, (state, action) => ({
        ...state,
        login: action.payload.login,
    }))
    .handleAction(actions.signInFromAdmin.request, (state, action) => ({
        ...state,
        value: action.payload.value,
        inProgress: true,
    }))
    .handleAction(actions.signInFromAdmin.success, (state, action) => {
        const { session_id, access_token } = action.payload;
        return {
            ...state,
            session_id,
            access_token,
            errors: null,
            msg: null,
            inProgress: false,
        };
    })
    .handleAction(actions.signInFromAdmin.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload.response?.data,
            inProgress: false,
        };
    })
    ;

export default signInReducer;
