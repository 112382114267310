import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import CustomizedSwitch from '../Switch/Switch';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

export type SwitchWithLabelProps = {
    label: string;
    icon?: JSX.Element;
    setValue: (field: string, value: boolean) => void;
    value: boolean;
    field: string;
    disabled?: boolean;
    id?: string;
    className?: string;
    customDisabledTooltip?: string;
    dataQa?: string;
};

const useStyles = makeStyles(() => ({
    itemsContainer: {
        backgroundColor: Colors.LightGray,
        padding: '17px 25px 17px 25px',
        marginTop: 20,
        maxWidth: 876,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            marginRight: 90,
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        height: '55px',
        marginBottom: '17px',
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 16,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 20,

        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            color: Colors.Text,
            fontSize: 14,
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        paddingTop: 30,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
}));

const SwitchWithLabel: React.FC<SwitchWithLabelProps> = ({
    label,
    icon,
    value,
    field,
    setValue,
    disabled,
    id,
    className,
    customDisabledTooltip,
    dataQa,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(field, e.target.checked);
    };

    return (
        <div
            className={classNames(classes.inputs, className)}
            data-qa={dataQa}
            data-testid={dataQa}
        >
            <Box className={classes.rowBox}>
                <CustomizedTooltip
                    title={
                        customDisabledTooltip || t('tooltips:incomings.locked')
                    }
                    disableHoverListener={!disabled}
                    copy={false}
                >
                    <Box className={classes.rowBox}>
                        <CustomizedSwitch
                            checked={value}
                            dataQa={`status-switch-${id}`}
                            dataTestId={`status-switch-${id}`}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        {label && (
                            <span
                                className={classNames(
                                    classes.rowBoxHeader,
                                    classes.boldHeader,
                                )}
                            >
                                {label}
                            </span>
                        )}
                    </Box>
                </CustomizedTooltip>
                {icon}
            </Box>
        </div>
    );
};

export default SwitchWithLabel;
