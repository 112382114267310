import React from 'react';
import PermissionProvider from '../components/PermissionProvider/PermissionProvider';
import { PermissionType, PortalPermission } from '../store/types/Permission';
import { getPermission } from './permissions/getPermission';

export const generateTabs = (
    items: { tab: React.ReactNode; title: string; permission: string[]; skipReadOnly?: boolean }[],
    permissions?: PortalPermission,
) => {
    
    items = items.filter(
        (v) =>
            getPermission(permissions, ...v.permission) !==
            PermissionType.Hidden,
    );

    return {
        tabNames: items.map((v) => v.title),
        tabs: items.map((v, index) => (
            <PermissionProvider key={`tab-${index}`} permission={v.permission} skipReadOnly={v.skipReadOnly || false}>
                {v.tab}
            </PermissionProvider>
        )),
    };
};
