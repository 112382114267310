import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { GlobalCurrencyInfo } from '../../../../store/types/CustomerInfo';
import { MakePaymentFormType } from '../MakePaymentDialog/MakePaymentDialog.utils';
import { useStyles } from './PaymentAmountForm.utils';
import _ from 'lodash';
import { actions } from '../../../../store';
import { BillingStateType } from '../../../../store/reducers/billing/reducer';

interface PaymentTaxesProps {
    taxesVisible:boolean
}

const PaymentTaxes:React.VFC<PaymentTaxesProps> = ({taxesVisible}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const { estimatedTaxesValue } = useSelector<ReduxState, BillingStateType>(
        (state) => state.billing,
    );

    const { customerInfo } = useSelector((state: ReduxState) => state.billing);

    const estimateTaxes = useCallback(
        _.debounce((amount: string) => {
            dispatch(
                actions.estimateTaxes.request({
                    amount: parseFloat(amount.replace(/,/g, '') || '0'),
                }),
            );
        }, 800),
        [],
    );

    const { values } = useFormikContext<MakePaymentFormType>();

    useEffect(() => {
        if (taxesVisible) {
            estimateTaxes(values.amount);
        }
    }, [values.amount]);

    return (
        <div className={classes.taxContainer}>
            <div className={classes.taxValuesContainer}>
                <span className={classes.headerText}>{`${t('common:tax')}, ${
                    currencyInfo?.iso_4217
                }`}</span>
                <span className={classes.valueText}>
                    {(estimatedTaxesValue || 0).toFixed(2)}
                </span>
            </div>
            <div className={classes.taxValuesContainer}>
                <span className={classes.headerText}>{`${t(
                    'screens:billing.totalAmount',
                )}, ${currencyInfo?.iso_4217}`}</span>
                <span className={classes.valueText}>
                    {(
                        (estimatedTaxesValue || 0) +
                        parseFloat(values.amount.replace(/,/g, '') || '0')
                    ).toFixed(2)}
                </span>
            </div>
        </div>
    );
};

export default PaymentTaxes;
