import React, { useState } from 'react';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Colors } from '../../styles/Colors';
import AssignDevice from '../Extensions/AssignDevice';
import { UAInfo, UAPortConfiguration } from '../../store/types/Devices';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';

interface AssignDeviceProps {
    isOpen: boolean;
    i_c_ext: number;
    onClose?: () => void;
    id?: string;
    selectedDevice?: UAInfo;
    selectedPort?: UAPortConfiguration;
    callBackOnSave?: () => void;
}

const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        padding: '47px 84px 23px 84px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            width: '100%',
            marginBottom: 24,
        },
        '& .MuiGrid-root': {
            width: 472,
        },
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiGrid-item': {
            marginBottom: 10,
        },
    },

    select: {
        display: 'flex',
    },

    disabledButton: {
        backgroundColor: Colors.Gray8,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}`,
        },
    },
    primaryButton: {
        background: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White}`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    width: {
        width: 96,
    },
}));

const AssignDeviceDialog: React.VFC<AssignDeviceProps> = ({
    id,
    i_c_ext,
    isOpen,
    onClose,
    selectedDevice,
    selectedPort,
    callBackOnSave,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [device, setDevice] = useState<UAInfo | null>(selectedDevice || null);
    const [port, setPort] = useState<UAPortConfiguration | null>(
        selectedPort || null,
    );

    const handleDeviceChange = (device: UAInfo | null) => {
        setDevice(device);
    };

    const handlePortChange = (port: UAPortConfiguration | null) => {
        setPort(port);
    };

    const handleSubmit = () => {
        if (device && port) {
            dispatch(
                actions.assignDevice.request({
                    port: port.port,
                    i_cpe: device.i_ua,
                    i_c_ext: i_c_ext,
                    onSuccess: callBackOnSave,
                }),
            );
        }
    };

    const actionButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={onClose}
        />,
        <DialogButton
            key="save"
            label={t('common:save')}
            onClick={handleSubmit}
            disabled={!device || !port}
            className={classes.primaryButton}
        />,
    ];

    return (
        <DialogContainer
            isOpen={isOpen}
            header={t('screens:devices.assignExtension', { id: id })}
            headerClass={classes.header}
            dialogActionsButtons={actionButtons}
            className={classes.root}
        >
            <Grid container className={classes.itemsContainer}>
                <AssignDevice
                    refresh={isOpen}
                    onDeviceChange={handleDeviceChange}
                    onPortChange={handlePortChange}
                    device={selectedDevice}
                    port={selectedPort}
                />
            </Grid>
        </DialogContainer>
    );
};
export default AssignDeviceDialog;
